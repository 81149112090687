var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/helpers.tsx
import { useSlotValue } from "plugin-system";
import { generatePath } from "react-router-dom";
import { v4 as libUuidv4 } from "uuid";
import { z } from "zod";

// src/auth/RequireAuthPage.tsx
import { usePluginRegistry as usePluginRegistry5 } from "plugin-system";
import { useEffect as useEffect2, useState as useState3 } from "react";
import { useNavigate as useNavigate5 } from "react-router-dom";
import {
  LONG_LIVED_QRY_CONFIG,
  getCoreApiErrorCode,
  getCoreApiErrorStatus,
  useAllRoles,
  useApi as useApi2,
  useOrgProfile,
  useResourceQuery
} from "common-api";
import { Box as Box5, Button as Button5, LinearProgress, Typography as Typography5 } from "@mui/material";

// src/NotFoundPage.tsx
import { Box as Box2, Button as Button2 } from "@mui/material";
import Typography2 from "@mui/material/Typography";
import { useNavigate as useNavigate2 } from "react-router-dom";

// src/Page.tsx
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import ComputerIcon from "@mui/icons-material/Computer";
import { useNavigate } from "react-router-dom";

// src/PageHelmet.tsx
import ReactHelmet from "react-helmet";
import { jsx } from "react/jsx-runtime";
function PageHelmet({ lang = "en", meta = [], title }) {
  return /* @__PURE__ */ jsx(
    ReactHelmet,
    {
      htmlAttributes: {
        lang
      },
      title,
      titleTemplate: "Phosphor | %s",
      meta: [
        {
          property: `og:title`,
          content: title
        }
      ].concat(meta)
    }
  );
}

// src/useMobileEnabled.tsx
import { isMobile } from "react-device-detect";
var useMobileEnabled = () => {
  return isMobile;
};

// src/Page.tsx
import { jsx as jsx2, jsxs } from "react/jsx-runtime";
function Page({
  children,
  title,
  titleAsHeader = true,
  subtitle,
  testIdPrefix,
  background,
  width,
  height,
  border,
  rootPadding,
  mainContentPadding,
  allowVerticalScroll = false,
  icon: Icon,
  mobileCompatible = false
}) {
  const navigate = useNavigate();
  const isMobile3 = useMobileEnabled();
  return /* @__PURE__ */ jsxs(
    Paper,
    {
      elevation: 0,
      square: true,
      sx: {
        overflowY: allowVerticalScroll ? "auto" : "hidden",
        overflowX: isMobile3 ? "hidden" : "inherit",
        border,
        padding: rootPadding || (isMobile3 ? "0 16px 0px 16px" : "50px 0"),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width || "100%",
        height: height || "100%",
        background: background || "transparent"
      },
      children: [
        title && /* @__PURE__ */ jsx2(PageHelmet, { title }),
        !mobileCompatible && isMobile3 ? /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              rowGap: 3,
              display: "flex"
            },
            children: [
              /* @__PURE__ */ jsx2(Avatar, { sx: { width: "125px", height: "125px" }, children: /* @__PURE__ */ jsx2(ComputerIcon, { sx: { fontSize: 75 } }) }),
              /* @__PURE__ */ jsx2(Typography, { variant: "h5", textAlign: "center", children: "This content is not supported through our mobile offering" }),
              /* @__PURE__ */ jsx2(Typography, { variant: "body1", textAlign: "center", children: "Please switch to a desktop device to access this feature" }),
              /* @__PURE__ */ jsx2(
                Button,
                {
                  variant: "contained",
                  color: "primary",
                  size: "large",
                  onClick: () => {
                    navigate("/");
                  },
                  children: "To Mobile Landing Page"
                }
              )
            ]
          }
        ) : /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              color: "text.primary",
              rowGap: isMobile3 ? 2 : 5,
              backgroundColor: background || "white",
              borderRadius: "32px",
              padding: mainContentPadding || "32px"
            },
            "data-testid": `page-${testIdPrefix}`,
            children: [
              (Icon || title && titleAsHeader) && /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
                /* @__PURE__ */ jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [
                  Icon && /* @__PURE__ */ jsx2(Icon, {}),
                  title && titleAsHeader && /* @__PURE__ */ jsx2(
                    Typography,
                    {
                      textAlign: isMobile3 ? "center" : "inherit",
                      variant: isMobile3 ? "h5" : "h2",
                      sx: { width: isMobile3 ? "100%" : "max-content" },
                      fontWeight: 700,
                      color: "neutral_900",
                      children: title
                    }
                  )
                ] }),
                subtitle && /* @__PURE__ */ jsx2(Typography, { variant: "bodyL", fontWeight: 500, children: subtitle })
              ] }),
              children
            ]
          }
        )
      ]
    }
  );
}

// src/NotFoundPage.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
function NotFoundPage({ height, buttonText }) {
  const navigate = useNavigate2();
  return /* @__PURE__ */ jsx3(
    Page,
    {
      title: "Page Not Found",
      titleAsHeader: false,
      testIdPrefix: "404",
      height: height != null ? height : "100vh",
      mobileCompatible: true,
      children: /* @__PURE__ */ jsxs2(
        Box2,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center"
          },
          children: [
            /* @__PURE__ */ jsx3(Typography2, { variant: "h5", mb: 3, textAlign: "center", color: "primary", children: "Oops! Something with this address is not right." }),
            /* @__PURE__ */ jsx3(Typography2, { variant: "body1", mb: 5, textAlign: "center", children: "But don't worry! You can still join the creators and collectors enjoying our platform and experiencing awesome NFTs." }),
            /* @__PURE__ */ jsx3(Button2, { variant: "contained", onClick: () => navigate("/"), children: buttonText != null ? buttonText : "Join Us" })
          ]
        }
      )
    }
  );
}

// src/auth/helpers.ts
var AuthClearEvent = class {
  constructor() {
    this.id = "auth.clear";
  }
};
var AuthTokenSetEvent = class {
  constructor(data) {
    this.data = data;
    this.id = "auth.token.set";
  }
};
var AuthPlatformSetEvent = class {
  constructor(data) {
    this.data = data;
    this.id = "auth.platform.set";
  }
};
var hasPermission = (requiredPermission, permissions) => {
  return permissions.includes(requiredPermission);
};
var hasPermissions = (requiredPermissions, permissions) => {
  return requiredPermissions.every((p) => hasPermission(p, permissions));
};

// src/auth/useAuth.tsx
import { usePluginState } from "plugin-system";
function useAuth() {
  return usePluginState("auth", {});
}

// src/auth/UnexpectedAuthError.tsx
import { Box as Box3, Button as Button3, Typography as Typography3 } from "@mui/material";
import { usePluginRegistry } from "plugin-system";
import { useNavigate as useNavigate3 } from "react-router-dom";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var UnexpectedAuthError = ({ reason }) => {
  const navigate = useNavigate3();
  const pluginRegistry = usePluginRegistry();
  return /* @__PURE__ */ jsxs3(
    Box3,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        rowGap: 4
      },
      children: [
        /* @__PURE__ */ jsx4(Typography3, { variant: "h1", gutterBottom: true, textAlign: "center", children: "Oops!" }),
        /* @__PURE__ */ jsx4(Typography3, { variant: "h4", gutterBottom: true, textAlign: "center", children: reason || "An unexpected error occured." }),
        /* @__PURE__ */ jsxs3(Box3, { sx: { display: "flex", columnGap: 2 }, children: [
          /* @__PURE__ */ jsx4(
            Button3,
            {
              size: "small",
              variant: "outlined",
              onClick: (e) => {
                pluginRegistry.commands.execute({ id: "portal.contact-support-dialog" });
              },
              children: "Contact Support"
            }
          ),
          /* @__PURE__ */ jsx4(
            Typography3,
            {
              onClick: () => navigate("/auth/logout"),
              variant: "h5",
              textAlign: "center",
              sx: { textDecoration: "underline", "&:hover": { opacity: 0.85 } },
              children: "To Log In"
            }
          )
        ] })
      ]
    }
  );
};

// src/auth/UnverifiedEmailError.tsx
import { Box as Box4, Button as Button4, CircularProgress, Typography as Typography4, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate as useNavigate4 } from "react-router-dom";
import { useAuth0 as useAuth02 } from "@auth0/auth0-react";
import { usePluginRegistry as usePluginRegistry4 } from "plugin-system";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState as useState2 } from "react";

// src/auth/useEmailVerification.tsx
import { useAuth0 } from "@auth0/auth0-react";
import { getApiErrorMessage, useApi } from "common-api";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";

// src/useConfig.tsx
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";
import { useMemo } from "react";
function useConfig() {
  const pluginRegistry = usePluginRegistry2();
  return useMemo(() => {
    return getConfig(pluginRegistry);
  }, [pluginRegistry]);
}

// src/auth/useEmailVerification.tsx
function useEmailVerification() {
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const pluginRegistry = usePluginRegistry3();
  const { auth0ClientId } = useConfig();
  const { user } = useAuth0();
  const { coreApiPublic } = useApi();
  const options = { auth0_id: String(user == null ? void 0 : user.sub), client_id: auth0ClientId };
  const mutation = useMutation({
    mutationFn: () => coreApiPublic.v1Auth0VerificationEmailPost(options),
    onSuccess: () => {
      pluginRegistry.events.publish({
        id: "portal.notification",
        data: {
          message: "Successfully sent verification email",
          options: {
            type: "success"
          }
        }
      });
    },
    onError: (error) => {
      const detail = getApiErrorMessage(error);
      const msg = detail === "Email already verified" ? detail : "Failed to send verification email";
      pluginRegistry.events.publish({
        id: "portal.notification",
        data: {
          message: msg,
          options: {
            type: "warning"
          }
        }
      });
      setAlreadyVerified(true);
    }
  });
  return __spreadProps(__spreadValues({}, mutation), { alreadyVerified });
}

// src/auth/UnverifiedEmailError.tsx
import { Fragment, jsx as jsx5, jsxs as jsxs4 } from "react/jsx-runtime";
var AUTH0_VERIFY_CLAIM = "https://consensys-nft.com/claims/email_verified";
var UnverifiedEmailError = () => {
  const navigate = useNavigate4();
  const theme = useTheme();
  const [checkingVerificationStatus, setCheckingVerificationStatus] = useState2(false);
  const pluginRegistry = usePluginRegistry4();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isAuthenticated, getAccessTokenSilently } = useAuth02();
  const {
    mutate: sendVerificationEmail,
    isPending: sendingVerificationEmail,
    alreadyVerified
  } = useEmailVerification();
  const publishNotificationEvent = useCallback(
    (msg, event_type, id) => {
      pluginRegistry.events.publish({
        id: "portal.notification",
        data: {
          message: msg,
          options: {
            type: event_type,
            toastId: id
          }
        }
      });
    },
    [pluginRegistry.events]
  );
  const authFailed = useCallback(() => {
    const msg = "Failed to authenticate. Redirecting to log in page...";
    publishNotificationEvent(msg, "info", "failed-to-authenticate");
    setTimeout(() => {
      navigate("/auth/logout");
    }, 2500);
  }, [publishNotificationEvent, navigate]);
  const checkVerificationStatus = useCallback(() => {
    setCheckingVerificationStatus(true);
    getAccessTokenSilently({ cacheMode: "off" }).then((token) => {
      const decoded = jwtDecode(token);
      const email_verified = Boolean(decoded[AUTH0_VERIFY_CLAIM]);
      if (email_verified) {
        publishNotificationEvent(
          "Your email has been successfully verified",
          "success",
          "email-verified"
        );
        pluginRegistry.events.publish(
          new AuthTokenSetEvent({
            accessToken: token,
            trigger: "auth-provider-continue-session"
          })
        );
        navigate("/auth");
      } else {
        publishNotificationEvent(
          "Your email has not been verified yet",
          "warning",
          "email-not-verified"
        );
      }
    }).catch((reason) => {
      authFailed();
    }).finally(() => {
      setCheckingVerificationStatus(false);
    });
  }, [authFailed, getAccessTokenSilently, navigate, pluginRegistry, publishNotificationEvent]);
  useEffect(() => {
    if (alreadyVerified || isAuthenticated) {
      checkVerificationStatus();
    }
  }, [alreadyVerified, checkVerificationStatus, isAuthenticated]);
  if (!isAuthenticated)
    return /* @__PURE__ */ jsx5(Fragment, {});
  return /* @__PURE__ */ jsxs4(
    Box4,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        rowGap: 3
      },
      children: [
        /* @__PURE__ */ jsx5(Typography4, { variant: "h1", textAlign: "center", children: "Almost There!" }),
        /* @__PURE__ */ jsx5(Typography4, { variant: "h5", textAlign: "center", children: "Please verify your email to login." }),
        /* @__PURE__ */ jsx5(Typography4, { variant: "body2", textAlign: "center", children: "You should have received an email which will guide you through the process of verifying your email." }),
        /* @__PURE__ */ jsxs4(
          Box4,
          {
            sx: {
              display: "flex",
              flexDirection: smallScreen ? "column" : "row",
              rowGap: 2,
              columnGap: 2,
              justifyContent: "center",
              alignItems: "center",
              ".MuiButton-root": {
                width: "275px"
                // Size evenly
              }
            },
            children: [
              /* @__PURE__ */ jsx5(
                Button4,
                {
                  title: "Sends a verification email to your email address so that you can complete the sign up process",
                  variant: "outlined",
                  disabled: sendingVerificationEmail,
                  startIcon: sendingVerificationEmail ? /* @__PURE__ */ jsx5(CircularProgress, { size: 25 }) : void 0,
                  onClick: () => sendVerificationEmail(),
                  children: sendingVerificationEmail ? "Sending verification email..." : "Resend verification email"
                }
              ),
              /* @__PURE__ */ jsx5(
                Button4,
                {
                  disabled: checkingVerificationStatus,
                  startIcon: checkingVerificationStatus ? /* @__PURE__ */ jsx5(CircularProgress, { size: 25 }) : void 0,
                  title: "Checks if your email has been successfully verified",
                  variant: "contained",
                  onClick: () => checkVerificationStatus(),
                  children: checkingVerificationStatus ? "Verifying..." : "Check verification status"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx5(Button4, { sx: { width: "max-content" }, variant: "text", onClick: () => navigate("/auth/logout"), children: "To Log In" })
      ]
    }
  );
};

// src/auth/RequireAuthPage.tsx
import { jsx as jsx6, jsxs as jsxs5 } from "react/jsx-runtime";
var useCurrentPlatformUser = (value) => {
  const { coreApi } = useApi2();
  const result = useResourceQuery(
    ["ORGANIZATION", "ME"],
    () => coreApi == null ? void 0 : coreApi.meGet().then((res) => res.data),
    __spreadValues({
      enabled: Boolean(value),
      refetchInterval: 3e4 * 100
    }, LONG_LIVED_QRY_CONFIG)
  );
  return result;
};
function RequireAuthPage({
  children,
  requiredPermissions
}) {
  var _a, _b, _c;
  const navigate = useNavigate5();
  const pluginRegistry = usePluginRegistry5();
  const { token, platform } = useAuth();
  const [wasLoggedIn, setWasLoggedIn] = useState3(false);
  const {
    data: platformUser,
    isPending: platformUserPending,
    isError: platformUserError,
    error: platformUserErrorData
  } = useCurrentPlatformUser(token == null ? void 0 : token.accessToken);
  const {
    data: roles,
    isPending: rolesPending,
    isError: rolesError
  } = useAllRoles({
    enabled: Boolean((_a = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _a.role_id)
  });
  const [hasValidRole, setHasValidRole] = useState3(void 0);
  const {
    isPending: orgProfilePending,
    isError: orgProfileError,
    data: orgProfile,
    status: orgProfileStatus
  } = useOrgProfile((_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id);
  const isMobile3 = useMobileEnabled();
  const missingAuthPlatformState = !(platform == null ? void 0 : platform.user) || !(platform == null ? void 0 : platform.org);
  useEffect2(() => {
    if (wasLoggedIn && platformUserError) {
      pluginRegistry.events.publish({
        id: "portal.notification",
        data: {
          message: "Failed to authenticate, it might be that your session expired. Redirecting to log in page...",
          options: {
            type: "info"
          }
        }
      });
      setTimeout(() => {
        navigate("/auth/logout");
      }, 2500);
    } else if ((platform == null ? void 0 : platform.user) && !platformUserError && !wasLoggedIn) {
      setWasLoggedIn(true);
    }
  }, [platform == null ? void 0 : platform.user, platformUserError, wasLoggedIn, navigate, pluginRegistry]);
  useEffect2(() => {
    var _a2, _b2;
    if (!(platformUser == null ? void 0 : platformUser.user) || rolesPending)
      return;
    if (!(roles == null ? void 0 : roles.length) || !platformUser.user.role_id || rolesError) {
      setHasValidRole(false);
    } else {
      const matchingRole = roles.find((role) => role.id === platformUser.user.role_id);
      if (matchingRole && ((_a2 = matchingRole.permissions) == null ? void 0 : _a2.length)) {
        setHasValidRole(
          !((_b2 = matchingRole == null ? void 0 : matchingRole.permissions) == null ? void 0 : _b2.map((permission) => permission == null ? void 0 : permission.toLowerCase()).includes("cross_organization:read"))
        );
      } else {
        setHasValidRole(false);
      }
    }
  }, [roles, platformUser == null ? void 0 : platformUser.user, rolesPending, rolesError]);
  useEffect2(() => {
    if ((platformUser == null ? void 0 : platformUser.user) && (platformUser == null ? void 0 : platformUser.organization) && missingAuthPlatformState && !platformUserPending && !rolesPending && hasValidRole) {
      pluginRegistry.events.publish(
        new AuthPlatformSetEvent({
          user: platformUser.user,
          org: platformUser.organization,
          permissions: platformUser.permissions
        })
      );
    }
  }, [
    platformUser,
    missingAuthPlatformState,
    platformUserPending,
    pluginRegistry,
    rolesPending,
    hasValidRole
  ]);
  useEffect2(() => {
    if (token == null ? void 0 : token.accessToken)
      return;
    if (window.location.pathname && !window.location.pathname.toLowerCase().includes("/auth")) {
      navigate("/auth", { state: { from: window.location.pathname } });
    }
  }, [navigate, token]);
  useEffect2(() => {
    return pluginRegistry.events.subscribe("settings.profile-onboard.completed", () => {
      navigate("/");
    });
  }, [pluginRegistry, navigate]);
  useEffect2(() => {
    return pluginRegistry.events.subscribe("settings.profile-onboard.needed", () => {
      navigate("/setup");
    });
  }, [pluginRegistry, navigate]);
  if (hasValidRole === false && (platformUser == null ? void 0 : platformUser.user)) {
    return /* @__PURE__ */ jsxs5(
      Box5,
      {
        sx: {
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "750px",
          flexDirection: "column",
          rowGap: 4
        },
        children: [
          /* @__PURE__ */ jsx6(Typography5, { variant: "h6", children: "Your account does not have the necessary permissions to access this app." }),
          /* @__PURE__ */ jsx6(Button5, { variant: "contained", onClick: () => navigate("/auth/logout"), children: "Log Out" })
        ]
      }
    );
  } else if (platformUserError || orgProfileError || orgProfileStatus === "success" && !orgProfile && isMobile3) {
    if (orgProfileError)
      return /* @__PURE__ */ jsx6(UnexpectedAuthError, { reason: "Failed to retrieve profile settings." });
    else if (orgProfileStatus === "success" && !orgProfile && isMobile3)
      return /* @__PURE__ */ jsx6(UnexpectedAuthError, { reason: "You can't use the mobile experience if you haven't set up your profile set." });
    const code = getCoreApiErrorCode(platformUserErrorData);
    const status = getCoreApiErrorStatus(platformUserErrorData);
    if (code === "UNVERIFIED_EMAIL" && status === "451")
      return /* @__PURE__ */ jsx6(UnverifiedEmailError, {});
    return /* @__PURE__ */ jsx6(UnexpectedAuthError, {});
  } else if (missingAuthPlatformState || orgProfilePending || rolesPending) {
    return /* @__PURE__ */ jsx6(
      Box5,
      {
        sx: {
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "750px"
        },
        children: /* @__PURE__ */ jsx6(
          LinearProgress,
          {
            sx: {
              width: "225px"
            }
          }
        )
      }
    );
  } else if ((platform == null ? void 0 : platform.user) && requiredPermissions && !hasPermissions(requiredPermissions, (_c = platform == null ? void 0 : platform.permissions) != null ? _c : [])) {
    return /* @__PURE__ */ jsx6(NotFoundPage, {});
  } else {
    return children;
  }
}

// src/helpers.tsx
import { jsx as jsx7 } from "react/jsx-runtime";
var downloadCsvFromString = (data, fileName) => {
  downloadBlobAs(new Blob([data], { type: "text/csv;charset=utf-8;" }), fileName, "csv");
};
var convertToCsv = (data, columns2) => {
  const validColumns = columns2.filter((col) => col.headerName);
  const csvRows = [
    validColumns.map((col) => col.headerName).join(","),
    ...data.map(
      (row) => validColumns.map((col) => row[col.field] !== void 0 ? row[col.field] : "").join(",")
    )
  ].join("\n");
  return csvRows;
};
var downloadBlobAs = (blob, fileName, fileExtension) => {
  const csvUrl = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = csvUrl;
  tempLink.setAttribute("download", `${fileName}.${fileExtension}`);
  tempLink.click();
};
var LINEA_TESTNET_NETWORK_ID = 59140;
var LINEA_NETWORK_ID = 59144;
var SEPOLIA_TESTNET_NETWORK_ID = 11155111;
var POLYGON_NETWORK_ID = 137;
var POLYGON_TESTNET_MUMBAI_NETWORK_ID = 80001;
var POLYGON_TESTNET_AMOY_NETWORK_ID = 80002;
function getExplorerUrl(networkId, slugValue, slugType = "address") {
  let url = "";
  if (networkId && slugValue) {
    switch (networkId) {
      case 1:
        url = `https://etherscan.io/${slugType}/${slugValue}`;
        break;
      case 4:
        url = `https://rinkeby.etherscan.io/${slugType}/${slugValue}`;
        break;
      case 5:
        url = `https://goerli.etherscan.io/${slugType}/${slugValue}`;
        break;
      case 100:
        url = `https://blockscout.com/xdai/mainnet/${slugType}/${slugValue}`;
        break;
      case POLYGON_NETWORK_ID:
        url = `https://polygonscan.com/${slugType}/${slugValue}`;
        break;
      case POLYGON_TESTNET_MUMBAI_NETWORK_ID:
        url = `https://mumbai.polygonscan.com/${slugType}/${slugValue}`;
        break;
      case POLYGON_TESTNET_AMOY_NETWORK_ID:
        url = `https://amoy.polygonscan.com/${slugType}/${slugValue}`;
        break;
      case LINEA_TESTNET_NETWORK_ID:
        url = `https://goerli.lineascan.build/${slugType}/${slugValue}`;
        break;
      case LINEA_NETWORK_ID:
        url = `https://lineascan.build/${slugType}/${slugValue}`;
        break;
      case SEPOLIA_TESTNET_NETWORK_ID:
        url = `https://sepolia.etherscan.io/${slugType}/${slugValue}`;
        break;
      case 11297108109:
        url = `https://www.ondora.xyz/network/palm/accounts/${slugValue}`;
        break;
      case 8453:
        url = `https://basescan.org/${slugType}/${slugValue}`;
        break;
      case 10:
        url = `https://optimistic.etherscan.io/${slugType}/${slugValue}`;
        break;
      case 42161:
        url = `https://arbiscan.io/${slugType}/${slugValue}`;
        break;
      default:
        break;
    }
  }
  return url;
}
var FORM_PREVENT_CANCEL = "preventCancel";
var FORM_PREVENT_SUBMIT = "preventSubmit";
var FORM_PREVENT_SUBMIT_LOADING = "preventSubmitLoading";
var uuidv4 = () => {
  return libUuidv4();
};
var ipfsToHttps = (url, gateway) => {
  if (!url.startsWith("ipfs://"))
    return url;
  const gatewayUrl = gateway != null ? gateway : "https://ipfs.io/ipfs/";
  return url.replace("ipfs://", gatewayUrl);
};
function snakeToKebabCase(snakeCase) {
  return snakeCase.toLowerCase().replace(/_/g, "-");
}
function snakeToPascalCase(snakeCase) {
  return snakeCase.toLowerCase().replace(/_/g, " ").replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  }).replace(/\s/g, "");
}
function snakeToTitleCase(snakeCase) {
  return snakeCase.toLowerCase().replace(/_/g, " ").replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
}
function coerceShortThemeColor(themeColor, theme) {
  try {
    return /[A-z]+\.[A-z]+/.test(themeColor) ? theme.palette[themeColor.split(".")[0]][themeColor.split(".")[1]] : (
      // Could be in format (e.g.) primary_500
      /[A-z]+_[0-9]{3}/.test(themeColor) ? theme.palette[themeColor] : themeColor
    );
  } catch (e) {
    return themeColor;
  }
}
var COMMON_IMAGE_MIME_TYPES = ["image/png", "image/jpeg", "image/gif"];
var PALM_NETWORK_ID = 11297108109;
var PALM_TESTNET_NETWORK_ID = 11297108099;
function validateStripeKeyAgainstEnv(publishableKey, paymentsEnv) {
  if (!publishableKey) {
    return void 0;
  }
  if (!paymentsEnv) {
    console.warn(
      "Stripe publishable key is set but payments env is not set. Please check your environment variables."
    );
    return void 0;
  }
  if (paymentsEnv === "test" && !publishableKey.startsWith("pk_test")) {
    console.warn(
      "Stripe publishable key is not set to test mode but payments env is set to test. Please check your environment variables."
    );
    return void 0;
  }
  if (paymentsEnv === "live" && !publishableKey.startsWith("pk_live")) {
    console.warn(
      "Stripe publishable key is not set to live mode but payments env is set to live. Please check your environment variables."
    );
    return void 0;
  }
  return publishableKey;
}
function getConfig(pluginRegistry) {
  var _a, _b, _c, _d, _e, _f, _g;
  const env = pluginRegistry.slots.getValue("shell.config");
  return {
    baseApiUrl: env.BASE_API_URL,
    basePublicApiUrl: env.PUBLIC_API_URL,
    baseDropApiUrl: env.DROP_API_URL,
    auth0Domain: env.AUTH0_DOMAIN,
    auth0ClientId: env.AUTH0_CLIENT_ID,
    auth0Audience: env.AUTH0_API_AUDIENCE,
    infuraPublicApiKey: env.PUBLIC_INFURA_KEY,
    environment: (_a = env.ENVIRONMENT) != null ? _a : "dev",
    wcProjectId: env.WC_PROJECT_ID,
    userVerifyUrl: env.USER_VERIFY_URL,
    singleMediaMaxFileSize: env.SINGLE_MEDIA_MAX_FILE_SIZE || "100MB",
    uploadZipMaxFileSize: env.UPLOAD_ZIP_MAX_FILE_SIZE || "100MB",
    // payments env should be live or test
    paymentsEnv: env.PAYMENTS_ENV,
    paymentsNetworkId: env.PAYMENTS_ENV === "live" ? 137 : 80002,
    stripeKey: validateStripeKeyAgainstEnv(env.STRIPE_PUBLISHABLE_KEY, env.PAYMENTS_ENV),
    dropNetworkIds: (_d = (_c = (_b = env.DROP_NETWORK_IDS) == null ? void 0 : _b.split(",")) == null ? void 0 : _c.map((id) => BigInt(id))) != null ? _d : [],
    newDropPresetNetworkIds: (_g = (_f = (_e = env.NEW_DROP_NETWORK_ID_PRESET) == null ? void 0 : _e.split(",")) == null ? void 0 : _f.map((id) => BigInt(id))) != null ? _g : [],
    featureFlagsPrefix: env.FEATURE_FLAGS_PREFIX
  };
}
function withQueryString(path, queryString) {
  return queryString ? `${path}?${queryString}` : path;
}
function withParams(path, params) {
  return params ? generatePath(path, params) : path;
}
function getPath(path, params, queryString) {
  return withQueryString(withParams(path, params), queryString);
}
function fillSlotsForPage(registry, config) {
  const element = config.anonymous ? config.element : /* @__PURE__ */ jsx7(RequireAuthPage, { requiredPermissions: config == null ? void 0 : config.requiredPermissions, children: config.element });
  registry.fillSlot(
    PORTAL_ROUTES_SLOT.id,
    {
      path: config.path === "/" ? void 0 : config.path,
      index: config.path === "/",
      element
    },
    { order: config.order }
  );
  const { icon } = config;
  if (icon) {
    registry.fillSlot(
      PORTAL_MAIN_MENU_BUTTONS_SLOT.id,
      {
        icon,
        commandId: config.id,
        tooltip: config.title
      },
      { order: config.order }
    );
  }
  registry.registerCommand({
    id: config.id,
    title: `Go to ${config.title}`,
    handler: (params, queryString = "") => {
      registry.commands.execute({
        id: "shell.navigate",
        to: getPath(config.path, params, queryString),
        options: params == null ? void 0 : params.options
      });
    }
  });
}
var NotificationEvent = class {
  constructor(data) {
    this.data = data;
    this.id = "portal.notification";
  }
};
var mainMenuButtonSchema = z.object({
  commandId: z.string(),
  icon: z.custom(),
  tooltip: z.string()
});
var PORTAL_MAIN_MENU_BUTTONS_SLOT = {
  id: "portal.main-menu.buttons",
  schema: mainMenuButtonSchema
};
var quickContextAction = z.object({
  commandId: z.string(),
  dataTestId: z.string(),
  description: z.string(),
  commandLabel: z.string().nullish(),
  title: z.string(),
  icon: z.custom().nullish()
});
var quickContextSchema = z.object({
  mainAction: quickContextAction,
  actions: z.array(quickContextAction).nullish(),
  accentColor: z.string(),
  textAccentColor: z.string().nullish(),
  illustration: z.custom(),
  order: z.number().optional()
});
var PORTAL_QUICK_ACTIONS_SLOT = {
  id: "portal.quick-actions",
  schema: quickContextSchema
};
var PORTAL_QUICK_CARDS_SLOT = {
  id: "portal.quick-cards",
  schema: quickContextSchema
};
var PORTAL_DROP_TOOL_SLOT = {
  id: "portal.tools",
  schema: quickContextSchema
};
var PORTAL_RECIPE_CARDS_SLOT = {
  id: "portal.recipes",
  schema: quickContextSchema
};
var PORTAL_HOME_DESKTOP_SLOT = {
  id: "portal.home-desktop",
  schema: z.function().returns(z.custom())
};
var PORTAL_HOME_MOBILE_SLOT = {
  id: "portal.home-mobile",
  schema: z.function().returns(z.custom())
};
var PORTAL_TOP_BAR_SLOT = {
  id: "portal.top-bar-sections",
  schema: z.function().returns(z.custom())
};
var PORTAL_APP_BAR_USER_PROFILE_SLOT = {
  id: "portal.app-bar-list.btns",
  schema: z.function().returns(z.custom())
};
var PORTAL_ROUTES_SLOT = {
  id: "portal.routes",
  schema: z.object({})
};
var PORTAL_THEME_SLOT = {
  id: "portal.theme",
  schema: z.object({})
};
var PORTAL_THEME_LOGO_TEXT_SLOT = {
  id: "portal.theme.logo-text",
  schema: z.function().returns(z.custom())
};
var PORTAL_THEME_ICONS = [
  "introduction",
  "image-placeholder",
  "detail",
  "collectible",
  "audience",
  "email",
  "schedule",
  "review",
  "step-not-started",
  "step-active",
  "step-complete",
  "step-incomplete",
  "airdrop",
  "freeClaim",
  "primarySale",
  "execute",
  "delete",
  "edit",
  "view",
  "duplicate",
  "cancel",
  "create",
  "polygon",
  "ethereum",
  "send",
  "criteria",
  "retry",
  "metamask-fox",
  "gate",
  "distribution"
];
function usePortalIcon(icon) {
  return useSlotValue(`portal.theme.icons.${icon}`);
}
function getBase64FromImageFile(file) {
  return __async(this, null, function* () {
    return yield new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function(error) {
        reject();
      };
    });
  });
}
var VideoToFramesMethod = /* @__PURE__ */ ((VideoToFramesMethod2) => {
  VideoToFramesMethod2[VideoToFramesMethod2["fps"] = 0] = "fps";
  VideoToFramesMethod2[VideoToFramesMethod2["totalFrames"] = 1] = "totalFrames";
  return VideoToFramesMethod2;
})(VideoToFramesMethod || {});
var VideoToFrames = class {
  /**
   * Extracts frames from the video and returns them as an array of imageData
   * @param videoUrl url to the video file (html5 compatible format) eg: mp4
   * @param amount number of frames per second or total number of frames that you want to extract
   * @param type [fps, totalFrames] The method of extracting frames: Number of frames per second of video or the total number of frames acros the whole video duration. defaults to fps
   */
  static getFrames(videoUrl, amount, type = 0 /* fps */) {
    return new Promise((resolve, reject) => {
      const frames = [];
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      let duration;
      const video = document.createElement("video");
      video.preload = "auto";
      const that = this;
      video.addEventListener("loadeddata", function() {
        return __async(this, null, function* () {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          duration = video.duration;
          let totalFrames = amount;
          if (type === 0 /* fps */) {
            totalFrames = duration * amount;
          }
          for (let time = 0; time < duration; time += duration / totalFrames) {
            frames.push(yield that.getVideoFrame(video, context, canvas, time));
          }
          resolve(frames);
        });
      });
      video.src = videoUrl;
      video.load();
    });
  }
  static getVideoFrame(video, context, canvas, time) {
    return new Promise((resolve, reject) => {
      const eventCallback = () => {
        video.removeEventListener("seeked", eventCallback);
        this.storeFrame(video, context, canvas, resolve);
      };
      video.addEventListener("seeked", eventCallback);
      video.currentTime = time;
    });
  }
  static storeFrame(video, context, canvas, resolve) {
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    resolve(canvas.toDataURL());
  }
};
function dataURLtoFile(dataurl, filename) {
  var _a, _b;
  var arr = dataurl.split(","), mime = (_b = (_a = arr == null ? void 0 : arr[0]) == null ? void 0 : _a.match(/:(.*?);/)) == null ? void 0 : _b[1], bstr = atob(arr[arr.length - 1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
var getImagePreviewFromVideo = (video, imagePreviewFileName) => __async(void 0, null, function* () {
  try {
    if (video) {
      const frames = yield VideoToFrames.getFrames(URL.createObjectURL(video), 1, 1);
      if (frames.length > 0 && Boolean(frames[0])) {
        return dataURLtoFile(frames[0], imagePreviewFileName || "video-preview.png");
      } else
        return void 0;
    } else
      return void 0;
  } catch (e) {
    console.error("Failed to make image preview from the given video file.");
    return void 0;
  }
});

// src/mui-overrides.tsx
import "@mui/material/styles";

// src/useCoerceColorToTheme.tsx
import { useTheme as useTheme2 } from "@mui/material";
import { useMemo as useMemo2 } from "react";
var useCoerceColorToTheme = (uncoercedColor) => {
  const theme = useTheme2();
  const coercedAccentColor = useMemo2(() => {
    return uncoercedColor ? coerceShortThemeColor(uncoercedColor, theme) : void 0;
  }, [uncoercedColor, theme]);
  return coercedAccentColor;
};

// src/useCopyToClipboard.tsx
import { usePluginRegistry as usePluginRegistry6 } from "plugin-system";
import { useCallback as useCallback2 } from "react";
var useCopyToClipboard = () => {
  const pluginRegistry = usePluginRegistry6();
  return useCallback2(
    (toCopy) => {
      if (!toCopy)
        return;
      navigator.clipboard.writeText(toCopy);
      pluginRegistry.events.publish({
        id: "portal.notification",
        data: {
          message: "Copied to clipboard!",
          options: {
            type: "success"
          }
        }
      });
    },
    [pluginRegistry]
  );
};

// src/useFeatureFlags.tsx
import { usePluginState as usePluginState2 } from "plugin-system";
var useFeatureFlags = () => {
  const { flags } = usePluginState2("feature-flags", {
    flags: {}
  });
  const { featureFlagsPrefix } = useConfig();
  const isFeatureEnabled = (name, defaultValue = false) => {
    var _a;
    return (_a = flags[name]) != null ? _a : defaultValue;
  };
  return {
    allowRestrictOrgNetworks: isFeatureEnabled(featureFlagsPrefix + "restrict-org-networks"),
    d2EIntroNew: isFeatureEnabled(featureFlagsPrefix + "d2e-intro-new"),
    onboardingMarketingUpdatesConsent: isFeatureEnabled(
      featureFlagsPrefix + "onboarding-marketing-updates-consent"
    )
  };
};

// src/useDebounce.tsx
import { useState as useState4, useEffect as useEffect3 } from "react";
function useDebouncedEffect(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState4(value);
  useEffect3(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}
var useDebounce = useDebouncedEffect;

// src/useConsent.tsx
import { isEmailAddress, useApi as useApi3 } from "common-api";
import { useCallback as useCallback3 } from "react";
function checkConsentViaReport(email, consentReport) {
  var _a, _b;
  return Boolean(consentReport) && Boolean(email) && (!isEmailAddress(email) || !((_a = consentReport == null ? void 0 : consentReport.rejected_addresses) == null ? void 0 : _a.length) || ((_b = consentReport == null ? void 0 : consentReport.rejected_addresses) == null ? void 0 : _b.indexOf(email.toLowerCase())) === -1);
}
var useCheckConsentSingle = () => {
  const { dropApi } = useApi3();
  const checkConsent = useCallback3(
    (address) => __async(void 0, null, function* () {
      if (!address)
        return false;
      else if (!isEmailAddress(address))
        return true;
      return yield dropApi.betaCheckEmailConsentControllerCheckEmailConsent({ email_addresses: [address] }).then((data) => data.data).then((report) => {
        return checkConsentViaReport(address, report);
      });
    }),
    [dropApi]
  );
  return { checkConsent };
};
var useCheckConsentMultiple = () => {
  const { dropApi } = useApi3();
  const checkConsent = useCallback3(
    (addresses) => __async(void 0, null, function* () {
      return yield dropApi.betaCheckEmailConsentControllerCheckEmailConsent({
        email_addresses: addresses == null ? void 0 : addresses.filter(
          (address) => Boolean(address) && isEmailAddress(address)
        )
      }).then((data) => data.data);
    }),
    [dropApi]
  );
  return { checkConsent };
};

// src/OrganizationLogo.tsx
import styled from "@emotion/styled";
import { SlotRenderer } from "plugin-system";
import { jsx as jsx8 } from "react/jsx-runtime";
var LogoText = styled((_a) => {
  var _b = _a, { fillColor } = _b, rest = __objRest(_b, ["fillColor"]);
  return /* @__PURE__ */ jsx8(SlotRenderer, __spreadProps(__spreadValues({ slot: "portal.theme.logo-text" }, rest), { viewBox: "0 0 265 18" }));
})`
  > path {
    fill: ${(props) => {
  var _a;
  return (_a = props.fillColor) != null ? _a : "white";
}};
  }
`;
function OrganizationLogo({ fillColor, textHeight, textWidth }) {
  return /* @__PURE__ */ jsx8(
    LogoText,
    {
      height: textHeight != null ? textHeight : "35px",
      fillColor: fillColor != null ? fillColor : "white",
      width: textWidth != null ? textWidth : "265px"
    }
  );
}

// src/PhosphorLoading.tsx
import { jsx as jsx9 } from "react/jsx-runtime";
var PhosphorLoading = ({
  width,
  height,
  contained
}) => {
  return /* @__PURE__ */ jsx9(
    "img",
    {
      style: { width, height },
      src: contained ? "/phosphor-loading-contained.gif" : "/phosphor-loading.gif",
      alt: "loading"
    }
  );
};

// src/BasicTabs.tsx
import { Box as Box6, Typography as Typography6, Tabs, Tab } from "@mui/material";
import React from "react";
import { jsx as jsx10, jsxs as jsxs6 } from "react/jsx-runtime";
function TabPanel(props) {
  const _a = props, { value, index, content } = _a, other = __objRest(_a, ["value", "index", "content"]);
  return /* @__PURE__ */ jsx10(
    "div",
    __spreadProps(__spreadValues({
      role: "tabpanel",
      hidden: value !== index,
      id: `simple-tabpanel-${index}`,
      "aria-labelledby": `simple-tab-${index}`
    }, other), {
      children: value === index && content.children && /* @__PURE__ */ jsx10(Box6, { sx: { p: 3 }, children: /* @__PURE__ */ jsx10(Typography6, { children: content.children }) })
    })
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
var BasicTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  return /* @__PURE__ */ jsxs6(Box6, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsx10(Box6, { children: /* @__PURE__ */ jsx10(Tabs, { value, onChange: handleChange, "aria-label": "basic tabs", children: props.tabs.map((tab, i) => {
      return /* @__PURE__ */ jsx10(Tab, __spreadValues({ label: tab.title }, a11yProps(i)));
    }) }) }),
    props.tabs.map((tabContent, i) => {
      return /* @__PURE__ */ jsx10(TabPanel, { value, index: i, content: tabContent }, tabContent.title);
    })
  ] });
};

// src/MobileNotificationModal.tsx
import { Modal, Paper as Paper2, Box as Box7, Divider, Button as Button6, Typography as Typography7, useTheme as useTheme4 } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import { isMobile as isMobile2 } from "react-device-detect";
import { useMemo as useMemo3 } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ReportIcon from "@mui/icons-material/Report";

// src/ConfettiExplosion.tsx
import { useTheme as useTheme3 } from "@mui/material";
import ReactConfettiExplosion from "react-confetti-explosion";
import { Fragment as Fragment2, jsx as jsx11 } from "react/jsx-runtime";
var ConfettiExplosion = ({ isExploding, confettiProps }) => {
  const theme = useTheme3();
  return /* @__PURE__ */ jsx11(Fragment2, { children: isExploding && /* @__PURE__ */ jsx11(
    ReactConfettiExplosion,
    __spreadValues({
      colors: [
        theme.palette.primary.main,
        theme.palette.primary.dark,
        theme.palette.primary.light,
        theme.palette.secondary.main,
        theme.palette.secondary.dark,
        theme.palette.secondary.light,
        theme.palette.warning.main,
        theme.palette.success.main,
        theme.palette.error.main
      ],
      particleCount: 250,
      duration: 3500
    }, confettiProps != null ? confettiProps : {})
  ) });
};
var ConfettiExplosion_default = ConfettiExplosion;

// src/MobileNotificationModal.tsx
import { Fragment as Fragment3, jsx as jsx12, jsxs as jsxs7 } from "react/jsx-runtime";
var MobileNotificationModal = ({
  onClose,
  title,
  caption,
  type,
  top,
  left
}) => {
  const theme = useTheme4();
  const IconForType = useMemo3(() => {
    if (type === "success") {
      return /* @__PURE__ */ jsx12(CelebrationIcon, {});
    } else if (type === "info") {
      return /* @__PURE__ */ jsx12(InfoIcon, {});
    } else if (type === "warning") {
      return /* @__PURE__ */ jsx12(WarningIcon, {});
    } else if (type === "error") {
      return /* @__PURE__ */ jsx12(ReportIcon, {});
    } else
      return /* @__PURE__ */ jsx12(Fragment3, {});
  }, [type]);
  const iconColor = useMemo3(() => {
    if (type === "success") {
      return theme.palette.success.main;
    } else if (type === "info") {
      return theme.palette.info.main;
    } else if (type === "warning") {
      return theme.palette.warning.main;
    } else if (type === "error") {
      return theme.palette.error.main;
    } else
      return theme.palette.primary.main;
  }, [theme, type]);
  return /* @__PURE__ */ jsx12(Modal, { open: true, onClose, children: /* @__PURE__ */ jsxs7(
    Paper2,
    {
      sx: {
        position: "absolute",
        top: top || "35%",
        left: left || "50%",
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        alignItems: "center",
        justifyContent: "center",
        transform: "translate(-50%, -50%)",
        width: isMobile2 ? "85%" : "636px",
        p: 4,
        borderRadius: "14px",
        ".MuiTypography-root": {
          textAlign: "center",
          overflowWrap: "break-word"
        }
      },
      elevation: 5,
      children: [
        type === "success" && /* @__PURE__ */ jsx12(Box7, { sx: { position: "absolute", left: "50%", top: "50%" }, children: /* @__PURE__ */ jsx12(ConfettiExplosion_default, { isExploding: true, confettiProps: { zIndex: 1e4 } }) }),
        /* @__PURE__ */ jsx12(
          Box7,
          {
            sx: {
              width: "125px",
              height: "125px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ".MuiSvgIcon-root": {
                fontSize: 100,
                fill: iconColor
              }
            },
            children: IconForType
          }
        ),
        /* @__PURE__ */ jsx12(Divider, { sx: { width: "100%" } }),
        title && typeof title === "string" && /* @__PURE__ */ jsx12(Typography7, { variant: "h6", children: title }),
        title && typeof title !== "string" && title,
        caption && typeof caption === "string" && /* @__PURE__ */ jsx12(Typography7, { variant: "bodyXL", children: caption }),
        caption && typeof caption !== "string" && caption,
        /* @__PURE__ */ jsx12(Divider, { sx: { width: "100%" } }),
        /* @__PURE__ */ jsx12(Box7, { sx: { display: "flex", justifyContent: "flex-end", width: "100%" }, children: /* @__PURE__ */ jsx12(Button6, { variant: "contained", color: "primary", onClick: onClose, children: "Close" }) })
      ]
    }
  ) });
};

// src/MobileTileButton.tsx
import { Box as Box8, LinearProgress as LinearProgress2, Typography as Typography8 } from "@mui/material";
import { jsx as jsx13, jsxs as jsxs8 } from "react/jsx-runtime";
var MobileTileButton = ({
  Icon,
  label,
  onClick,
  disabled = false,
  loading,
  rootSx
}) => {
  return /* @__PURE__ */ jsxs8(
    Box8,
    {
      sx: __spreadValues({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: (theme) => theme.palette.neutral_50,
        border: (theme) => `1px solid ${theme.palette.neutral_50}`,
        borderRadius: "20px",
        opacity: disabled || loading ? 0.5 : 1,
        boxShadow: (theme) => disabled || loading ? "none" : theme.shadows[3],
        p: 2,
        "&:hover": {
          cursor: "pointer"
        }
      }, rootSx != null ? rootSx : {}),
      onClick: () => {
        if (!disabled && !loading) {
          onClick();
        }
      },
      children: [
        /* @__PURE__ */ jsx13(
          Box8,
          {
            sx: {
              ".MuiSvgIcon-root ": {
                fontSize: "40px",
                fill: (theme) => disabled || loading ? theme.palette.neutral_50 : theme.palette.primary.main
              }
            },
            children: Icon
          }
        ),
        loading ? /* @__PURE__ */ jsx13(Box8, { sx: { width: "100%", my: 2, flexBasis: "55px" }, children: /* @__PURE__ */ jsx13(LinearProgress2, { sx: { width: "100%", my: 2 } }) }) : /* @__PURE__ */ jsx13(
          Typography8,
          {
            variant: "body1",
            sx: {
              flexBasis: "55px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center"
            },
            textAlign: "center",
            children: label
          }
        )
      ]
    }
  );
};

// src/MobileTileOverview.tsx
import { Box as Box9, Card, CardContent, CardHeader, Divider as Divider2 } from "@mui/material";
import { Fragment as Fragment4, jsx as jsx14, jsxs as jsxs9 } from "react/jsx-runtime";
var MobileTileOverview = ({
  TitleIcon,
  title,
  actions,
  actionsPrepend
}) => {
  return /* @__PURE__ */ jsxs9(
    Card,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        rowGap: 1,
        backgroundColor: "transparent",
        p: 0
      },
      elevation: 0,
      children: [
        title && /* @__PURE__ */ jsxs9(Fragment4, { children: [
          /* @__PURE__ */ jsx14(
            CardHeader,
            {
              title,
              titleTypographyProps: { variant: "h5" },
              sx: { p: 0, ".MuiSvgIcon-root": { fontSize: "40px" } },
              avatar: TitleIcon
            }
          ),
          /* @__PURE__ */ jsx14(Divider2, { sx: { width: "100%" } })
        ] }),
        /* @__PURE__ */ jsxs9(
          CardContent,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              rowGap: 3,
              p: `${title ? "16px" : "0"} 0 0 0 !important`
            },
            children: [
              Boolean(actionsPrepend) && actionsPrepend,
              /* @__PURE__ */ jsx14(Box9, { sx: { display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: 2, rowGap: 2 }, children: actions.map((props, index) => /* @__PURE__ */ jsx14(MobileTileButton, __spreadValues({}, props), `community-mobile-action-${index}`)) })
            ]
          }
        )
      ]
    }
  );
};

// src/ShareQRCodeCard.tsx
import {
  Card as Card2,
  CardHeader as CardHeader2,
  Divider as Divider3,
  CardContent as CardContent2,
  Box as Box10,
  Skeleton,
  Alert,
  useTheme as useTheme5,
  Tab as Tab2,
  Tabs as Tabs2,
  Button as Button7,
  IconButton,
  Link,
  Tooltip
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";
import { useCreateQRCodeForUrl, LONG_LIVED_QRY_STALE } from "common-api";
import { usePluginRegistry as usePluginRegistry7 } from "plugin-system";
import { useEffect as useEffect4, useRef, useState as useState5 } from "react";
import { Fragment as Fragment5, jsx as jsx15, jsxs as jsxs10 } from "react/jsx-runtime";
var SHARE_QR_CODE_TAB_INDEX = /* @__PURE__ */ ((SHARE_QR_CODE_TAB_INDEX2) => {
  SHARE_QR_CODE_TAB_INDEX2[SHARE_QR_CODE_TAB_INDEX2["METAMASK_DEEPLINK"] = 0] = "METAMASK_DEEPLINK";
  SHARE_QR_CODE_TAB_INDEX2[SHARE_QR_CODE_TAB_INDEX2["REGULAR_LINK"] = 1] = "REGULAR_LINK";
  return SHARE_QR_CODE_TAB_INDEX2;
})(SHARE_QR_CODE_TAB_INDEX || {});
var ShareQrCodeCardContent = ({
  qrCodeUri,
  isPending,
  isError,
  downloadContext,
  valueUri,
  qrCodeHeight = "400px",
  orientation
}) => {
  const downloadAnchorRef = useRef(null);
  const [imgLoaded, setImgLoaded] = useState5(false);
  const isMobile3 = useMobileEnabled();
  const theme = useTheme5();
  const copyToClipboard = useCopyToClipboard();
  return /* @__PURE__ */ jsxs10(Fragment5, { children: [
    valueUri && isMobile3 && /* @__PURE__ */ jsxs10(
      Box10,
      {
        sx: {
          display: "flex",
          justifyContent: orientation === "vertical" ? "center" : "flex-start"
        },
        children: [
          /* @__PURE__ */ jsx15(Link, { href: valueUri, target: "_blank", sx: { display: "flex", alignItems: "center" }, children: `${valueUri.substring(0, 40)}${valueUri.length > 40 ? "..." : ""}` }),
          /* @__PURE__ */ jsx15(IconButton, { title: valueUri, color: "secondary", onClick: () => copyToClipboard(valueUri), children: /* @__PURE__ */ jsx15(ContentCopyIcon, {}) })
        ]
      }
    ),
    /* @__PURE__ */ jsxs10(
      Box10,
      {
        sx: {
          display: "flex",
          flexDirection: orientation === "vertical" ? "column" : "row-reverse",
          columnGap: 2,
          rowGap: 2,
          alignItems: "center",
          justifyContent: orientation === "vertical" ? "center" : "flex-end"
        },
        children: [
          /* @__PURE__ */ jsxs10(
            Box10,
            {
              sx: {
                display: "grid",
                columnGap: 2,
                gridTemplateColumns: orientation === "vertical" ? "1fr 1fr" : "1fr",
                rowGap: 1,
                padding: 1
              },
              children: [
                valueUri && !isMobile3 && /* @__PURE__ */ jsxs10(
                  Box10,
                  {
                    sx: {
                      display: "flex",
                      justifyContent: orientation === "vertical" ? "center" : "flex-start"
                    },
                    children: [
                      /* @__PURE__ */ jsx15(Tooltip, { title: valueUri, children: /* @__PURE__ */ jsx15(
                        Link,
                        {
                          href: valueUri,
                          target: "_blank",
                          sx: { display: "flex", alignItems: "center" },
                          "data-testid": "qr-code-value-link",
                          children: `${valueUri.substring(0, 40)}${valueUri.length > 40 ? "..." : ""}`
                        }
                      ) }),
                      /* @__PURE__ */ jsx15(
                        IconButton,
                        {
                          title: valueUri,
                          color: "secondary",
                          onClick: () => copyToClipboard(valueUri),
                          children: /* @__PURE__ */ jsx15(ContentCopyIcon, {})
                        }
                      )
                    ]
                  }
                ),
                isMobile3 ? /* @__PURE__ */ jsx15(
                  MobileTileButton,
                  {
                    Icon: /* @__PURE__ */ jsx15(DownloadIcon, {}),
                    label: "Download QR",
                    onClick: () => {
                      if (downloadAnchorRef == null ? void 0 : downloadAnchorRef.current)
                        downloadAnchorRef == null ? void 0 : downloadAnchorRef.current.click();
                    },
                    disabled: !Boolean(qrCodeUri),
                    loading: isPending,
                    rootSx: { width: "100%" }
                  }
                ) : /* @__PURE__ */ jsx15(
                  Button7,
                  {
                    onClick: () => {
                      if (downloadAnchorRef == null ? void 0 : downloadAnchorRef.current)
                        downloadAnchorRef == null ? void 0 : downloadAnchorRef.current.click();
                    },
                    variant: "text",
                    color: "primary",
                    startIcon: /* @__PURE__ */ jsx15(DownloadIcon, {}),
                    children: "Download QR"
                  }
                ),
                isMobile3 ? /* @__PURE__ */ jsx15(
                  MobileTileButton,
                  {
                    Icon: /* @__PURE__ */ jsx15(ContentCopyIcon, {}),
                    label: "Copy QR Code Link",
                    onClick: () => {
                      copyToClipboard(qrCodeUri);
                    },
                    disabled: !Boolean(qrCodeUri),
                    loading: isPending,
                    rootSx: { width: "100%" }
                  }
                ) : /* @__PURE__ */ jsx15(
                  Button7,
                  {
                    onClick: () => {
                      copyToClipboard(qrCodeUri);
                    },
                    variant: "text",
                    color: "primary",
                    startIcon: /* @__PURE__ */ jsx15(ContentCopyIcon, {}),
                    children: "Copy QR code Link"
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ jsxs10(
            Box10,
            {
              sx: {
                display: "flex",
                justifyContent: "center"
              },
              children: [
                (isPending || !qrCodeUri) && !isError && !imgLoaded && /* @__PURE__ */ jsx15(Skeleton, { variant: "rectangular", height: qrCodeHeight, width: qrCodeHeight }),
                isError && /* @__PURE__ */ jsx15(
                  Alert,
                  {
                    severity: "error",
                    sx: {
                      ".MuiAlert-message": {
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center"
                      }
                    },
                    children: "Failed to create QR code"
                  }
                ),
                Boolean(qrCodeUri) && /* @__PURE__ */ jsxs10(
                  Box10,
                  {
                    sx: {
                      height: qrCodeHeight,
                      maxWidth: "350px",
                      position: "relative"
                    },
                    children: [
                      !imgLoaded && /* @__PURE__ */ jsx15(
                        Skeleton,
                        {
                          sx: {
                            height: "100%",
                            minWidth: "qrCodeHeight",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 100
                          },
                          variant: "rectangular"
                        }
                      ),
                      /* @__PURE__ */ jsx15(
                        "img",
                        {
                          src: qrCodeUri,
                          alt: "QR Code",
                          style: {
                            width: "100%",
                            height: "100%",
                            maxWidth: "400px",
                            maxHeight: "400px",
                            boxShadow: theme.shadows[3],
                            objectFit: "contain"
                          },
                          onLoad: () => setImgLoaded(true)
                        }
                      )
                    ]
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    Boolean(qrCodeUri) && // eslint-disable-next-line jsx-a11y/anchor-has-content
    /* @__PURE__ */ jsx15(
      "a",
      {
        ref: downloadAnchorRef,
        href: qrCodeUri,
        target: "_blank",
        rel: "noreferrer",
        download: `${downloadContext}-phosphor-qr-code.png`,
        style: { visibility: "hidden", marginTop: "-50px" }
      }
    )
  ] });
};
var ShareQRCodeCard = ({
  isPending,
  qrCodeValue,
  showQrCodeValue,
  cardHeaderProps,
  downloadContext,
  metamaskDeepLinkQrCodeValue,
  qrCodeHeight,
  orientation = "vertical",
  initialTabPreference = 0 /* METAMASK_DEEPLINK */
}) => {
  var _a;
  const isMobile3 = useMobileEnabled();
  const pluginRegistry = usePluginRegistry7();
  const [tabIndex, setTabIndex] = useState5(initialTabPreference);
  const MetamaskIcon = usePortalIcon("metamask-fox");
  const {
    mutate: createQrCode,
    isPending: creatingQrCode,
    isError: createQrCodeError,
    data: createdQrCode
  } = useCreateQRCodeForUrl({
    gcTime: LONG_LIVED_QRY_STALE * 2 * 24
    // A day
  });
  const {
    mutate: createMetamaskDeepLinkQrCode,
    isPending: creatingMetamaskDeepLinkQrCode,
    isError: createMetamaskDeepLinkQrCodeError,
    data: createdMetamaskDeepLinkQrCode
  } = useCreateQRCodeForUrl({
    gcTime: LONG_LIVED_QRY_STALE * 2 * 24
    // A day
  });
  useEffect4(() => {
    if (createQrCodeError) {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "Failed to create QR code. If this problem persists, please contact support.",
          contactSupport: true,
          options: {
            type: "error",
            toastId: "generate-phosphor-qr-code-error"
          }
        })
      );
    }
  }, [pluginRegistry, createQrCodeError]);
  useEffect4(() => {
    if (!Boolean(qrCodeValue) || Boolean(createdQrCode == null ? void 0 : createdQrCode.uri) || creatingQrCode)
      return;
    createQrCode({ qrCodeValue });
  }, [createdQrCode, isPending, creatingQrCode, qrCodeValue, createQrCode]);
  useEffect4(() => {
    if (!Boolean(metamaskDeepLinkQrCodeValue) || Boolean(createdMetamaskDeepLinkQrCode == null ? void 0 : createdMetamaskDeepLinkQrCode.uri) || creatingMetamaskDeepLinkQrCode)
      return;
    createMetamaskDeepLinkQrCode({
      qrCodeValue: metamaskDeepLinkQrCodeValue,
      asMetamaskDeepLink: true
    });
  }, [
    createdMetamaskDeepLinkQrCode,
    isPending,
    creatingMetamaskDeepLinkQrCode,
    metamaskDeepLinkQrCodeValue,
    createMetamaskDeepLinkQrCode
  ]);
  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };
  useEffect4(() => {
    if (tabIndex === 0 /* METAMASK_DEEPLINK */ && !metamaskDeepLinkQrCodeValue && qrCodeValue) {
      setTabIndex(1 /* REGULAR_LINK */);
    }
  }, [tabIndex, metamaskDeepLinkQrCodeValue, qrCodeValue]);
  return /* @__PURE__ */ jsxs10(
    Card2,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        backgroundColor: "transparent"
      },
      elevation: 0,
      children: [
        cardHeaderProps && /* @__PURE__ */ jsxs10(Fragment5, { children: [
          /* @__PURE__ */ jsx15(
            CardHeader2,
            __spreadProps(__spreadValues({
              titleTypographyProps: { variant: "h6" }
            }, cardHeaderProps), {
              sx: __spreadValues({ p: 0 }, (_a = cardHeaderProps == null ? void 0 : cardHeaderProps.sx) != null ? _a : {})
            })
          ),
          /* @__PURE__ */ jsx15(Divider3, { sx: { width: "100%" } })
        ] }),
        /* @__PURE__ */ jsx15(
          CardContent2,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              py: "12px",
              px: 0
            },
            children: isPending ? /* @__PURE__ */ jsxs10(Fragment5, { children: [
              /* @__PURE__ */ jsxs10(Box10, { sx: { display: "flex", columnGap: 2, flexBasis: "75px" }, children: [
                /* @__PURE__ */ jsx15(Skeleton, { variant: "rectangular", height: 40, sx: { flexGrow: 1 } }),
                /* @__PURE__ */ jsx15(Skeleton, { variant: "rectangular", height: 40, sx: { flexGrow: 1 } })
              ] }),
              /* @__PURE__ */ jsx15(Skeleton, { variant: "rectangular", height: "calc(100% - 75px)", sx: { flexGrow: 1 } })
            ] }) : /* @__PURE__ */ jsxs10(Fragment5, { children: [
              metamaskDeepLinkQrCodeValue && /* @__PURE__ */ jsxs10(
                Tabs2,
                {
                  sx: {
                    width: "100%",
                    svg: { width: isMobile3 ? "45px" : "25px", height: isMobile3 ? "45px" : "25px" }
                  },
                  value: tabIndex,
                  variant: "fullWidth",
                  onChange: handleTabChange,
                  "aria-label": `share-qr-code-${downloadContext}`,
                  children: [
                    /* @__PURE__ */ jsx15(
                      Tab2,
                      {
                        sx: { display: "flex", flexDirection: "row", columnGap: 1 },
                        icon: /* @__PURE__ */ jsx15(LinkIcon, { sx: { color: (theme) => theme.palette.secondary.main } }),
                        "data-testid": "share-qr-code-regular-link-tab",
                        value: 1 /* REGULAR_LINK */,
                        label: "Regular Link"
                      }
                    ),
                    /* @__PURE__ */ jsx15(
                      Tab2,
                      {
                        sx: { display: "flex", flexDirection: "row", columnGap: 1 },
                        icon: /* @__PURE__ */ jsx15(MetamaskIcon, {}),
                        "data-testid": "share-qr-code-metamask-deep-link-tab",
                        value: 0 /* METAMASK_DEEPLINK */,
                        label: "Metamask Deeplink"
                      }
                    )
                  ]
                }
              ),
              tabIndex === 0 /* METAMASK_DEEPLINK */ && Boolean(metamaskDeepLinkQrCodeValue) && /* @__PURE__ */ jsx15(
                ShareQrCodeCardContent,
                {
                  isError: createMetamaskDeepLinkQrCodeError,
                  isPending: creatingMetamaskDeepLinkQrCode,
                  downloadContext,
                  qrCodeUri: createdMetamaskDeepLinkQrCode == null ? void 0 : createdMetamaskDeepLinkQrCode.uri,
                  valueUri: showQrCodeValue ? metamaskDeepLinkQrCodeValue : void 0,
                  qrCodeHeight,
                  orientation
                }
              ),
              tabIndex === 1 /* REGULAR_LINK */ && /* @__PURE__ */ jsx15(
                ShareQrCodeCardContent,
                {
                  isError: createQrCodeError,
                  isPending: creatingQrCode,
                  downloadContext,
                  qrCodeUri: createdQrCode == null ? void 0 : createdQrCode.uri,
                  valueUri: showQrCodeValue ? qrCodeValue : void 0,
                  qrCodeHeight,
                  orientation
                }
              )
            ] })
          }
        )
      ]
    }
  );
};

// src/MobileCaptureRecipient.tsx
import { Alert as Alert2, Box as Box11, Button as Button8, Divider as Divider4, LinearProgress as LinearProgress3, TextField, Typography as Typography9 } from "@mui/material";
import { QrReader } from "react-qr-reader";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { isAddress } from "ethers";
import { useCallback as useCallback4, useEffect as useEffect5, useState as useState6 } from "react";
import { isEmailAddress as isEmailAddress2 } from "common-api";
import { Fragment as Fragment6, jsx as jsx16, jsxs as jsxs11 } from "react/jsx-runtime";
var MobileCaptureRecipient = ({
  manualRecipientTypes,
  scanRecipientTypes,
  confirmLabel,
  handleCapturedValue,
  textFieldLabel = "Specify Address",
  processingLabel,
  scanLabel,
  isProcessingCapturedValue,
  allowManualInput = true
}) => {
  const { checkConsent } = useCheckConsentSingle();
  const [error, setError] = useState6(void 0);
  const [scanning, setScanning] = useState6(false);
  const [useScanner, setUseScanner] = useState6(false);
  const [capturedInputValue, setCapturedInputValue] = useState6("");
  const validateCapturedValue = useCallback4(
    (value, source) => __async(void 0, null, function* () {
      let coercedValue = value;
      try {
        coercedValue = value && (value == null ? void 0 : value.toLowerCase().indexOf("0x")) !== -1 && (value == null ? void 0 : value.length) > 42 ? value == null ? void 0 : value.toLowerCase().substring(
          value == null ? void 0 : value.toLowerCase().indexOf("0x"),
          (value == null ? void 0 : value.toLowerCase().indexOf("0x")) + 42
        ) : value;
      } catch (e) {
        coercedValue = value;
      }
      const isEthAddress = coercedValue && isAddress(coercedValue);
      const isEmail = coercedValue && isEmailAddress2(coercedValue);
      const coercedRecipientTypes = source === "SCANNER" ? scanRecipientTypes : manualRecipientTypes;
      if (!coercedValue || coercedValue && (coercedRecipientTypes.includes("ETHEREUM_ADDRESS") && !isEthAddress && !isEmail || !coercedRecipientTypes.includes("ETHEREUM_ADDRESS") && isEthAddress || coercedRecipientTypes.includes("EMAIL") && !isEmail && !isEthAddress || !coercedRecipientTypes.includes("EMAIL") && isEmail)) {
        return {
          error: source === "SCANNER" ? "Please scan a valid QR code." : "Please enter a valid address",
          coercedValue: void 0
        };
      } else if (coercedValue && isEmail && coercedRecipientTypes.includes("EMAIL") && !(yield checkConsent(coercedValue))) {
        return { error: "You do not have consent to add this address" };
      } else
        return { coercedValue };
    }),
    [checkConsent, manualRecipientTypes, scanRecipientTypes]
  );
  useEffect5(() => {
    if (!useScanner)
      setScanning(false);
  }, [useScanner]);
  const handleSubmittedValue = useCallback4(
    (candidate, source) => __async(void 0, null, function* () {
      const validationResult = yield validateCapturedValue(candidate, source);
      setError(validationResult == null ? void 0 : validationResult.error);
      const coercedCandidate = (validationResult == null ? void 0 : validationResult.coercedValue) || candidate;
      if (!(validationResult == null ? void 0 : validationResult.error) && coercedCandidate) {
        handleCapturedValue(
          coercedCandidate,
          isAddress(coercedCandidate) ? "ETHEREUM_ADDRESS" : "EMAIL"
        );
        setUseScanner(false);
        setCapturedInputValue("");
        setError(void 0);
      }
    }),
    [validateCapturedValue, handleCapturedValue]
  );
  return /* @__PURE__ */ jsx16(
    Box11,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        position: "relative",
        width: "100%",
        height: "100%",
        minHeight: "450px"
      },
      children: isProcessingCapturedValue ? /* @__PURE__ */ jsxs11(
        Box11,
        {
          sx: {
            position: "absolute",
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            rowGap: 2,
            width: "100%",
            height: "100%",
            border: (theme) => `1px solid ${theme.palette.neutral_50}`
          },
          children: [
            /* @__PURE__ */ jsx16(LinearProgress3, { sx: { width: "65%" } }),
            /* @__PURE__ */ jsx16(
              Typography9,
              {
                variant: "bodyL",
                textAlign: "center",
                sx: { color: (theme) => theme.palette.primary.main },
                children: processingLabel || "Processing captured address..."
              }
            )
          ]
        }
      ) : /* @__PURE__ */ jsxs11(Fragment6, { children: [
        allowManualInput && /* @__PURE__ */ jsxs11(Fragment6, { children: [
          /* @__PURE__ */ jsx16(
            TextField,
            {
              fullWidth: true,
              label: textFieldLabel,
              disabled: isProcessingCapturedValue,
              value: capturedInputValue,
              sx: {
                ".MuiInputBase-root": { height: "65px" },
                ".MuiFormControl-root": { height: "65px" }
              },
              onChange: (e) => {
                var _a;
                return setCapturedInputValue(((_a = e == null ? void 0 : e.target) == null ? void 0 : _a.value) || void 0);
              }
            }
          ),
          /* @__PURE__ */ jsx16(
            Button8,
            {
              color: "primary",
              variant: "outlined",
              sx: { p: 3 },
              onClick: () => {
                handleSubmittedValue(capturedInputValue, "INPUT");
              },
              children: confirmLabel
            }
          )
        ] }),
        Boolean(error) && /* @__PURE__ */ jsx16(
          Alert2,
          {
            icon: false,
            severity: "warning",
            sx: { p: 2, ".MuiAlert-message": { width: "100%" } },
            children: /* @__PURE__ */ jsx16(
              Typography9,
              {
                variant: "bodyL",
                sx: { display: "flex", justifyContent: "center", textAlign: "center" },
                children: error
              }
            )
          }
        ),
        allowManualInput && /* @__PURE__ */ jsxs11(
          Box11,
          {
            sx: {
              height: "50px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: 2
            },
            children: [
              /* @__PURE__ */ jsx16(Divider4, { sx: { flexGrow: 1 } }),
              /* @__PURE__ */ jsx16(Typography9, { variant: "bodyL", sx: { color: (theme) => theme.palette.neutral_50 }, children: "OR" }),
              /* @__PURE__ */ jsx16(Divider4, { sx: { flexGrow: 1 } })
            ]
          }
        ),
        useScanner && !isProcessingCapturedValue ? /* @__PURE__ */ jsxs11(
          Box11,
          {
            sx: { display: "flex", flexDirection: "column", rowGap: 2 },
            onClick: () => {
              setUseScanner(false);
            },
            children: [
              scanning && /* @__PURE__ */ jsxs11(
                Box11,
                {
                  sx: {
                    flexBasis: "350px",
                    border: (theme) => `1px solid ${theme.palette.neutral_300}`,
                    backgroundColor: (theme) => theme.palette.neutral_50,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    rowGap: 2,
                    p: 2
                  },
                  children: [
                    /* @__PURE__ */ jsx16(LinearProgress3, { sx: { width: "80%" } }),
                    /* @__PURE__ */ jsx16(Typography9, { textAlign: "center", variant: "bodyL", children: "Scanning... (Tap To Cancel)" })
                  ]
                }
              ),
              /* @__PURE__ */ jsx16(Box11, { children: /* @__PURE__ */ jsxs11(Box11, { sx: { flexBasis: "350px", position: "relative" }, children: [
                !scanning && /* @__PURE__ */ jsxs11(
                  Box11,
                  {
                    sx: {
                      position: "absolute",
                      border: (theme) => `1px solid ${theme.palette.neutral_300}`,
                      backgroundColor: (theme) => theme.palette.neutral_50,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      zIndex: 99,
                      rowGap: 2,
                      p: 2
                    },
                    children: [
                      /* @__PURE__ */ jsx16(LinearProgress3, { sx: { width: "100%" } }),
                      /* @__PURE__ */ jsx16(Typography9, { textAlign: "center", variant: "bodyL", children: "Preparing To Scan... (Tap To Cancel)" })
                    ]
                  }
                ),
                /* @__PURE__ */ jsx16(
                  QrReader,
                  {
                    onResult: (result) => {
                      if (!scanning) {
                        setScanning(true);
                      }
                      if (result) {
                        handleSubmittedValue(result == null ? void 0 : result.getText(), "SCANNER");
                      }
                    },
                    videoStyle: {
                      width: "350px",
                      height: "auto",
                      zIndex: 100
                    },
                    constraints: {
                      facingMode: "environment"
                    }
                  }
                )
              ] }) })
            ]
          }
        ) : /* @__PURE__ */ jsxs11(
          Box11,
          {
            sx: {
              flexBasis: "350px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              rowGap: 2,
              border: (theme) => `1px solid ${theme.palette.neutral_300}`,
              backgroundColor: (theme) => theme.palette.neutral_50
            },
            onClick: () => {
              setUseScanner(true);
            },
            children: [
              /* @__PURE__ */ jsx16(TouchAppIcon, { sx: { fontSize: 55, color: (theme) => theme.palette.secondary.main } }),
              /* @__PURE__ */ jsx16(
                Typography9,
                {
                  textAlign: "center",
                  variant: "bodyL",
                  sx: { display: "flex", justifyContent: "center" },
                  children: scanLabel || "Tap to start scanning"
                }
              )
            ]
          }
        )
      ] })
    }
  );
};

// src/MobileCheckWalletAddressPresence.tsx
import { useCallback as useCallback5, useMemo as useMemo4, useState as useState7 } from "react";
import { Box as Box12 } from "@mui/material";
import { Fragment as Fragment7, jsx as jsx17, jsxs as jsxs12 } from "react/jsx-runtime";
var MobileCheckWalletAddressPresence = ({
  checkPresence,
  checkingPresence,
  hasPresence,
  resetHasPresence,
  notifCtxt
}) => {
  const [addressToCheck, setAddressToCheck] = useState7(void 0);
  const resetComponent = useCallback5(() => {
    setAddressToCheck(void 0);
    resetHasPresence();
  }, [resetHasPresence]);
  const handleCapturedRecipientChange = useCallback5(
    (captured) => {
      if (!captured) {
        return;
      }
      checkPresence(captured);
    },
    [checkPresence]
  );
  const supportedRecipientTypes = useMemo4(() => {
    return ["ETHEREUM_ADDRESS"];
  }, []);
  return /* @__PURE__ */ jsxs12(Fragment7, { children: [
    /* @__PURE__ */ jsx17(
      Box12,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        },
        children: /* @__PURE__ */ jsx17(
          MobileCaptureRecipient,
          {
            confirmLabel: "Confirm",
            allowManualInput: false,
            processingLabel: "Checking presence...",
            scanLabel: "Tap to start scanning a QR code containing an Ethereum address",
            scanRecipientTypes: supportedRecipientTypes,
            manualRecipientTypes: supportedRecipientTypes,
            handleCapturedValue: handleCapturedRecipientChange,
            isProcessingCapturedValue: Boolean(addressToCheck) && checkingPresence
          }
        )
      }
    ),
    (notifCtxt == null ? void 0 : notifCtxt.title) && /* @__PURE__ */ jsx17(
      MobileNotificationModal,
      __spreadValues({
        onClose: resetComponent
      }, notifCtxt)
    )
  ] });
};

// src/RouteLoaderPage.tsx
import { Box as Box13, LinearProgress as LinearProgress4 } from "@mui/material";
import { jsx as jsx18 } from "react/jsx-runtime";
function RouteLoaderPage() {
  return /* @__PURE__ */ jsx18(
    Box13,
    {
      sx: {
        width: "100%",
        height: "100%",
        minHeight: "750px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: 3,
        svg: {
          visibility: "visible"
        }
      },
      children: /* @__PURE__ */ jsx18(
        LinearProgress4,
        {
          sx: {
            width: "225px"
          }
        }
      )
    }
  );
}

// src/NetworkSelect.tsx
import { Chip, FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useFormikContext } from "formik";

// src/CommonInputLabelStack.tsx
import { Box as Box14, InputLabel, Stack as Stack2, Tooltip as Tooltip2, Typography as Typography10, useTheme as useTheme6 } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import kebabCase from "lodash/kebabCase";
import LockIcon from "@mui/icons-material/Lock";
import { Fragment as Fragment8, jsx as jsx19, jsxs as jsxs13 } from "react/jsx-runtime";
var CommonInputLabelStack = ({
  inputReadOnly,
  label,
  labelId,
  isOptional,
  helpDescription,
  forCtrl = "textfield"
}) => {
  const theme = useTheme6();
  return /* @__PURE__ */ jsxs13(
    Stack2,
    {
      direction: "row",
      spacing: 1,
      sx: {
        lineHeight: "inherit",
        ".MuiSvgIcon-root": {
          color: theme.palette.neutral_400
        },
        mb: forCtrl === "select" || forCtrl === "autocomplete" ? "16px" : forCtrl === "switch" ? "-8px" : "inherit"
      },
      children: [
        /* @__PURE__ */ jsxs13(Stack2, { direction: "row", children: [
          forCtrl === "textfield" && /* @__PURE__ */ jsxs13(Fragment8, { children: [
            inputReadOnly && /* @__PURE__ */ jsx19(Box14, { title: "This field is not editable", sx: { mr: "4px" }, children: /* @__PURE__ */ jsx19(LockIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx19(Typography10, { variant: "inherit", id: labelId != null ? labelId : `${kebabCase(label)}-label`, children: label })
          ] }),
          forCtrl !== "textfield" && /** For use with custom controls - the mui label transform will leave too much space below it, so fix with margin */
          /* @__PURE__ */ jsx19(InputLabel, { sx: { mt: forCtrl === "custom" ? "16px" : "inherit" }, children: /* @__PURE__ */ jsxs13(Stack2, { direction: "row", sx: { columnGap: "8px" }, children: [
            inputReadOnly && /* @__PURE__ */ jsx19(Box14, { title: "This field is not editable", children: /* @__PURE__ */ jsx19(LockIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx19(Typography10, { variant: "inherit", id: labelId != null ? labelId : `${kebabCase(label)}-label`, children: label }),
            isOptional && /* @__PURE__ */ jsx19(Typography10, { variant: "body2", children: "(Optional)" }),
            helpDescription && /* @__PURE__ */ jsx19(Tooltip2, { title: helpDescription, placement: "right", children: /* @__PURE__ */ jsx19(Box14, { children: /* @__PURE__ */ jsx19(HelpOutlineIcon, { fontSize: "small" }) }) })
          ] }) }),
          isOptional && forCtrl === "textfield" && /* @__PURE__ */ jsx19(
            Typography10,
            {
              variant: "body2",
              sx: {
                ml: "4px"
              },
              children: "(Optional)"
            }
          )
        ] }),
        helpDescription && forCtrl === "textfield" && /* @__PURE__ */ jsx19(Tooltip2, { title: helpDescription, placement: "right", children: /* @__PURE__ */ jsx19(Box14, { children: /* @__PURE__ */ jsx19(HelpOutlineIcon, { fontSize: "small" }) }) })
      ]
    }
  );
};
var CommonInputLabelStack_default = CommonInputLabelStack;

// src/NetworkSelect.tsx
import { Fragment as Fragment9, jsx as jsx20, jsxs as jsxs14 } from "react/jsx-runtime";
var NetworkSelect = ({
  formikRef,
  formKey,
  networks,
  isDataPending,
  readOnly,
  label = "Network",
  requireTouchedForError = true
}) => {
  var _a, _b;
  const formik = (_a = useFormikContext()) != null ? _a : formikRef;
  const selectId = `${formKey}-select`;
  const labelId = `${selectId}-label`;
  return /* @__PURE__ */ jsxs14(FormControl, { fullWidth: true, error: formik.touched[formKey] && Boolean(formik.errors[formKey]), children: [
    /* @__PURE__ */ jsx20(
      CommonInputLabelStack_default,
      {
        label,
        labelId,
        helpDescription: "Choose the network for deploying your collectible.",
        forCtrl: "select",
        inputReadOnly: readOnly
      }
    ),
    /* @__PURE__ */ jsx20(
      Select,
      {
        "data-testid": "network-select",
        labelId,
        id: selectId,
        name: formKey,
        value: isDataPending || networks.length === 0 ? 0 : (_b = formik.values[formKey]) != null ? _b : "",
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        disabled: formik.isSubmitting || isDataPending || networks.length === 0,
        readOnly,
        error: (Boolean(formik.touched[formKey]) || !requireTouchedForError) && Boolean(formik.errors[formKey]),
        children: (isDataPending || networks.length === 0 ? [{ id: 0, name: isDataPending ? "Loading" : "No Networks Found", testnet: false }] : networks).map((network) => /* @__PURE__ */ jsx20(MenuItem, { value: network.id, children: network.id === 0 ? /* @__PURE__ */ jsx20(Fragment9, { children: snakeToPascalCase(network.name) }) : /* @__PURE__ */ jsxs14(Fragment9, { children: [
          /* @__PURE__ */ jsx20(
            Chip,
            {
              sx: { mr: 1, minWidth: "65px" },
              size: "small",
              label: network.testnet ? "Testnet" : "Mainnet",
              color: network.testnet ? "secondary" : "primary",
              variant: "filled"
            }
          ),
          snakeToPascalCase(network.name)
        ] }) }, network.id))
      }
    ),
    (Boolean(formik.touched[formKey]) || !requireTouchedForError) && Boolean(formik.errors[formKey]) && /* @__PURE__ */ jsx20(FormHelperText, { error: true, children: formik.errors[formKey] })
  ] });
};

// src/file-upload/index.tsx
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import { Box as Box17, styled as styled3 } from "@mui/material";
import { useState as useState8, useCallback as useCallback6, useEffect as useEffect6, useRef as useRef2 } from "react";

// src/ColoredBorderLoader.tsx
import Box15 from "@mui/material/Box";
import { styled as styled2 } from "@mui/material/styles";
var ColoredBorderLoader = styled2(Box15)`
  --border-size: 3px;
  --border-angle: 0turn;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: rgba(217, 251, 255, 1);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  border-radius: 6px;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
   to {
     --border-angle: 1turn;
      }
    }
  }

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
`;

// src/file-upload/helpers.tsx
import { Box as Box16, useTheme as useTheme7 } from "@mui/material";
import { jsx as jsx21, jsxs as jsxs15 } from "react/jsx-runtime";
var getCommonStyledFilePondIdleLabel = (titleLabel, titleLabelColor, titleLabelFontSize, secondaryLabel, secondaryLabelColor, secondaryLabelFontSize, maxFileSizeLabel, maxFileSizeLabelColor, maxFileSizeLabelFontSize, captionLabel, captionLabelColor, captionLabelFontSize) => {
  return `
     <div class="filepond--label-action" style="display:grid;justify-content:center;justify-items:center;text-decoration:none !important;z-index:-1;position:relative">
          	<span style="color:${titleLabelColor};font-size:${titleLabelFontSize}">
              <span>${titleLabel}</span>
            </span>
            <span style="color:${secondaryLabelColor};font-size:${secondaryLabelFontSize}">
              ${secondaryLabel}
            </span>
            ${captionLabel ? `<span style="margin-top:8px;font-size:${captionLabelFontSize};color:${captionLabelColor}">
              ${captionLabel}
            </span>` : ``}
            <span style="margin-top:8px;font-size:${maxFileSizeLabelFontSize};color:${maxFileSizeLabelColor}">
              ${maxFileSizeLabel}
            </span>
          </div>
    `;
};
var CommonFilePondContainer = ({
  height,
  width,
  disabled,
  error,
  hasInitialValue,
  children,
  testId
}) => {
  const theme = useTheme7();
  return /* @__PURE__ */ jsxs15(
    Box16,
    {
      "data-testid": testId,
      sx: {
        columnGap: "16px",
        height,
        width,
        opacity: 1,
        position: "relative",
        borderRadius: "6px",
        border: (theme2) => `1px solid ${theme2.palette.neutral_200}`,
        "&:hover": {
          cursor: disabled ? "not-allowed" : "auto",
          backgroundColor: (theme2) => theme2.palette.secondary_50
        },
        ".filepond--file-info": {
          color: (theme2) => theme2.palette.neutral_900
        },
        ".filepond--drop-label > label": {
          cursor: disabled ? "not-allowed" : "pointer"
        },
        ".filepond--wrapper": {
          height: "100%",
          backgroundColor: "transparent",
          borderRadius: "6px",
          border: error ? `1px solid ${theme.palette.error.main}` : "none"
        },
        ".filepond--item": {
          height: "100% !important",
          video: {
            height: "100%"
          }
        },
        ".filepond--panel-root": { backgroundColor: "transparent" },
        ".filepond--image-preview-wrapper": {
          borderRadius: "6px !important"
        },
        // No overlays for idle & success
        ".filepond--image-preview-overlay-success": {
          color: "transparent"
        },
        ".filepond--image-preview-overlay-idle": {
          display: "none"
        },
        ".filepond--image-preview, .filepond--media-preview-wrapper": {
          backgroundColor: "transparent"
        },
        ".filepond--media-preview-wrapper:before": {
          display: "none"
        }
      },
      children: [
        disabled && /* @__PURE__ */ jsx21(Box16, { sx: { height: "100%", width: "100%", zIndex: 100, position: "absolute" } }),
        children
      ]
    }
  );
};

// src/file-upload/index.tsx
import { jsx as jsx22, jsxs as jsxs16 } from "react/jsx-runtime";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginMediaPreview
);
var StyledFilePond = styled3(FilePond)((props) => ({
  fontFamily: props.theme.typography.fontFamily,
  fontSize: props.theme.typography.body1.fontSize,
  label: {
    color: "white"
  },
  ".filepond--panel-root": {
    background: "transparent"
  },
  ".filepond--file-status": {
    color: props.theme.palette.text.primary
  },
  ".filepond--file-action-button": {
    backgroundColor: props.theme.palette.secondary.main,
    color: props.theme.palette.secondary.contrastText
  }
}));
var BASIC_FILE_UPLOAD_DROPZONE_DATA_ATTRIBUTE = "data-single-file-server-upload-container";
var BasicFileUploadDropContainer = (props) => {
  return /* @__PURE__ */ jsx22(
    "div",
    __spreadProps(__spreadValues({
      style: { height: "100%", width: "100%" }
    }, { [BASIC_FILE_UPLOAD_DROPZONE_DATA_ATTRIBUTE]: true }), {
      children: props.children
    })
  );
};
var BasicFileUpload = (_a) => {
  var _b = _a, { innerRef, disabled } = _b, rest = __objRest(_b, ["innerRef", "disabled"]);
  var _a2, _b2;
  const containerRef = useRef2(null);
  const [isDroppingFile, setIsDroppingFile] = useState8(false);
  const [isHoveringOver, setIsHoveringOver] = useState8(false);
  const handleFileBeingDropped = useCallback6(() => {
    setIsDroppingFile(true);
  }, []);
  const handleFileStoppedBeingDropped = useCallback6(() => {
    setIsDroppingFile(false);
  }, []);
  useEffect6(() => {
    var _a3;
    if (!containerRef.current)
      return;
    const dropzoneElement = (_a3 = containerRef.current.closest(`div[${BASIC_FILE_UPLOAD_DROPZONE_DATA_ATTRIBUTE}="true"]`)) != null ? _a3 : containerRef.current;
    if (dropzoneElement) {
      dropzoneElement.addEventListener("dragenter", handleFileBeingDropped, false);
      dropzoneElement.addEventListener("dragover", handleFileBeingDropped, false);
      dropzoneElement.addEventListener("drop", handleFileStoppedBeingDropped, false);
      dropzoneElement.addEventListener("dragleave", handleFileStoppedBeingDropped, false);
    }
    return () => {
      if (dropzoneElement) {
        dropzoneElement.removeEventListener("dragenter", handleFileBeingDropped, false);
        dropzoneElement.removeEventListener("dragover", handleFileBeingDropped, false);
        dropzoneElement.removeEventListener("drop", handleFileStoppedBeingDropped, false);
        dropzoneElement.removeEventListener("dragleave", handleFileStoppedBeingDropped, false);
      }
    };
  }, [handleFileBeingDropped, handleFileStoppedBeingDropped]);
  return /* @__PURE__ */ jsxs16(
    Box17,
    {
      ref: containerRef,
      onMouseEnter: () => setIsHoveringOver(true),
      onMouseLeave: () => setIsHoveringOver(false),
      sx: { position: "relative", height: "100%", width: "100%" },
      children: [
        !disabled && (isDroppingFile || isHoveringOver && (!innerRef || !(innerRef == null ? void 0 : innerRef.current) || !((innerRef == null ? void 0 : innerRef.current) && "getFiles" in (innerRef == null ? void 0 : innerRef.current)) || !((_b2 = (_a2 = innerRef == null ? void 0 : innerRef.current) == null ? void 0 : _a2.getFiles()) == null ? void 0 : _b2.length))) && /* @__PURE__ */ jsx22(ColoredBorderLoader, {}),
        /* @__PURE__ */ jsx22(StyledFilePond, __spreadValues({ ref: innerRef != null ? innerRef : null, credits: false }, rest))
      ]
    }
  );
};

// src/file-upload/SingleFileServerUpload.tsx
import { useTheme as useTheme8 } from "@mui/material";
import { useEffect as useEffect7, useRef as useRef3, useState as useState9 } from "react";
import { FileStatus } from "react-filepond";
import { jsx as jsx23 } from "react/jsx-runtime";
var SingleFileServerUpload = ({
  disabled,
  width,
  height,
  acceptedFileTypes,
  titleLabel = "Click to upload file",
  subtitleLabel = "or drag and drop",
  captionLabel,
  uploadUri,
  uploadResponseUrlKey = "link",
  uploadAuthHeader,
  onFileUploadedUrlChange,
  onUploadingChange,
  initialValue,
  maxFileSize,
  error,
  testId
}) => {
  var _a, _b, _c, _d;
  const theme = useTheme8();
  const filepondRef = useRef3(null);
  const [file, setFile] = useState9(null);
  const [respectedInitialValue, setRespectedInitialValue] = useState9(false);
  useEffect7(() => {
    if (initialValue && !file && !respectedInitialValue && (filepondRef == null ? void 0 : filepondRef.current)) {
      setRespectedInitialValue(true);
      filepondRef.current.addFile(initialValue, {
        type: typeof initialValue === "string" ? "local" : "input"
      });
    }
  }, [file, initialValue, respectedInitialValue]);
  return /* @__PURE__ */ jsx23(
    CommonFilePondContainer,
    {
      height,
      width,
      disabled,
      hasInitialValue: Boolean(initialValue),
      error,
      testId,
      children: /* @__PURE__ */ jsx23(
        BasicFileUpload,
        {
          innerRef: filepondRef,
          disabled,
          server: {
            // Load in existing file by an url
            load: (source, load, _error, _progress, _abort, _headers) => {
              if (onUploadingChange)
                onUploadingChange(true);
              if (file == null ? void 0 : file.original) {
                load(file == null ? void 0 : file.original);
                if (onUploadingChange)
                  onUploadingChange(false);
                return;
              }
              const request = new XMLHttpRequest();
              request.open("GET", source);
              request.responseType = "blob";
              request.onreadystatechange = () => {
                if (request.readyState === 4) {
                  load(request.response);
                  if (onUploadingChange)
                    onUploadingChange(false);
                }
              };
              request.send();
            },
            // Process uploaded file - we care about the URL that the server returns for the uploaded file
            process: (_fieldName, file2, _metadata, load, error2, progress, abort, _transfer, _options) => {
              if (onUploadingChange)
                onUploadingChange(true);
              const formData = new FormData();
              formData.append("file", file2, file2.name);
              const request = new XMLHttpRequest();
              request.withCredentials = true;
              request.open("POST", uploadUri);
              request.setRequestHeader("Authorization", uploadAuthHeader);
              request.upload.onprogress = (e) => {
                progress(false, 0, 0);
              };
              request.onload = function() {
                if (request.status >= 200 && request.status < 300) {
                  load(request.responseText);
                  const parsed = JSON.parse(request.response);
                  setFile({
                    url: parsed[uploadResponseUrlKey],
                    original: file2
                  });
                  onFileUploadedUrlChange(parsed[uploadResponseUrlKey], file2);
                  if (onUploadingChange)
                    onUploadingChange(false);
                } else {
                  error2("Failed to upload file");
                  setFile(null);
                  if (onUploadingChange)
                    onUploadingChange(false);
                  onFileUploadedUrlChange(void 0);
                }
              };
              request.send(formData);
              return {
                abort: () => {
                  setFile(null);
                  onFileUploadedUrlChange(void 0);
                  if (onUploadingChange)
                    onUploadingChange(false);
                  request.abort();
                  abort();
                }
              };
            }
          },
          beforeRemoveFile: () => {
            setFile(null);
            if (onUploadingChange)
              onUploadingChange(false);
            onFileUploadedUrlChange(void 0);
            return true;
          },
          onaddfile: (_, file2) => {
            if (onUploadingChange) {
              const filesUploading = file2.status === FileStatus.PROCESSING || file2.status === FileStatus.LOADING || file2.status === FileStatus.PROCESSING_QUEUED;
              onUploadingChange(filesUploading);
            }
            return true;
          },
          allowFileSizeValidation: true,
          maxFileSize,
          allowMultiple: false,
          stylePanelLayout: "integrated",
          labelIdle: getCommonStyledFilePondIdleLabel(
            // Title
            titleLabel,
            theme.palette.primary.main,
            (_a = theme.typography.body1.fontSize) == null ? void 0 : _a.toString(),
            // Subtitle
            subtitleLabel,
            theme.palette.primary.main,
            (_b = theme.typography.body1.fontSize) == null ? void 0 : _b.toString(),
            // Max File Size
            `Max file size is ${maxFileSize}`,
            theme.palette.primary.main,
            (_c = theme.typography.caption.fontSize) == null ? void 0 : _c.toString(),
            // Optional Caption
            captionLabel,
            theme.palette.neutral_900,
            (_d = theme.typography.body2.fontSize) == null ? void 0 : _d.toString()
          ),
          acceptedFileTypes
        }
      )
    }
  );
};

// src/ClipboardContent.tsx
import Box18 from "@mui/material/Box";
import Tooltip3 from "@mui/material/Tooltip";
import { jsx as jsx24 } from "react/jsx-runtime";
var ClipboardContent = ({
  tooltipContext,
  clipboardValue,
  children
}) => {
  const copyToClipboard = useCopyToClipboard();
  return /* @__PURE__ */ jsx24(Tooltip3, { followCursor: true, title: `Click to copy ${tooltipContext} to the clipboard`, children: /* @__PURE__ */ jsx24(
    Box18,
    {
      sx: {
        display: "flex",
        alignItems: "center",
        "&:hover, input:hover": {
          fontWeight: "bold",
          cursor: "copy"
        }
      },
      onClick: () => copyToClipboard(clipboardValue),
      children: /* @__PURE__ */ jsx24(Box18, { sx: { width: "100%" }, children })
    }
  ) });
};
var ClipboardContent_default = ClipboardContent;

// src/EthereumAddress.tsx
import { useCallback as useCallback7, useMemo as useMemo5 } from "react";
import Tooltip4 from "@mui/material/Tooltip";
import Box19 from "@mui/material/Box";
import Typography11 from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon2 from "@mui/icons-material/ContentCopy";
import { Stack as Stack3 } from "@mui/material";
import { Fragment as Fragment10, jsx as jsx25, jsxs as jsxs17 } from "react/jsx-runtime";
var BlockchainExplorerTooltip = ({
  title,
  children
}) => {
  return /* @__PURE__ */ jsx25(
    Tooltip4,
    {
      componentsProps: {
        tooltip: {
          sx: {
            whiteSpace: "nowrap",
            fontSize: 12,
            maxWidth: "fit-content"
          }
        }
      },
      title: title != null ? title : "Click to navigate to blockchain explorer",
      children
    }
  );
};
var BlockchainExplorerIcon = ({ openExplorer, sx }) => {
  return /* @__PURE__ */ jsx25(BlockchainExplorerTooltip, { children: /* @__PURE__ */ jsx25(
    OpenInNewIcon,
    {
      className: "eth-address-link-icon",
      sx: __spreadValues({
        cursor: "pointer",
        color: (theme) => theme.palette.secondary_700
      }, sx || {}),
      fontSize: "small",
      onClick: openExplorer
    }
  ) });
};
var EthereumAddress = ({
  value = "",
  networkId = 1,
  shortFormat = true,
  shortFormatLengthOnLeftSide = 4,
  shortFormatLengthOnRightSide = 4,
  showExplorerIcon = false,
  showCopyIcon = false,
  explorerIconPosition = "start",
  openExplorerOnClick = showExplorerIcon,
  type = "address",
  customFormat
}) => {
  const displayedAddress = useMemo5(() => {
    if (!shortFormat || !value)
      return value;
    if (customFormat)
      return customFormat(value);
    return `${value.slice(0, 2 + shortFormatLengthOnLeftSide)}...${value.slice(
      0 - shortFormatLengthOnRightSide
    )}`;
  }, [value, shortFormat, shortFormatLengthOnLeftSide, shortFormatLengthOnRightSide]);
  const explorerUrl = useMemo5(() => {
    return getExplorerUrl(networkId, value, type);
  }, [networkId, type, value]);
  const openExplorer = useCallback7(() => {
    if (explorerUrl) {
      window.open(explorerUrl);
    }
  }, [explorerUrl]);
  if (!displayedAddress && !showExplorerIcon && !explorerUrl)
    return /* @__PURE__ */ jsx25(Fragment10, {});
  return /* @__PURE__ */ jsxs17(Box19, { sx: { display: "flex", columnGap: 0.5, alignItems: "center" }, children: [
    openExplorerOnClick ? /* @__PURE__ */ jsx25(BlockchainExplorerTooltip, { title: value, children: /* @__PURE__ */ jsx25(
      Typography11,
      {
        onClick: openExplorer,
        sx: { cursor: "pointer", color: (theme) => theme.palette.secondary_700 },
        children: displayedAddress
      }
    ) }) : /* @__PURE__ */ jsx25(ClipboardContent_default, { tooltipContext: "this Ethereum address", clipboardValue: value, children: /* @__PURE__ */ jsxs17(Stack3, { direction: "row", sx: { alignItems: "center", columnGap: 0.5 }, children: [
      /* @__PURE__ */ jsx25(
        Typography11,
        {
          sx: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flexShrink: 1,
            display: "flex",
            alignItems: "center",
            color: (theme) => theme.palette.secondary_700
          },
          children: displayedAddress
        }
      ),
      showCopyIcon && /* @__PURE__ */ jsx25(
        ContentCopyIcon2,
        {
          className: "eth-address-link-icon",
          fontSize: "small",
          sx: {
            cursor: "pointer",
            color: (theme) => theme.palette.secondary_700,
            fill: (theme) => theme.palette.secondary_700
          }
        }
      )
    ] }) }),
    showExplorerIcon && explorerUrl && /* @__PURE__ */ jsx25(
      BlockchainExplorerIcon,
      {
        openExplorer,
        sx: {
          order: displayedAddress ? explorerIconPosition === "end" ? 1 : 0 : 0,
          mr: displayedAddress ? explorerIconPosition === "start" ? 1 : 0 : 0,
          ml: displayedAddress ? explorerIconPosition === "end" ? 1 : 0 : 0
        }
      }
    )
  ] });
};
var EthereumAddress_default = EthereumAddress;

// src/ChipList.tsx
import { Stack as Stack4, Chip as Chip2, useTheme as useTheme9, FormHelperText as FormHelperText2, Tooltip as Tooltip5, Alert as Alert3 } from "@mui/material";
import { jsx as jsx26, jsxs as jsxs18 } from "react/jsx-runtime";
var ChipList = ({
  elements,
  labelFormatter,
  minHeight = "40px",
  maxHeight = "150px",
  onDelete,
  helperText,
  maxToRender = 100
}) => {
  const theme = useTheme9();
  return /* @__PURE__ */ jsxs18(Stack4, { children: [
    /* @__PURE__ */ jsx26(
      Stack4,
      {
        sx: {
          backgroundColor: theme.palette.neutral_50,
          padding: "8px 12px",
          borderRadius: "6px",
          width: "100%",
          minHeight,
          maxHeight,
          overflowY: "auto",
          flexWrap: "wrap",
          rowGap: "12px",
          columnGap: "12px"
        },
        direction: "row",
        children: elements.filter((_, index) => index + 1 <= maxToRender).map((element, index) => {
          var _a, _b;
          const chipLabel = labelFormatter(element);
          return /* @__PURE__ */ jsx26(Tooltip5, { title: chipLabel, children: element.canDelete !== false && onDelete ? /* @__PURE__ */ jsx26(
            Chip2,
            {
              sx: { maxWidth: "300px" },
              color: (_a = element.color) != null ? _a : "secondary",
              size: "small",
              variant: "filled",
              onDelete: () => {
                onDelete(element);
              },
              label: chipLabel
            }
          ) : /* @__PURE__ */ jsx26(
            Chip2,
            {
              sx: { maxWidth: "300px" },
              color: (_b = element.color) != null ? _b : "secondary",
              size: "small",
              variant: "filled",
              label: chipLabel
            }
          ) }, index);
        })
      }
    ),
    helperText && /* @__PURE__ */ jsx26(FormHelperText2, { children: helperText }),
    elements.length > maxToRender && /* @__PURE__ */ jsxs18(Alert3, { severity: "info", sx: { mt: 1 }, children: [
      "For performance reasons, only showing: ",
      maxToRender,
      "/",
      elements.length
    ] })
  ] });
};

// src/DefineViaCsvOrManualDialog.tsx
import CloseIcon from "@mui/icons-material/Close";
import {
  Box as Box20,
  Button as Button9,
  CircularProgress as CircularProgress2,
  Dialog,
  DialogActions,
  Divider as Divider5,
  IconButton as IconButton2,
  InputLabel as InputLabel2,
  Stack as Stack5,
  Tab as Tab3,
  Tabs as Tabs3,
  useMediaQuery as useMediaQuery2,
  useTheme as useTheme10
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Typography12 from "@mui/material/Typography/Typography";
import AddIcon from "@mui/icons-material/Add";
import { useCallback as useCallback8, useEffect as useEffect8, useMemo as useMemo6, useState as useState10 } from "react";
import { usePluginRegistry as usePluginRegistry8 } from "plugin-system";
import sortBy from "lodash/sortBy";
import { Fragment as Fragment11, jsx as jsx27, jsxs as jsxs19 } from "react/jsx-runtime";
var TabType = /* @__PURE__ */ ((TabType2) => {
  TabType2[TabType2["IMPORT"] = 0] = "IMPORT";
  TabType2[TabType2["MANUAL"] = 1] = "MANUAL";
  TabType2[TabType2["CUSTOM"] = 2] = "CUSTOM";
  return TabType2;
})(TabType || {});
function TabPanel2(props) {
  const _a = props, { children, index, activeIndex } = _a, other = __objRest(_a, ["children", "index", "activeIndex"]);
  return /* @__PURE__ */ jsx27(
    Stack5,
    __spreadProps(__spreadValues({
      role: "tabpanel",
      hidden: activeIndex !== index,
      id: `define-via-csv-or-manual-tabpanel-${index}`,
      "aria-labelledby": `define-via-csv-or-manual-${index}`,
      spacing: 2,
      sx: {
        display: activeIndex !== index ? "none" : "flex",
        height: "100%",
        width: "100%"
      }
    }, other), {
      children: activeIndex === index && children
    })
  );
}
function a11yProps2(index) {
  return {
    id: `define-via-csv-or-manual-tab-${index}`,
    "aria-controls": `define-via-csv-or-manual-tabpanel-${index}`
  };
}
function DefineViaCsvOrManualDialog({
  open,
  handleClose,
  defaultEntryFactory,
  dialogTitle,
  dialogSubtitle,
  dialogMinWidth = 650,
  confirmLabel,
  sortElements,
  elementLabelFormatter,
  CsvUpload,
  ManualDefine,
  CustomDefine,
  customDefineOptions,
  definitionContextSingular,
  validateManuallyDefinedEntry
}) {
  const theme = useTheme10();
  const pluginRegistry = usePluginRegistry8();
  const [validatingManualEntry, setValidatingManualEntry] = useState10(false);
  const [defaultEntry] = useState10(defaultEntryFactory());
  const [manualEntry, setManualEntry] = useState10(defaultEntry);
  const [addedEntries, setAddedEntries] = useState10([]);
  const [tabIndex, setTabIndex] = useState10(0 /* IMPORT */);
  const fullScreen = useMediaQuery2(theme.breakpoints.down("md"));
  const minWidth = !fullScreen ? dialogMinWidth : void 0;
  const clearState = useCallback8(() => {
    setAddedEntries([]);
    setManualEntry(defaultEntry);
    setTabIndex(0 /* IMPORT */);
  }, [defaultEntry]);
  const handleTabChange = useCallback8(
    (_, newValue) => {
      const handleTabChangeConfirm = () => {
        setAddedEntries([]);
        setManualEntry(defaultEntry);
        setTabIndex(newValue);
      };
      if (addedEntries.length || manualEntry.valid) {
        pluginRegistry.commands.execute({
          id: "portal.confirm-dialog",
          dialogTitle: "Unsaved changes",
          dialogSubtitle: "Are you sure you want to switch tabs? You will lose any changes you made in this tab so far.",
          confirmButtonLabel: "Switch",
          onConfirm: handleTabChangeConfirm
        });
      } else
        handleTabChangeConfirm();
    },
    [defaultEntry, manualEntry, addedEntries, pluginRegistry]
  );
  const handleDialogClose = useCallback8(() => {
    const handleDialogCloseInner = () => {
      handleClose([]);
      clearState();
    };
    if (addedEntries.length) {
      pluginRegistry.commands.execute({
        id: "portal.confirm-dialog",
        dialogTitle: "Discard changes",
        dialogSubtitle: `Are you sure you want to discard the ${addedEntries.length === 1 ? "recipient" : "recipients"} you've started defining?`,
        onConfirm: handleDialogCloseInner
      });
    } else
      handleDialogCloseInner();
  }, [handleClose, addedEntries, clearState, pluginRegistry]);
  const handleConfirmManualEntry = useCallback8(() => __async(this, null, function* () {
    if (tabIndex === 1 /* MANUAL */) {
      manualEntry.valid = true;
      manualEntry.warning = void 0;
      if (validateManuallyDefinedEntry) {
        setValidatingManualEntry(true);
        yield validateManuallyDefinedEntry(manualEntry, addedEntries);
      }
      if (manualEntry.valid) {
        setManualEntry(defaultEntry);
        setAddedEntries((old) => manualEntry.valid ? [...old, manualEntry] : old);
      } else {
        setManualEntry(__spreadValues({}, manualEntry));
      }
      setValidatingManualEntry(false);
    }
  }), [tabIndex, manualEntry, defaultEntry]);
  const handleConfirm = useCallback8(() => {
    if (addedEntries.length > 0) {
      handleClose([...addedEntries]);
      clearState();
    }
  }, [addedEntries, handleClose, clearState]);
  const handleDefineEntryChange = useCallback8(
    (propName, changedValue) => {
      const updatedManualEntry = __spreadProps(__spreadValues({}, manualEntry), {
        [propName]: changedValue
      });
      setManualEntry(updatedManualEntry);
    },
    [manualEntry]
  );
  const handleManualEntryDelete = useCallback8((toDelete) => {
    setAddedEntries((old) => old.filter((oldEntry) => oldEntry !== toDelete));
  }, []);
  useEffect8(() => {
    setManualEntry((old) => {
      return __spreadValues({}, old);
    });
  }, [addedEntries]);
  const tabDatasource = useMemo6(() => {
    const base = [
      {
        label: "Import External",
        value: 0 /* IMPORT */,
        order: 1,
        testId: "import-external-recipients"
      },
      {
        label: "Add Individual",
        value: 1 /* MANUAL */,
        order: 2,
        testId: "add-individual-recipient"
      }
    ];
    if (customDefineOptions && CustomDefine) {
      base.push({
        label: customDefineOptions.tabLabel,
        value: 2 /* CUSTOM */,
        order: customDefineOptions.tabOrder,
        testId: "add-recipients-custom"
      });
    }
    return sortBy(base, (record) => record.order);
  }, [customDefineOptions, CustomDefine]);
  return /* @__PURE__ */ jsxs19(
    Dialog,
    {
      sx: {
        "& .MuiDialog-paper": { minWidth }
      },
      open,
      fullScreen,
      onClose: handleDialogClose,
      children: [
        /* @__PURE__ */ jsx27(DialogTitle, { children: /* @__PURE__ */ jsxs19(Stack5, { spacing: 2, children: [
          /* @__PURE__ */ jsxs19(Box20, { display: "flex", alignItems: "center", children: [
            /* @__PURE__ */ jsx27(Typography12, { fontSize: theme.typography.h3.fontSize, fontWeight: 600, children: dialogTitle }),
            /* @__PURE__ */ jsx27(
              IconButton2,
              {
                "aria-label": "close",
                onClick: handleDialogClose,
                size: "large",
                sx: {
                  position: "absolute",
                  right: 8
                },
                children: /* @__PURE__ */ jsx27(CloseIcon, { fontSize: "small" })
              }
            )
          ] }),
          dialogSubtitle && typeof dialogSubtitle === "string" && /* @__PURE__ */ jsx27(Typography12, { variant: "body1", children: dialogSubtitle }),
          dialogSubtitle && typeof dialogSubtitle !== "string" && /* @__PURE__ */ jsx27(Typography12, { variant: "body1", children: dialogSubtitle(tabIndex) })
        ] }) }),
        /* @__PURE__ */ jsx27(DialogContent, { children: /* @__PURE__ */ jsx27(BasicFileUploadDropContainer, { children: /* @__PURE__ */ jsx27(Stack5, { width: "100%", height: "100%", spacing: 2, children: /* @__PURE__ */ jsxs19(Stack5, { direction: "column", spacing: 4, width: "100%", children: [
          /* @__PURE__ */ jsx27(
            Tabs3,
            {
              sx: { width: "100%" },
              value: tabIndex,
              variant: "fullWidth",
              onChange: handleTabChange,
              "aria-label": "define-via-csv-or-manual-tabs",
              children: tabDatasource.map((tab) => /* @__PURE__ */ jsx27(
                Tab3,
                __spreadValues({
                  "data-testid": tab.testId,
                  label: tab.label
                }, a11yProps2(tab.value)),
                tab.value
              ))
            }
          ),
          /* @__PURE__ */ jsx27(TabPanel2, { activeIndex: tabIndex, index: 0 /* IMPORT */, children: CsvUpload((valid, _) => setAddedEntries(valid)) }),
          /* @__PURE__ */ jsxs19(TabPanel2, { activeIndex: tabIndex, index: 1 /* MANUAL */, children: [
            ManualDefine(manualEntry, handleDefineEntryChange),
            /* @__PURE__ */ jsxs19(Fragment11, { children: [
              /* @__PURE__ */ jsx27(Divider5, {}),
              /* @__PURE__ */ jsxs19(Box20, { sx: { display: "flex", alignItems: "center", columnGap: 2 }, children: [
                /* @__PURE__ */ jsx27(
                  Button9,
                  {
                    variant: "text",
                    "data-testid": "create-draft-recipient",
                    startIcon: /* @__PURE__ */ jsx27(AddIcon, {}),
                    sx: { borderWidth: 1, borderStyle: "solid" },
                    onClick: handleConfirmManualEntry,
                    children: `Create ${definitionContextSingular}`
                  }
                ),
                validatingManualEntry && /* @__PURE__ */ jsxs19(Box20, { sx: { display: "flex", alignItems: "center", columnGap: 1 }, children: [
                  /* @__PURE__ */ jsx27(CircularProgress2, { size: 25 }),
                  /* @__PURE__ */ jsx27(Typography12, { variant: "caption", children: "Validating address..." })
                ] })
              ] })
            ] }),
            /* @__PURE__ */ jsxs19(Stack5, { children: [
              addedEntries.length > 0 ? /* @__PURE__ */ jsx27(InputLabel2, { children: `${addedEntries.length} Created ${definitionContextSingular}${addedEntries.length > 1 ? "s" : ""}` }) : /* @__PURE__ */ jsx27(InputLabel2, { children: `No created ${definitionContextSingular == null ? void 0 : definitionContextSingular.toLowerCase()}s` }),
              /* @__PURE__ */ jsx27(
                ChipList,
                {
                  labelFormatter: elementLabelFormatter,
                  elements: sortElements ? sortElements(addedEntries) : addedEntries,
                  onDelete: handleManualEntryDelete
                }
              )
            ] })
          ] }),
          Boolean(customDefineOptions == null ? void 0 : customDefineOptions.tabLabel) && CustomDefine && /* @__PURE__ */ jsx27(TabPanel2, { activeIndex: tabIndex, index: 2 /* CUSTOM */, children: CustomDefine((customDefined) => setAddedEntries(customDefined)) })
        ] }) }) }) }),
        /* @__PURE__ */ jsxs19(DialogActions, { children: [
          /* @__PURE__ */ jsx27(Button9, { variant: "outlined", onClick: handleDialogClose, children: "Close" }),
          /* @__PURE__ */ jsx27(
            Button9,
            {
              "data-testid": "confirm-save-recipients",
              disabled: !addedEntries.length,
              variant: "contained",
              onClick: handleConfirm,
              children: confirmLabel
            }
          )
        ] })
      ]
    }
  );
}

// src/BasicRichTextEditor.tsx
import { Stack as Stack6, Typography as Typography13, styled as styled4, useTheme as useTheme11 } from "@mui/material";
import ReactQuill, { Quill as Quill3 } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect as useEffect9, useRef as useRef4, useState as useState11 } from "react";

// src/quill-blots/blockquote.ts
import { Quill } from "react-quill";
var Blockquote = Quill.import("formats/blockquote");
var BlockquoteInlineStyle = class extends Blockquote {
  static create() {
    BlockquoteInlineStyle.blotName = "blockquote";
    BlockquoteInlineStyle.tagName = "blockquote";
    const node = super.create();
    node.setAttribute("style", "border-left: 4px solid #ccc; margin: 5px 0; padding-left: 16px;");
    return node;
  }
};
var blockquote_default = BlockquoteInlineStyle;

// src/quill-blots/codeblock.ts
import { Quill as Quill2 } from "react-quill";
var Codeblock = Quill2.import("formats/code-block");
var CodeblockInlineStyle = class extends Codeblock {
  static create() {
    CodeblockInlineStyle.blotName = "pre";
    CodeblockInlineStyle.tagName = "pre";
    const node = super.create();
    node.setAttribute(
      "style",
      "background: rgba(255, 255, 255, 0.1); font-size: 13px; font-family: monospace; padding: 5px 10px; margin: 5px 0;"
    );
    return node;
  }
};
var codeblock_default = CodeblockInlineStyle;

// src/BasicRichTextEditor.tsx
import { jsx as jsx28, jsxs as jsxs20 } from "react/jsx-runtime";
var DEFAULT_BASIC_RICH_TEXT_EDITOR_TOOLBAR_OPTIONS = {
  container: [
    [{ size: ["12px", false, "18px", "24px"] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
    ["link", "clean"]
  ]
};
var StyledReactQuill = styled4(ReactQuill)(
  ({ muiTheme, editorHeight }) => ({
    fontFamily: muiTheme.typography.fontFamily,
    // The editor height is the height of the editor content + the height of the toolbar + margin.
    height: editorHeight ? `${editorHeight + 40 + 4}px` : "100%",
    "& > .ql-container": {
      fontFamily: muiTheme.typography.fontFamily,
      height: editorHeight ? `${editorHeight}px` : "100%",
      maxHeight: editorHeight ? `${editorHeight}px` : "100%",
      overflowY: "auto"
    },
    ".ql-snow .ql-editor pre.ql-syntax": {
      backgroundColor: muiTheme.palette.neutral_50
    },
    ".ql-toolbar.ql-snow": {
      border: "none",
      background: muiTheme.palette.neutral_50,
      borderRadius: "6px",
      marginBottom: "4px"
    },
    ".ql-container.ql-snow": {
      border: "none",
      background: muiTheme.palette.neutral_50,
      borderRadius: "6px"
    },
    a: {
      color: `${muiTheme.palette.primary.main}`,
      "&:visited": {
        color: `${muiTheme.palette.primary.dark}`
      }
    },
    ".ql-tooltip": {
      boxShadow: "1px 1px 3px 1px black",
      border: 0,
      padding: "8px",
      borderRadius: "6px",
      zIndex: 1,
      input: {
        height: "40px !important",
        borderRadius: "6px",
        boxShadow: "none",
        border: "none !important"
      }
    },
    ".ql-picker": {
      ".ql-picker-label": {
        border: 0,
        color: `${muiTheme.palette.primary.main}`
      },
      ".ql-picker-options": {
        backgroundColor: muiTheme.palette.secondary.dark,
        padding: "8px",
        color: muiTheme.palette.secondary.contrastText,
        border: 0
      }
    },
    ".ql-size": {
      '.ql-picker-item[data-value="12px"]::before': {
        content: '"Small"',
        fontSize: "12px !important"
      },
      '.ql-picker-item[data-value="14px"]::before': {
        content: '"Normal"',
        fontSize: "14px !important"
      },
      '.ql-picker-item[data-value="18px"]::before': {
        content: '"Large"',
        fontSize: "18px !important"
      },
      '.ql-picker-item[data-value="24px"]::before': {
        content: '"Huge"',
        fontSize: "24px !important"
      },
      '.ql-picker-label[data-value="12px"]::before': {
        content: '"Small"'
      },
      '.ql-picker-label[data-value="14px"]::before': {
        content: '"Normal"'
      },
      '.ql-picker-label[data-value="18px"]::before': {
        content: '"Large"'
      },
      '.ql-picker-label[data-value="24px"]::before': {
        content: '"Huge"'
      }
    },
    ".ql-stroke": {
      stroke: `${muiTheme.palette.text.primary}`
    },
    ".ql-fill": {
      fill: `${muiTheme.palette.text.primary}`
    },
    ".ql-picker-label, button, .ql-picker-item": {
      color: `${muiTheme.palette.text.primary}`
    },
    ".ql-active, .ql-picker-label:not(:disabled):hover, button:not(:disabled):hover, .ql-picker-item:not(:disabled):hover, .ql-selected": {
      color: `${muiTheme.palette.primary.main} !important`,
      ".ql-stroke": {
        stroke: `${muiTheme.palette.primary.main} !important`
      },
      ".ql-fill": {
        fill: `${muiTheme.palette.primary.main} !important`
      }
    }
  })
);
var BasicRichTextEditor = (_a) => {
  var _b = _a, {
    modules,
    editorHeight,
    placeholder,
    maxCharacters
  } = _b, rest = __objRest(_b, [
    "modules",
    "editorHeight",
    "placeholder",
    "maxCharacters"
  ]);
  const theme = useTheme11();
  const [characterCount, setCharacterCount] = useState11(0);
  const [isOverMaxCharacters, setIsOverMaxCharacters] = useState11(false);
  useEffect9(() => {
    const Size = Quill3.import("attributors/style/size");
    Size.whitelist = ["12px", "14px", "18px", "24px"];
    Quill3.register(Size, void 0, true);
    Quill3.register(Quill3.import("attributors/style/align"), void 0, true);
    Quill3.register(blockquote_default, true);
    Quill3.register(codeblock_default, true);
  }, []);
  const quill = useRef4();
  useEffect9(() => {
    var _a2;
    const editor = (_a2 = quill.current) == null ? void 0 : _a2.getEditor();
    if (placeholder) {
      const delta = editor == null ? void 0 : editor.clipboard.convert(placeholder);
      if (delta) {
        editor == null ? void 0 : editor.setContents(delta, "silent");
      }
    }
    if (maxCharacters && editor) {
      editor == null ? void 0 : editor.on("text-change", () => {
        const length = editor.getLength() - 1;
        setCharacterCount(length);
        setIsOverMaxCharacters(length > maxCharacters);
        if (length > maxCharacters) {
          const text = editor.getText(0, maxCharacters);
          editor.setText(text);
          const range = {
            index: maxCharacters - 1,
            length: 0
          };
          editor.setSelection(range);
        }
      });
    }
  }, [placeholder, maxCharacters]);
  return /* @__PURE__ */ jsxs20(Stack6, { sx: { ".ql-tooltip": { left: "15px !important" } }, children: [
    /* @__PURE__ */ jsx28(
      StyledReactQuill,
      __spreadProps(__spreadValues({
        theme: "snow",
        muiTheme: theme,
        editorHeight
      }, rest), {
        ref: quill,
        modules: modules != null ? modules : { toolbar: DEFAULT_BASIC_RICH_TEXT_EDITOR_TOOLBAR_OPTIONS },
        bounds: "document.body"
      })
    ),
    Boolean(maxCharacters) && /* @__PURE__ */ jsxs20(
      Typography13,
      {
        variant: "body2",
        sx: {
          color: isOverMaxCharacters ? "red" : theme.palette.neutral_50
        },
        children: [
          "Character Count: ",
          characterCount,
          " / ",
          maxCharacters
        ]
      }
    )
  ] });
};

// src/ConfirmDialog.tsx
import {
  Box as Box22,
  Button as Button11,
  CircularProgress as CircularProgress3,
  Dialog as Dialog2,
  DialogActions as DialogActions2,
  DialogContent as DialogContent2,
  DialogTitle as DialogTitle2,
  IconButton as IconButton3,
  Stack as Stack7,
  Typography as Typography14,
  useMediaQuery as useMediaQuery3,
  useTheme as useTheme12
} from "@mui/material";
import CloseIcon2 from "@mui/icons-material/Close";

// src/LoadingButton.tsx
import Button10 from "@mui/material/Button";
import { useCallback as useCallback9, useEffect as useEffect10, useRef as useRef5, useState as useState12 } from "react";
import Box21 from "@mui/material/Box";
import { jsx as jsx29, jsxs as jsxs21 } from "react/jsx-runtime";
var LoadingButton = (_a) => {
  var _b = _a, {
    loading,
    loadingText,
    children,
    delay,
    minDuration
  } = _b, rest = __objRest(_b, [
    "loading",
    "loadingText",
    "children",
    "delay",
    "minDuration"
  ]);
  var _a2;
  const timeoutInstance = useRef5();
  const setTimeoutInstance = useCallback9((timeout) => {
    timeoutInstance.current = timeout;
  }, []);
  const [canShowLoader, setCanShowLoader] = useState12(false);
  const [showLoader, setShowLoader] = useState12(false);
  useEffect10(() => {
    if (loading && !canShowLoader) {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }
      const timeout = setTimeout(() => {
        setCanShowLoader(true);
      }, delay != null ? delay : 0);
      setTimeoutInstance(timeout);
    }
    if (canShowLoader) {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }
      const timeout = setTimeout(() => {
        setCanShowLoader(false);
      }, minDuration != null ? minDuration : 0);
      setTimeoutInstance(timeout);
    }
  }, [loading, canShowLoader]);
  useEffect10(() => {
    if (loading && canShowLoader) {
      setShowLoader(true);
    }
    if (!loading && !canShowLoader) {
      setShowLoader(false);
    }
  }, [canShowLoader, loading]);
  if (showLoader) {
    return /* @__PURE__ */ jsxs21(
      Button10,
      __spreadProps(__spreadValues({}, rest), {
        disabled: true,
        sx: __spreadProps(__spreadValues({}, (_a2 = rest.sx) != null ? _a2 : {}), {
          "&:disabled": { opacity: "1 !important" }
        }),
        children: [
          /* @__PURE__ */ jsx29(PhosphorLoading, { width: "35px", height: "35px", contained: false }),
          loadingText && /* @__PURE__ */ jsx29(Box21, { sx: { ml: 1 }, children: loadingText })
        ]
      })
    );
  }
  if (loading) {
    return /* @__PURE__ */ jsx29(Button10, __spreadValues({}, rest));
  }
  return /* @__PURE__ */ jsx29(Button10, __spreadProps(__spreadValues({}, rest), { children }));
};

// src/ConfirmDialog.tsx
import { jsx as jsx30, jsxs as jsxs22 } from "react/jsx-runtime";
var ConfirmDialog = ({
  children,
  renderChildren,
  open,
  initializing,
  onCancel,
  cancelButtonLabel,
  onConfirm,
  isConfirming,
  confirmButtonLabel,
  dialogTitle,
  dialogSubtitle,
  dialogMinWidth,
  confirmButtonDisabled
}) => {
  const theme = useTheme12();
  const fullScreen = useMediaQuery3(theme.breakpoints.down("md"));
  return /* @__PURE__ */ jsxs22(
    Dialog2,
    {
      sx: {
        "& .MuiDialog-paper": { minWidth: dialogMinWidth }
      },
      open,
      fullScreen,
      onClose: onCancel,
      children: [
        /* @__PURE__ */ jsx30(DialogTitle2, { children: /* @__PURE__ */ jsxs22(Stack7, { spacing: 2, children: [
          /* @__PURE__ */ jsxs22(Box22, { display: "flex", alignItems: "center", children: [
            /* @__PURE__ */ jsx30(
              Typography14,
              {
                fontSize: theme.typography.h3.fontSize,
                lineHeight: theme.typography.h3.lineHeight,
                fontWeight: 600,
                children: dialogTitle
              }
            ),
            /* @__PURE__ */ jsx30(
              IconButton3,
              {
                "aria-label": "close",
                onClick: onCancel,
                sx: {
                  position: "absolute",
                  right: 16,
                  top: 20
                },
                children: /* @__PURE__ */ jsx30(CloseIcon2, {})
              }
            )
          ] }),
          dialogSubtitle && /* @__PURE__ */ jsx30(Typography14, { variant: "body1", sx: { fontWeight: 200 }, children: dialogSubtitle })
        ] }) }),
        initializing || (children || renderChildren) && /* @__PURE__ */ jsx30(DialogContent2, { children: initializing ? /* @__PURE__ */ jsx30(
          Box22,
          {
            sx: {
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            },
            children: /* @__PURE__ */ jsx30(CircularProgress3, { size: 75 })
          }
        ) : /* @__PURE__ */ jsxs22(Box22, { sx: { display: "flex", rowGap: "16px", flexDirection: "column" }, children: [
          children,
          !children && renderChildren ? renderChildren() : null
        ] }) }),
        /* @__PURE__ */ jsxs22(DialogActions2, { children: [
          /* @__PURE__ */ jsx30(Button11, { variant: "outlined", onClick: onCancel, children: cancelButtonLabel != null ? cancelButtonLabel : "Cancel" }),
          /* @__PURE__ */ jsx30(
            LoadingButton,
            {
              variant: "contained",
              onClick: onConfirm,
              loading: isConfirming,
              disabled: isConfirming || confirmButtonDisabled,
              children: confirmButtonLabel != null ? confirmButtonLabel : "Confirm"
            }
          )
        ] })
      ]
    }
  );
};

// src/DateTimeModalPicker.tsx
import CloseIcon3 from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import {
  Alert as Alert4,
  Box as Box23,
  Button as Button12,
  Dialog as Dialog3,
  DialogActions as DialogActions3,
  DialogContent as DialogContent3,
  DialogTitle as DialogTitle3,
  IconButton as IconButton4,
  Stack as Stack8,
  TextField as TextField2,
  Typography as Typography15,
  useTheme as useTheme13
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import dayjs from "dayjs";
import { useFormikContext as useFormikContext2 } from "formik";
import { useCallback as useCallback10, useMemo as useMemo7, useState as useState13 } from "react";
import { jsx as jsx31, jsxs as jsxs23 } from "react/jsx-runtime";
var DateTimeModalPicker = ({
  dialogTitle = "Select Date & Time",
  dialogSubtitle,
  textfieldLabel,
  textfieldPlaceholder,
  textfieldHelperText,
  minAllowed,
  maxAllowed,
  formKey,
  requireTouchedForError = true,
  readOnly,
  isOptional,
  showErrors,
  includeClearButton,
  dataTestId
}) => {
  const [modalOpen, setModalOpen] = useState13(false);
  const theme = useTheme13();
  const formik = useFormikContext2();
  const [selectedDate, setSelectedDate] = useState13(
    formKey && formik.values[formKey] && dayjs(formik.values[formKey]).isValid() ? dayjs(formik.values[formKey]) : null
  );
  const [selectedTime, setSelectedTime] = useState13(
    formKey && formik.values[formKey] && dayjs(formik.values[formKey]).isValid() ? dayjs(formik.values[formKey]) : null
  );
  const [formattedSelection, setFormattedSelection] = useState13(
    formKey && formik.values[formKey] && dayjs(formik.values[formKey]).isValid() ? dayjs(formik.values[formKey]).format("L LT") : ""
  );
  const candidateValue = useMemo7(() => {
    if (!selectedDate || !selectedTime)
      return null;
    return selectedDate.hour(selectedTime.hour()).minute(selectedTime.minute()).second(0).millisecond(0);
  }, [selectedDate, selectedTime]);
  const selectedValidForSubmit = useMemo7(() => {
    if (!candidateValue)
      return false;
    return (!minAllowed || !candidateValue.isBefore(minAllowed === "now" ? dayjs().startOf("minute") : minAllowed)) && (!maxAllowed || !candidateValue.isAfter(maxAllowed === "now" ? dayjs().startOf("minute") : maxAllowed));
  }, [candidateValue, minAllowed, maxAllowed]);
  const clearModal = useCallback10(() => {
    setModalOpen(false);
    setSelectedDate(null);
    setSelectedTime(null);
  }, []);
  const openModal = useCallback10(() => {
    setModalOpen(true);
    const valueFromForm = formik.values[formKey] && dayjs(formik.values[formKey]).isValid() ? dayjs(formik.values[formKey]) : null;
    setSelectedDate(valueFromForm);
    setSelectedTime(valueFromForm);
  }, [formKey, formik.values]);
  const confirmSelection = useCallback10(() => {
    if (!candidateValue)
      return;
    setFormattedSelection(candidateValue.format("L LT"));
    formik.setFieldValue(formKey, candidateValue.toDate());
    clearModal();
  }, [candidateValue, formKey, clearModal]);
  const handleClear = useCallback10(() => {
    formik.setFieldValue(formKey, null);
    setSelectedDate(null);
    setSelectedTime(null);
    setFormattedSelection("");
  }, [formKey]);
  return /* @__PURE__ */ jsxs23(Box23, { sx: { display: "flex", columnGap: 0.5, alignItems: "flex-end" }, children: [
    /* @__PURE__ */ jsx31(
      TextField2,
      {
        fullWidth: true,
        label: textfieldLabel ? /* @__PURE__ */ jsx31(
          CommonInputLabelStack_default,
          {
            label: textfieldLabel,
            helpDescription: textfieldHelperText,
            forCtrl: "textfield",
            inputReadOnly: readOnly,
            isOptional
          }
        ) : void 0,
        placeholder: textfieldPlaceholder,
        helperText: formik.errors[formKey] && (!requireTouchedForError || formik.touched[formKey]) && showErrors !== false ? formik.errors[formKey] : formattedSelection ? "" : "",
        error: Boolean(formik.errors[formKey]) && (!Boolean(requireTouchedForError) || Boolean(formik.touched[formKey])) && showErrors !== false,
        "data-testid": dataTestId,
        onClick: openModal,
        disabled: formik.isSubmitting || readOnly,
        sx: {
          ".MuiInputBase-root:hover": {
            cursor: !formik.isSubmitting ? "pointer !important" : "default"
          },
          flexGrow: 1
        },
        InputProps: {
          readOnly: true,
          startAdornment: /* @__PURE__ */ jsx31(CalendarMonth, {})
        },
        value: formattedSelection
      }
    ),
    includeClearButton && formik.values[formKey] && /* @__PURE__ */ jsx31(
      IconButton4,
      {
        "aria-label": "close",
        color: "primary",
        title: "Clear Start Date",
        onClick: handleClear,
        children: /* @__PURE__ */ jsx31(ClearIcon, {})
      }
    ),
    /* @__PURE__ */ jsxs23(
      Dialog3,
      {
        sx: {
          "& .MuiDialog-paper": { minWidth: 650 }
        },
        open: modalOpen,
        children: [
          /* @__PURE__ */ jsx31(DialogTitle3, { children: /* @__PURE__ */ jsxs23(Stack8, { spacing: 2, children: [
            /* @__PURE__ */ jsxs23(Box23, { display: "flex", alignItems: "center", children: [
              /* @__PURE__ */ jsx31(
                Typography15,
                {
                  fontSize: theme.typography.h3.fontSize,
                  lineHeight: theme.typography.h3.lineHeight,
                  fontWeight: 600,
                  children: dialogTitle
                }
              ),
              /* @__PURE__ */ jsx31(
                IconButton4,
                {
                  "aria-label": "close",
                  onClick: clearModal,
                  sx: {
                    position: "absolute",
                    right: 8
                  },
                  children: /* @__PURE__ */ jsx31(CloseIcon3, {})
                }
              )
            ] }),
            dialogSubtitle && /* @__PURE__ */ jsx31(Typography15, { variant: "body1", children: dialogSubtitle })
          ] }) }),
          /* @__PURE__ */ jsx31(
            DialogContent3,
            {
              sx: { ".MuiMultiSectionDigitalClock-root ul.MuiList-root": { maxHeight: "fit-content" } },
              children: /* @__PURE__ */ jsxs23(Stack8, { spacing: 4, children: [
                /* @__PURE__ */ jsx31(Stack8, { direction: "row", sx: { width: "100%" }, spacing: 2, children: /* @__PURE__ */ jsxs23(LocalizationProvider, { dateAdapter: AdapterDayjs, children: [
                  /* @__PURE__ */ jsx31(
                    DateCalendar,
                    {
                      value: selectedDate,
                      onChange: (value) => setSelectedDate(value),
                      disablePast: minAllowed === "now",
                      minDate: minAllowed === "now" ? void 0 : minAllowed != null ? minAllowed : dayjs().subtract(100, "year"),
                      disableFuture: maxAllowed === "now",
                      maxDate: maxAllowed === "now" ? void 0 : maxAllowed != null ? maxAllowed : dayjs().add(100, "year"),
                      sx: { flexGrow: 2 }
                    }
                  ),
                  /* @__PURE__ */ jsx31(
                    MultiSectionDigitalClock,
                    {
                      value: selectedTime,
                      onChange: (value) => setSelectedTime(value),
                      sx: {
                        flexBasis: "215px",
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "12px"
                      },
                      timeSteps: { hours: 1, minutes: 1, seconds: 1 },
                      minutesStep: 1,
                      views: ["hours", "minutes"]
                    }
                  )
                ] }) }),
                !selectedValidForSubmit && selectedDate && selectedTime && /* @__PURE__ */ jsxs23(Alert4, { severity: "warning", variant: "filled", icon: false, children: [
                  /* @__PURE__ */ jsx31(Typography15, { variant: "body1", sx: { mb: 1 }, children: "Selected date and time is outside of allowed range" }),
                  (minAllowed || maxAllowed) && /* @__PURE__ */ jsxs23(Stack8, { children: [
                    /* @__PURE__ */ jsx31(Typography15, { variant: "caption", children: minAllowed ? "Min Bound: " + (minAllowed === "now" ? "Current Date & Time" : minAllowed.format("L LT")) : "" }),
                    /* @__PURE__ */ jsx31(Typography15, { variant: "caption", children: maxAllowed ? "Max Bound: " + (maxAllowed === "now" ? "Current Date & Time" : maxAllowed.format("L LT")) : "" })
                  ] })
                ] })
              ] })
            }
          ),
          /* @__PURE__ */ jsxs23(DialogActions3, { children: [
            /* @__PURE__ */ jsx31(
              Button12,
              {
                onClick: clearModal,
                variant: "outlined",
                sx: { flexGrow: "1" },
                "data-testid": "cancel-date-picker-modal-btn",
                children: "Cancel"
              }
            ),
            /* @__PURE__ */ jsx31(
              Button12,
              {
                variant: "contained",
                onClick: confirmSelection,
                disabled: formik.isSubmitting || !selectedValidForSubmit,
                sx: { flexGrow: "1" },
                "data-testid": "confirm-date-picker-modal-btn",
                children: "Confirm"
              }
            )
          ] })
        ]
      }
    )
  ] });
};

// src/KebabMenuButton.tsx
import MoreVert from "@mui/icons-material/MoreVert";
import { ListItemIcon } from "@mui/material";
import Button13 from "@mui/material/Button";
import IconButton5 from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem2 from "@mui/material/MenuItem";
import { orderBy, uniq } from "lodash";
import { useCallback as useCallback11, useMemo as useMemo8, useState as useState14 } from "react";
import { Fragment as Fragment13, jsx as jsx32, jsxs as jsxs24 } from "react/jsx-runtime";
var KebabMenuButton = ({
  label,
  menuItems,
  menuProps = {},
  buttonProps = {},
  buttonIcon,
  groupOrders,
  testId
}) => {
  const [anchorEl, setAnchorEl] = useState14(null);
  const handleClick = useCallback11((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback11(() => {
    setAnchorEl(null);
  }, []);
  const menuGroups = useMemo8(() => {
    return uniq(
      orderBy(
        menuItems.map((item) => {
          var _a;
          return {
            group: item.group,
            order: (_a = groupOrders == null ? void 0 : groupOrders.find((go) => go.group === item.group)) == null ? void 0 : _a.order
          };
        }),
        "order"
      ).map((e) => e.group).filter(Boolean)
    );
  }, [menuItems, groupOrders]);
  const RenderMenuItem = useCallback11(
    (groupName) => {
      return menuItems.filter((item) => (item == null ? void 0 : item.group) === groupName).map((item) => /* @__PURE__ */ jsxs24(
        MenuItem2,
        {
          onClick: () => {
            if (item.onClick && !item.disabled && !item.loading) {
              item.onClick();
              handleClose();
            }
          },
          disabled: item.disabled || item.loading,
          sx: {
            fontSize: "1rem",
            display: "flex",
            justifyContent: "flex-start",
            columnGap: "8px"
          },
          children: [
            item.loading && /* @__PURE__ */ jsx32(PhosphorLoading, { width: "25px", height: "25px", contained: false }),
            item.icon && !item.loading && /* @__PURE__ */ jsx32(ListItemIcon, { sx: { color: "primary.main" }, children: item.icon }),
            item.element || item.label
          ]
        },
        item.key
      ));
    },
    [handleClose, menuItems]
  );
  const RenderedMenuItems = useMemo8(() => {
    const result = [];
    if (!(menuGroups == null ? void 0 : menuGroups.length))
      return RenderMenuItem();
    for (const group of menuGroups) {
      result.push(
        /* @__PURE__ */ jsx32(
          MenuItem2,
          {
            sx: {
              fontWeight: "bold",
              color: (theme) => theme.palette.secondary_600,
              backgroundColor: (theme) => theme.palette.bg.primary,
              opacity: "1 !important",
              paddingX: 1
            },
            disabled: true,
            children: group
          },
          group
        )
      );
      const groupChilds = RenderMenuItem(group);
      for (const child of groupChilds) {
        result.push(child);
      }
    }
    return result;
  }, [RenderMenuItem, menuGroups]);
  return /* @__PURE__ */ jsxs24(Fragment13, { children: [
    label ? /* @__PURE__ */ jsx32(
      Button13,
      __spreadProps(__spreadValues({
        color: "primary",
        variant: "contained",
        startIcon: buttonIcon != null ? buttonIcon : /* @__PURE__ */ jsx32(MoreVert, { sx: { color: (theme) => theme.palette.neutral_900 } }),
        "aria-haspopup": "true",
        onClick: handleClick,
        "data-testid": testId
      }, buttonProps), {
        children: label
      })
    ) : /* @__PURE__ */ jsx32(
      IconButton5,
      __spreadProps(__spreadValues({
        "data-testid": testId,
        "aria-haspopup": "true",
        onClick: handleClick
      }, buttonProps), {
        children: buttonIcon != null ? buttonIcon : /* @__PURE__ */ jsx32(MoreVert, { sx: { color: (theme) => theme.palette.neutral_900 } })
      })
    ),
    /* @__PURE__ */ jsx32(
      Menu,
      __spreadProps(__spreadValues({
        anchorEl,
        open: Boolean(anchorEl),
        onClose: handleClose,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "center",
          horizontal: "right"
        },
        sx: { padding: "16px" }
      }, menuProps), {
        children: RenderedMenuItems
      })
    )
  ] });
};

// src/StructuralSkeleton.tsx
import { Box as Box24, Skeleton as Skeleton2 } from "@mui/material";
import { jsx as jsx33, jsxs as jsxs25 } from "react/jsx-runtime";
var StructuralSkeleton = ({
  columnDivisions,
  rowDivisions,
  animation = "pulse"
}) => {
  return /* @__PURE__ */ jsx33(
    Box24,
    {
      sx: {
        height: "100%",
        width: "100%",
        display: "grid",
        rowGap: "16px",
        gridTemplateRows: "1fr"
      },
      children: /* @__PURE__ */ jsxs25(
        Box24,
        {
          sx: {
            display: "grid",
            gridTemplateColumns: typeof columnDivisions === "number" ? `repeat(${columnDivisions}, 1fr)` : columnDivisions.join(" "),
            gridTemplateRows: typeof rowDivisions === "number" ? `repeat(${rowDivisions}, 1fr)` : rowDivisions.join(" "),
            height: "100%",
            width: "100%",
            rowGap: 2,
            columnGap: 2,
            ".MuiSkeleton-root": {
              height: "100%",
              width: "100%"
            }
          },
          children: [
            typeof rowDivisions === "number" && typeof columnDivisions === "number" && [...Array(rowDivisions).keys()].map(
              () => [...Array(columnDivisions).keys()].map(() => /* @__PURE__ */ jsx33(
                Skeleton2,
                {
                  animation,
                  variant: "rectangular",
                  sx: { borderRadius: "10px" }
                },
                uuidv4()
              ))
            ),
            typeof rowDivisions === "number" && typeof columnDivisions !== "number" && [...Array(rowDivisions).keys()].map(
              () => [...Array(columnDivisions.length).keys()].map(() => /* @__PURE__ */ jsx33(
                Skeleton2,
                {
                  animation,
                  variant: "rectangular",
                  sx: { borderRadius: "10px" }
                },
                uuidv4()
              ))
            ),
            typeof rowDivisions !== "number" && typeof columnDivisions === "number" && [...Array(rowDivisions.length).keys()].map(
              () => [...Array(columnDivisions).keys()].map(() => /* @__PURE__ */ jsx33(
                Skeleton2,
                {
                  animation,
                  variant: "rectangular",
                  sx: { borderRadius: "10px" }
                },
                uuidv4()
              ))
            )
          ]
        }
      )
    }
  );
};

// src/CustomGridFilterBar.tsx
import { Button as Button14 } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useInvalidateResourceQuery } from "common-api";
import React3 from "react";
import { Fragment as Fragment14, jsx as jsx34, jsxs as jsxs26 } from "react/jsx-runtime";
var CustomGridFilterBar = ({
  rightSlot,
  hideSearch,
  refreshResource,
  hideFilter,
  sx = {}
}) => {
  const invalidate = useInvalidateResourceQuery();
  if (!React3.isValidElement(rightSlot) && !refreshResource && hideFilter && hideSearch)
    return /* @__PURE__ */ jsx34(Fragment14, {});
  return /* @__PURE__ */ jsxs26(
    GridToolbarContainer,
    {
      sx: __spreadValues({
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        p: 1.5
      }, sx),
      children: [
        !hideFilter && /* @__PURE__ */ jsx34(
          GridToolbarFilterButton,
          {
            sx: {
              height: 40,
              border: "1px solid ",
              borderRadius: "32px",
              color: (theme) => theme.palette.neutral_700
            }
          }
        ),
        !hideSearch && /* @__PURE__ */ jsx34(
          GridToolbarQuickFilter,
          {
            sx: { maxWidth: 400, flexGrow: 1 },
            quickFilterParser: (searchInput) => searchInput.split(",").map((value) => value.toLowerCase().trim())
          }
        ),
        refreshResource && /* @__PURE__ */ jsx34(
          Button14,
          {
            size: "small",
            startIcon: /* @__PURE__ */ jsx34(RefreshIcon, {}),
            color: "primary",
            variant: "outlined",
            "data-test-id": "custom-grid-toolbar-refresh-btn",
            onClick: () => {
              if (typeof refreshResource === "string") {
                invalidate(refreshResource);
              } else if (Array.isArray(refreshResource)) {
                if (refreshResource.length === 1) {
                  invalidate(refreshResource[0]);
                } else if (refreshResource.length > 1) {
                  invalidate(refreshResource[0], ...refreshResource.slice(1));
                }
              }
            },
            children: "Refresh"
          }
        ),
        rightSlot
      ]
    }
  );
};

// src/SquareImageCard.tsx
import { Box as Box25, CircularProgress as CircularProgress4 } from "@mui/material";
import { useSlotValue as useSlotValue2 } from "plugin-system";
import { useState as useState15 } from "react";
import { jsx as jsx35, jsxs as jsxs27 } from "react/jsx-runtime";
var getStepImageBoxStyles = (sx) => __spreadProps(__spreadValues({
  objectFit: "cover",
  borderRadius: "12px"
}, sx || {}), {
  width: "100%",
  height: "100%"
});
var SquareImageCard = ({
  imageUrl,
  alt,
  width,
  loading,
  sx = {}
}) => {
  const [imageLoadError, setImageLoadError] = useState15(false);
  const _a = sx, {
    border: border,
    borderWidth: borderWidth,
    borderStyle: borderStyle,
    borderColor: borderColor,
    width: sxWidth,
    height: sxHeight
  } = _a, rest = __objRest(_a, [
    // @ts-ignore
    "border",
    // @ts-ignore
    "borderWidth",
    // @ts-ignore
    "borderStyle",
    // @ts-ignore
    "borderColor",
    // @ts-ignore
    "width",
    // @ts-ignore
    "height"
  ]);
  const sideLength = width || sxWidth || sxHeight || 200;
  const ImagePlaceholder = useSlotValue2(`portal.theme.icons.image-placeholder`);
  return /* @__PURE__ */ jsxs27(
    Box25,
    {
      bgcolor: "secondary_800",
      sx: __spreadProps(__spreadValues({
        borderRadius: "12px"
      }, rest), {
        width: sideLength,
        height: sideLength
      }),
      children: [
        imageUrl && imageUrl.toLowerCase().startsWith("http") && !imageLoadError && /* @__PURE__ */ jsx35(
          Box25,
          {
            component: "img",
            sx: getStepImageBoxStyles(__spreadValues({ bgcolor: "shades.white_20" }, sx)),
            src: imageUrl,
            alt,
            onError: () => setImageLoadError(true)
          }
        ),
        !loading && !imageUrl && /* @__PURE__ */ jsx35(
          Box25,
          {
            component: ImagePlaceholder,
            sx: getStepImageBoxStyles(__spreadValues({ bgcolor: "shades.white_10" }, sx))
          }
        ),
        loading && /* @__PURE__ */ jsx35(CircularProgress4, { size: sideLength, sx: { scale: "0.3" } })
      ]
    }
  );
};

// src/Status.tsx
import { Box as Box26 } from "@mui/material";
import { jsx as jsx36 } from "react/jsx-runtime";
var Status = (props) => {
  return /* @__PURE__ */ jsx36(
    Box26,
    __spreadValues({
      width: "90px",
      borderRadius: "24px",
      padding: "2px 10px",
      color: "secondary_900",
      fontSize: "12px",
      lineHeight: "1.66",
      fontWeight: "600",
      textAlign: "center",
      textTransform: "capitalize",
      height: "24px"
    }, props)
  );
};

// src/SimpleModal.tsx
import Modal2 from "@mui/material/Modal";
import Typography16 from "@mui/material/Typography";
import Paper3 from "@mui/material/Paper";
import { Box as Box27, Fade, IconButton as IconButton6 } from "@mui/material";
import CloseIcon4 from "@mui/icons-material/Close";
import { Fragment as Fragment15, jsx as jsx37, jsxs as jsxs28 } from "react/jsx-runtime";
var SimpleModal = ({
  open,
  onClose,
  children,
  title,
  titleIcon,
  description,
  caption,
  paperSxProps,
  canCloseByClickingOutside,
  testId
}) => {
  const isMobile3 = useMobileEnabled();
  return /* @__PURE__ */ jsx37(Modal2, { open, onClose: canCloseByClickingOutside ? onClose : () => null, children: /* @__PURE__ */ jsx37(Fade, { in: open, children: /* @__PURE__ */ jsx37(
    Paper3,
    {
      "data-testid": testId,
      sx: __spreadProps(__spreadValues({
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile3 ? "85%" : "636px"
      }, paperSxProps != null ? paperSxProps : {}), {
        p: 4,
        borderRadius: "14px"
      }),
      elevation: 5,
      children: /* @__PURE__ */ jsxs28(Fragment15, { children: [
        /* @__PURE__ */ jsxs28(
          Box27,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              marginBottom: Boolean(description) ? isMobile3 ? 2 : 1 : 0
            },
            children: [
              titleIcon,
              title && typeof title === "string" && /* @__PURE__ */ jsx37(
                Typography16,
                {
                  variant: "h4",
                  sx: {
                    ml: titleIcon ? 1 : 0,
                    fontWeight: 700,
                    color: (theme) => theme.palette.neutral_900
                  },
                  id: "parent-modal-title",
                  children: title
                }
              ),
              title && typeof title !== "string" && title,
              /* @__PURE__ */ jsx37(
                IconButton6,
                {
                  "aria-label": "close",
                  onClick: () => {
                    if (onClose) {
                      onClose();
                    }
                  },
                  sx: { ml: "auto" },
                  children: /* @__PURE__ */ jsx37(CloseIcon4, {})
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsxs28(Box27, { sx: { display: "flex", flexDirection: "column", rowGap: 2 }, children: [
          description && /* @__PURE__ */ jsx37(
            Typography16,
            {
              variant: "body1",
              id: "parent-modal-description",
              sx: { fontWeight: 400, color: (theme) => theme.palette.neutral_500 },
              children: description
            }
          ),
          caption && typeof caption !== "string" && caption,
          caption && typeof caption === "string" && /* @__PURE__ */ jsx37(
            Typography16,
            {
              variant: "body2",
              id: "parent-modal-caption",
              marginTop: isMobile3 ? 2 : 1,
              sx: { fontWeight: 400, color: (theme) => theme.palette.neutral_500 },
              children: caption
            }
          )
        ] }),
        /* @__PURE__ */ jsx37(Box27, { sx: { mt: 3, mb: 2 }, children })
      ] })
    }
  ) }) });
};

// src/SimpleDeleteForm.tsx
import { Stack as Stack9, Typography as Typography17 } from "@mui/material";
import Box28 from "@mui/material/Box";
import Button15 from "@mui/material/Button";
import { jsx as jsx38, jsxs as jsxs29 } from "react/jsx-runtime";
var DEFAULT_ALERT_MESSAGE = "Are you sure you want to delete this?";
var SimpleDeleteForm = ({
  id,
  deleteConfirmationMessage,
  onSuccess,
  onCancel,
  cancelLabel = "Cancel",
  confirmLabel = "Delete",
  mutate,
  children,
  isDeleting,
  deletingLabel
}) => {
  return /* @__PURE__ */ jsxs29(Stack9, { gap: "40px", children: [
    /* @__PURE__ */ jsx38(
      Typography17,
      {
        sx: {
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          lineWrap: "none"
        },
        children: deleteConfirmationMessage != null ? deleteConfirmationMessage : DEFAULT_ALERT_MESSAGE
      }
    ),
    /* @__PURE__ */ jsx38(Box28, { children }),
    /* @__PURE__ */ jsxs29(
      Box28,
      {
        sx: {
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          gap: 2
        },
        children: [
          /* @__PURE__ */ jsx38(Button15, { variant: "outlined", type: "button", onClick: onCancel, sx: { flexGrow: 1 }, children: cancelLabel }),
          /* @__PURE__ */ jsx38(
            LoadingButton,
            {
              loading: isDeleting,
              loadingText: deletingLabel,
              type: "submit",
              variant: "contained",
              sx: { flexGrow: 1 },
              onClick: () => {
                mutate(id, {
                  onSuccess: () => {
                    onSuccess == null ? void 0 : onSuccess();
                  }
                });
              },
              children: confirmLabel
            }
          )
        ]
      }
    )
  ] });
};

// src/PopoverButton.tsx
import Box29 from "@mui/material/Box";
import IconButton7 from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { useState as useState16 } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button as Button16, useTheme as useTheme14 } from "@mui/material";
import { Fragment as Fragment16, jsx as jsx39, jsxs as jsxs30 } from "react/jsx-runtime";
var PopoverButton = ({
  buttonIcon = /* @__PURE__ */ jsx39(VisibilityIcon, { fontSize: "small" }),
  buttonProps,
  buttonSx = {},
  popoverContent,
  popoverContainerSx,
  children
}) => {
  const theme = useTheme14();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState16(null);
  const handlePopoverClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  return /* @__PURE__ */ jsxs30(Fragment16, { children: [
    children ? /* @__PURE__ */ jsx39(
      Button16,
      __spreadProps(__spreadValues({
        sx: __spreadValues({
          backgroundColor: theme.palette.mode === "dark" ? "white" : "primary.main"
        }, buttonSx),
        onClick: handlePopoverClick,
        startIcon: buttonIcon,
        variant: "outlined"
      }, buttonProps != null ? buttonProps : {}), {
        children
      })
    ) : /* @__PURE__ */ jsx39(
      IconButton7,
      {
        onClick: handlePopoverClick,
        sx: __spreadValues({
          color: theme.palette.mode === "dark" ? "white" : "primary.main",
          p: 0
        }, buttonSx),
        children: buttonIcon
      }
    ),
    /* @__PURE__ */ jsx39(
      Popover,
      {
        open: Boolean(popoverAnchorEl),
        anchorEl: popoverAnchorEl,
        onClose: handlePopoverClose,
        elevation: 2,
        sx: {
          ".MuiPopover-paper": __spreadValues({
            p: 2
          }, popoverContainerSx)
        },
        children: /* @__PURE__ */ jsx39(Box29, { sx: { height: "100%", width: "100%" }, children: popoverContent })
      }
    )
  ] });
};

// src/SpinnerLoader.tsx
import { Box as Box30, CircularProgress as CircularProgress5 } from "@mui/material";
import { jsx as jsx40 } from "react/jsx-runtime";
function SpinnerLoader({
  size = 55,
  containerSx,
  progressSx
}) {
  return /* @__PURE__ */ jsx40(
    Box30,
    {
      sx: __spreadValues({
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }, containerSx != null ? containerSx : {}),
      children: /* @__PURE__ */ jsx40(CircularProgress5, { disableShrink: true, size, sx: __spreadValues({ mb: 2 }, progressSx != null ? progressSx : {}) })
    }
  );
}

// src/TopBarListItemBtn.tsx
import { ListItemButton, Typography as Typography18 } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { jsx as jsx41, jsxs as jsxs31 } from "react/jsx-runtime";
var TopBarListItemBtn = ({ handleOpen, label }) => {
  return /* @__PURE__ */ jsxs31(
    ListItemButton,
    {
      sx: {
        color: "primary.main",
        display: "flex",
        flexDirection: "row"
      },
      onClick: handleOpen,
      children: [
        /* @__PURE__ */ jsx41(ModeEditIcon, {}),
        /* @__PURE__ */ jsx41(Typography18, { fontWeight: "600", color: "primary.main", marginLeft: "12px", children: label })
      ]
    }
  );
};

// src/audience/constants.ts
import { z as z2 } from "zod";
var AudienceRecipientTypeSchema = z2.union([
  z2.literal("ETHEREUM_ADDRESS"),
  z2.literal("EMAIL")
]);
var AUDIENCE_EMAIL_SCHEMA = z2.string().email();

// src/audience/helpers.ts
import { isAddress as isAddress2 } from "ethers";
import { z as z3 } from "zod";
import { omit } from "lodash";
function getAudienceRecipientType(recipient) {
  if (recipient && isAddress2(recipient)) {
    return "ETHEREUM_ADDRESS";
  } else if (recipient && AUDIENCE_EMAIL_SCHEMA.safeParse(recipient).success) {
    return "EMAIL";
  } else {
    return void 0;
  }
}
var AudienceEntryBaseSchema = z3.object({
  recipient: z3.string().trim().nonempty("Recipient cannot be empty."),
  recipientType: AudienceRecipientTypeSchema.optional(),
  quantityToReceive: z3.number().int().gt(0, "Quantity must be greater than 0.")
}).superRefine((val, ctx) => {
  if (val.recipientType === "ETHEREUM_ADDRESS" && !isAddress2(val.recipient)) {
    ctx.addIssue({
      code: z3.ZodIssueCode.custom,
      message: "Recipient must be a valid Ethereum address.",
      path: ["recipient"]
    });
  } else if (!AUDIENCE_EMAIL_SCHEMA.safeParse(val.recipient).success && val.recipientType === "EMAIL") {
    ctx.addIssue({
      code: z3.ZodIssueCode.custom,
      message: "Recipient must be a valid email address.",
      path: ["recipient"]
    });
  } else if (val.recipientType === void 0) {
    ctx.addIssue({
      code: z3.ZodIssueCode.custom,
      message: "Recipient must be a valid Ethereum or email address.",
      path: ["recipient"]
    });
  }
}).refine((val) => {
  return val.recipientType === "ETHEREUM_ADDRESS" ? isAddress2(val.recipient) : AUDIENCE_EMAIL_SCHEMA.safeParse(val.recipient).success;
});
var defaultManualAudienceEntry = {
  valid: false,
  warning: "",
  recipientType: void 0,
  recipient: "",
  quantityToReceive: 1
};
var validateAudienceEntry = (toValidate, existingEntries, maxQuantity, defineContextSingular = "Recipient") => {
  var _a, _b;
  const isUniqueRecipient = [...existingEntries.map((entry) => entry.recipient.toUpperCase())].indexOf(
    (_a = toValidate.recipient) == null ? void 0 : _a.toUpperCase()
  ) === -1;
  if (!isUniqueRecipient) {
    return {
      valid: false,
      warning: `This ${defineContextSingular == null ? void 0 : defineContextSingular.toLowerCase()} already exists or is pending to be added.`
    };
  }
  const data = omit(toValidate, "valid", "warning");
  data.recipientType = (_b = data.recipientType) != null ? _b : getAudienceRecipientType(data.recipient);
  const parsed = AudienceEntryBaseSchema.safeParse(data);
  const firstIssue = !parsed.success && parsed.error.issues[0];
  if (firstIssue) {
    return { valid: false, warning: firstIssue.message };
  } else if (maxQuantity && toValidate.quantityToReceive > maxQuantity) {
    return {
      valid: false,
      warning: `Quantity must be exactly ${maxQuantity}.`
    };
  }
  return { valid: true };
};
function mapSingleToAudienceEntry(allowlistEntry) {
  if (!allowlistEntry)
    return {};
  return {
    recipient: allowlistEntry.email || allowlistEntry.address || "",
    recipientType: allowlistEntry.email ? "EMAIL" : "ETHEREUM_ADDRESS",
    quantityToReceive: allowlistEntry.quantity_allowed
  };
}
function mapToAudienceEntries(allowlistEntries) {
  return allowlistEntries.map(mapSingleToAudienceEntry);
}
function mapListingAllowlistToAudienceEntry(allowlistEntry) {
  if (!allowlistEntry)
    return {};
  return {
    recipient: allowlistEntry.email_address || allowlistEntry.eth_address || "",
    recipientType: allowlistEntry.email_address ? "EMAIL" : "ETHEREUM_ADDRESS",
    quantityToReceive: 0
  };
}

// src/audience/CsvUpload.tsx
import {
  Alert as Alert5,
  Box as Box31,
  Button as Button17,
  IconButton as IconButton8,
  InputLabel as InputLabel3,
  Stack as Stack10,
  Typography as Typography19,
  useTheme as useTheme15
} from "@mui/material";
import { usePapaParse } from "react-papaparse";
import { useCallback as useCallback12, useEffect as useEffect11, useState as useState17 } from "react";
import uniq2 from "lodash/uniq";
import filter from "lodash/filter";
import sortBy2 from "lodash/sortBy";
import DownloadIcon2 from "@mui/icons-material/Download";
import find from "lodash/find";
import { SlotRenderer as SlotRenderer2 } from "plugin-system";
import { isEmailAddress as isEmailAddress3 } from "common-api";
import { Fragment as Fragment17, jsx as jsx42, jsxs as jsxs32 } from "react/jsx-runtime";
var AudienceEntryCsvUpload = ({
  label,
  includeTemplateDownloadButton,
  handleChange,
  existingEntries,
  allowedRecipientTypes,
  includeQuantity = true,
  quantityHiddenDefault = 1
}) => {
  const theme = useTheme15();
  const canAllowEthereumAddresses = allowedRecipientTypes.includes("ETHEREUM_ADDRESS");
  const canAllowEmailAddresses = allowedRecipientTypes.includes("EMAIL");
  const [csvFormatRespected, setCsvFormatRespected] = useState17(void 0);
  const [validEntries, setValidEntries] = useState17([]);
  const [invalidRecords, setInvalidRecords] = useState17([]);
  const { checkConsent } = useCheckConsentMultiple();
  const { jsonToCSV, readString } = usePapaParse();
  const onTemplateDownload = useCallback12(() => {
    const templateData = [];
    if (canAllowEmailAddresses) {
      const emailCandidate = { recipient: "example@test-domain.be" };
      if (includeQuantity) {
        ;
        emailCandidate["quantityToReceive"] = 1;
      }
      templateData.push(emailCandidate);
    }
    if (canAllowEthereumAddresses) {
      const candidate = {
        recipient: "0x0000000000000000000000000000000000000000"
      };
      if (includeQuantity) {
        ;
        candidate["quantityToReceive"] = 1;
      }
      templateData.push(candidate);
    }
    const csv = jsonToCSV(
      {
        data: templateData
      },
      { header: true, skipEmptyLines: true }
    );
    downloadCsvFromString(csv, "drop-audience-import-template");
  }, [jsonToCSV, canAllowEthereumAddresses, canAllowEmailAddresses, includeQuantity]);
  const handleDownloadInvalidCsvRecords = useCallback12(() => {
    const csvString = jsonToCSV(invalidRecords);
    downloadCsvFromString(csvString, "invalid-drop-audience-entries");
  }, [invalidRecords, jsonToCSV]);
  const handleFileClear = useCallback12(() => {
    setValidEntries([]);
    setInvalidRecords([]);
    setCsvFormatRespected(void 0);
  }, []);
  useEffect11(() => {
    handleChange(validEntries, invalidRecords);
  }, [validEntries, invalidRecords, handleChange]);
  const handleDeleteEntry = useCallback12(
    (toDelete) => {
      setValidEntries(validEntries.filter((trait) => trait !== toDelete));
    },
    [validEntries]
  );
  function checkIfCsvHasHeaders(content) {
    const firstLine = content.split("\n")[0];
    if (firstLine.trim().includes("recipient")) {
      return true;
    }
    return false;
  }
  const handleFileUpload = useCallback12(
    (file) => {
      if (file.type !== "text/csv") {
        setCsvFormatRespected(false);
        return;
      }
      const reader = new FileReader();
      reader.onabort = () => handleFileClear();
      reader.onerror = () => handleFileClear();
      reader.onload = () => {
        const content = reader.result;
        const hasHeaders = checkIfCsvHasHeaders(content);
        readString(content, {
          worker: true,
          header: hasHeaders,
          complete: (results) => __async(void 0, null, function* () {
            var _a;
            const valid = [];
            const invalid = [];
            const uniqueCsvRecords = uniq2(
              filter(results.data, (record) => Object.keys(record).length >= 1)
            );
            const existingRecipients = (_a = existingEntries == null ? void 0 : existingEntries.map((entry) => entry.recipient.toLowerCase())) != null ? _a : [];
            const emailConsentReport = yield checkConsent(
              uniqueCsvRecords.map((record) => record.recipient || record["0"]).filter((recipient) => recipient && isEmailAddress3(recipient))
            );
            for (const csvRecord of uniqueCsvRecords) {
              const keys = Object.keys(csvRecord);
              const recipientKey = keys[0];
              const quantityKey = keys[1];
              const recipient = csvRecord[recipientKey];
              if (!recipient)
                continue;
              const recipientType = getAudienceRecipientType(recipient);
              const consentValid = checkConsentViaReport(recipient, emailConsentReport);
              const isRecipientUnique = existingRecipients.indexOf(String(recipient).toLowerCase()) === -1;
              const quantity = includeQuantity && /^\d+$/.test(csvRecord[quantityKey]) ? Number(csvRecord[quantityKey]) : quantityHiddenDefault;
              const isQuantityValid = quantity >= 1;
              const isRecordValid = !!recipientType && isRecipientUnique && isQuantityValid && consentValid && (canAllowEmailAddresses || recipientType !== "EMAIL") && (canAllowEthereumAddresses || recipientType !== "ETHEREUM_ADDRESS");
              if (isRecordValid) {
                valid.push({
                  recipient,
                  recipientType,
                  quantityToReceive: quantity
                });
              } else {
                let error = "";
                if (recipientType === null) {
                  error = `Invalid recipient: a recipient must be either a valid Ethereum address or an email address.`;
                } else if (!consentValid) {
                  error = `Invalid recipient: you do not have consent to email this address.`;
                } else if (!canAllowEmailAddresses && recipientType === "EMAIL") {
                  error = `Invalid recipient: email addresses are not allowed.`;
                } else if (!canAllowEthereumAddresses && recipientType === "ETHEREUM_ADDRESS") {
                  error = `Invalid recipient: Ethereum addresses are not allowed.`;
                } else if (!isRecipientUnique) {
                  error = `Duplicate recipient: this recipient might already exist in the drop audience.`;
                } else if (!isQuantityValid) {
                  error = `Invalid quantity: quantity must be larger than 0.`;
                }
                invalid.push(__spreadProps(__spreadValues({}, csvRecord), {
                  error
                }));
              }
            }
            setValidEntries(valid);
            setInvalidRecords(invalid);
            setCsvFormatRespected(valid.length > 0 || invalid.length > 0);
          })
        });
      };
      reader.readAsText(file);
    },
    [
      handleFileClear,
      readString,
      existingEntries,
      checkConsent,
      canAllowEmailAddresses,
      canAllowEthereumAddresses,
      includeQuantity,
      quantityHiddenDefault
    ]
  );
  return /* @__PURE__ */ jsxs32(Stack10, { children: [
    label && /* @__PURE__ */ jsx42(InputLabel3, { children: label }),
    /* @__PURE__ */ jsxs32(Stack10, { spacing: 2, children: [
      includeTemplateDownloadButton && /* @__PURE__ */ jsx42(
        Button17,
        {
          onClick: onTemplateDownload,
          color: "primary",
          variant: "text",
          startIcon: /* @__PURE__ */ jsx42(DownloadIcon2, {}),
          children: "Download Template"
        }
      ),
      /* @__PURE__ */ jsx42(Stack10, { direction: "row", spacing: 1, alignItems: "center", children: /* @__PURE__ */ jsx42(
        Box31,
        {
          sx: {
            flexGrow: 1,
            height: 40,
            ".filepond--wrapper": {
              borderRadius: theme.spacing(1),
              borderColor: theme.palette.neutral_200,
              backgroundColor: theme.palette.neutral_50,
              ".filepond--file-info, .filepond--drop-label label": {
                color: (theme2) => theme2.palette.neutral_900
              },
              height: "100%",
              ".filepond--drop-label": {
                minHeight: "auto"
              }
            }
          },
          children: /* @__PURE__ */ jsx42(
            BasicFileUpload,
            {
              allowImagePreview: false,
              allowMultiple: false,
              labelIdle: 'Drag & Drop a CSV file or <span class="filepond--label-action">Browse</span>',
              acceptedFileTypes: ["text/csv"],
              stylePanelLayout: "integrated",
              onremovefile: handleFileClear,
              onaddfile: (_, { file }) => {
                handleFileUpload(file);
              }
            }
          )
        }
      ) }),
      csvFormatRespected === false && /* @__PURE__ */ jsx42(Alert5, { severity: "warning", children: /* @__PURE__ */ jsx42(Typography19, { variant: "body1", children: "The CSV file you uploaded does not respect the required format." }) }),
      validEntries.length > 0 && /* @__PURE__ */ jsx42(
        ChipList,
        {
          elements: sortBy2(validEntries, (entry) => entry.recipient),
          onDelete: handleDeleteEntry,
          labelFormatter: (entry) => /* @__PURE__ */ jsxs32(Stack10, { direction: "row", children: [
            entry.recipientType === "ETHEREUM_ADDRESS" ? /* @__PURE__ */ jsx42(EthereumAddress_default, { shortFormat: true, value: entry.recipient, showExplorerIcon: true }) : /* @__PURE__ */ jsx42(Fragment17, { children: entry.recipient }),
            includeQuantity && /* @__PURE__ */ jsx42("span", { style: { marginLeft: "3px" }, children: `| Qty: ${entry.quantityToReceive}` })
          ] })
        }
      ),
      invalidRecords.length > 0 && /* @__PURE__ */ jsxs32(Fragment17, { children: [
        /* @__PURE__ */ jsx42(
          Alert5,
          {
            severity: "warning",
            action: /* @__PURE__ */ jsx42(IconButton8, { onClick: handleDownloadInvalidCsvRecords, color: "inherit", children: /* @__PURE__ */ jsx42(DownloadIcon2, {}) }),
            children: /* @__PURE__ */ jsxs32(Typography19, { variant: "body1", children: [
              "A total of ",
              invalidRecords.length,
              " invalid recipients were found."
            ] })
          }
        ),
        Boolean(
          find(
            invalidRecords,
            (record) => record.error.toLowerCase().includes("you do not have consent")
          )
        ) && /* @__PURE__ */ jsx42(SlotRenderer2, { slot: "portal.community.follower-share-link" })
      ] })
    ] })
  ] });
};

// src/audience/DefineSingle.tsx
import { Alert as Alert6, Stack as Stack11, TextField as TextField3 } from "@mui/material";
import { useEffect as useEffect12, useMemo as useMemo9 } from "react";
import { jsx as jsx43, jsxs as jsxs33 } from "react/jsx-runtime";
var DefineSingleAudienceEntry = ({
  recipient,
  quantityToReceive,
  warning,
  handleChange,
  allowedRecipientTypes,
  maxQuantity = void 0,
  includeQuantity = true,
  quantityHiddenDefault = 1,
  defineContextSingular = "Recipient"
}) => {
  const label = useMemo9(() => {
    if (allowedRecipientTypes.includes("EMAIL") && allowedRecipientTypes.includes("ETHEREUM_ADDRESS")) {
      return "Email or Wallet Address";
    } else if (allowedRecipientTypes.includes("EMAIL")) {
      return "Email";
    } else if (allowedRecipientTypes.includes("ETHEREUM_ADDRESS")) {
      return "Wallet Address";
    } else
      return "";
  }, [allowedRecipientTypes]);
  const helpDescription = useMemo9(() => {
    if (allowedRecipientTypes.includes("EMAIL") && allowedRecipientTypes.includes("ETHEREUM_ADDRESS")) {
      return "Specify the email address or wallet address of the recipient";
    } else if (allowedRecipientTypes.includes("EMAIL")) {
      return "Specify the email address of the recipient";
    } else if (allowedRecipientTypes.includes("ETHEREUM_ADDRESS")) {
      return "Specify the wallet address of the recipient";
    } else
      return "";
  }, [allowedRecipientTypes]);
  useEffect12(() => {
    if (includeQuantity === false && quantityHiddenDefault) {
      if (handleChange)
        handleChange("quantityToReceive", quantityHiddenDefault);
    }
  }, [includeQuantity, quantityHiddenDefault]);
  return /* @__PURE__ */ jsxs33(Stack11, { spacing: 2, sx: { width: "100%", ".MuiStack-root": { flexGrow: 1 } }, children: [
    /* @__PURE__ */ jsx43(
      TextField3,
      {
        fullWidth: true,
        id: "recipient",
        "data-testid": "recipient",
        name: "recipient",
        label: /* @__PURE__ */ jsx43(CommonInputLabelStack, { label, helpDescription }),
        type: "text",
        value: recipient,
        onChange: (e) => handleChange("recipient", e.target.value)
      }
    ),
    includeQuantity && /* @__PURE__ */ jsx43(
      TextField3,
      {
        fullWidth: true,
        id: "quantityToReceive",
        name: "quantityToReceive",
        disabled: Boolean(maxQuantity),
        InputProps: {
          inputProps: maxQuantity ? { min: maxQuantity, max: maxQuantity } : { min: 1 }
        },
        label: /* @__PURE__ */ jsx43(
          CommonInputLabelStack,
          {
            label: "Quantity",
            helpDescription: `Specify how many copies this ${defineContextSingular == null ? void 0 : defineContextSingular.toLowerCase()} should receive`
          }
        ),
        type: "text",
        value: quantityToReceive === 0 ? "" : quantityToReceive.toString(),
        onChange: (e) => {
          const value = e.target.value;
          if (value === "" || /^[0-9]+$/.test(value)) {
            handleChange("quantityToReceive", value === "" ? 0 : Number(value));
          }
        }
      }
    ),
    warning && typeof warning === "string" && /* @__PURE__ */ jsx43(Alert6, { color: "warning", children: warning }),
    warning && typeof warning !== "string" && warning
  ] });
};
var DefineSingle_default = DefineSingleAudienceEntry;

// src/audience/DefineDialog.tsx
import { Alert as Alert8, Box as Box33, Button as Button18, Stack as Stack13, Typography as Typography21 } from "@mui/material";
import find3 from "lodash/find";
import sortBy3 from "lodash/sortBy";
import { useCallback as useCallback13, useState as useState19 } from "react";
import { usePapaParse as usePapaParse2 } from "react-papaparse";

// ../api/src/sdks/sdk-drop.ts
import globalAxios2 from "axios";

// ../api/src/sdks/base.ts
import globalAxios from "axios";

// ../api/src/sdks/sdk-drop.ts
var AudienceListMemberTypeEnum = {
  EmailAddress: "EMAIL_ADDRESS",
  EthereumAddress: "ETHEREUM_ADDRESS"
};

// src/audience/DefineDialog.tsx
import { SlotRenderer as SlotRenderer4 } from "plugin-system";

// src/audience/CommunitySelect.tsx
import {
  Box as Box32,
  FormControl as FormControl2,
  InputLabel as InputLabel4,
  Tooltip as Tooltip6,
  Select as Select2,
  MenuItem as MenuItem3,
  Stack as Stack12,
  Typography as Typography20,
  LinearProgress as LinearProgress5,
  FormHelperText as FormHelperText3,
  Alert as Alert7,
  Autocomplete,
  TextField as TextField4
} from "@mui/material";
import {
  useAllSnapshotHoldersByAddress,
  useAllSnapshots,
  useAllCreatorFollowers,
  useAllAudienceLists,
  useAllAudienceListMembers
} from "common-api";
import InfoIcon2 from "@mui/icons-material/Info";
import { find as find2 } from "lodash";
import { useState as useState18, useMemo as useMemo10, useEffect as useEffect13 } from "react";
import { SlotRenderer as SlotRenderer3 } from "plugin-system";

// ../api/src/sdks/sdk-platform.ts
import globalAxios3 from "axios";
var SnapshotOutputStatusEnum = {
  Pending: "PENDING",
  Error: "ERROR",
  Completed: "COMPLETED"
};

// src/audience/CommunitySelect.tsx
import { jsx as jsx44, jsxs as jsxs34 } from "react/jsx-runtime";
var CommunitySelect = ({
  onCommunitySelected,
  onCommunityHolderDataPending,
  allowedRecipientTypes
}) => {
  var _a;
  const [communitySource, setCommunitySource] = useState18("");
  const canAllowEthereumAddresses = allowedRecipientTypes.includes("ETHEREUM_ADDRESS");
  const canAllowEmailAddresses = allowedRecipientTypes.includes("EMAIL");
  const [selectedSnapshot, setSelectedSnapshot] = useState18(void 0);
  const [selectedFollower, setSelectedFollower] = useState18(
    void 0
  );
  const [selectedAudienceList, setSelectedAudienceList] = useState18(
    void 0
  );
  const { data: snapshotHolders, isPending: snapshotHoldersPending } = useAllSnapshotHoldersByAddress((_a = selectedSnapshot == null ? void 0 : selectedSnapshot.id) != null ? _a : "");
  const {
    data: snapshots,
    isPending: snapshotsPending,
    isFetched: snapshotsFetched
  } = useAllSnapshots();
  const { data: followers, isPending: followersPending } = useAllCreatorFollowers();
  const { data: audienceLists, isPending: audienceListsPending } = useAllAudienceLists();
  const { data: audienceListMembers, isPending: audienceListMembersPending } = useAllAudienceListMembers(selectedAudienceList == null ? void 0 : selectedAudienceList.id);
  const relevantAudienceListMembers = useMemo10(() => {
    if (!audienceListMembers)
      return [];
    return audienceListMembers.filter((member) => {
      if (allowedRecipientTypes.includes("EMAIL") && member.type === "EMAIL_ADDRESS")
        return true;
      if (allowedRecipientTypes.includes("ETHEREUM_ADDRESS") && member.type === "ETHEREUM_ADDRESS")
        return true;
      return false;
    });
  }, [audienceListMembers, allowedRecipientTypes]);
  const handleCommunitySourceChange = (event) => {
    setSelectedSnapshot(void 0);
    setSelectedFollower(void 0);
    setSelectedAudienceList(void 0);
    setCommunitySource(event.target.value);
  };
  const completedSnapshotsWithHolders = useMemo10(() => {
    var _a2;
    return (_a2 = snapshots == null ? void 0 : snapshots.filter(
      (snapshot) => snapshot.status === SnapshotOutputStatusEnum.Completed && snapshot.holder_count && snapshot.holder_count > 0
    )) != null ? _a2 : [];
  }, [snapshots]);
  const dummySnapshot = useMemo10(() => {
    return { id: "0", name: snapshotsPending ? "LOADING SNAPSHOTS" : "NO SNAPSHOTS FOUND" };
  }, [snapshotsPending]);
  useEffect13(() => {
    if (snapshotHoldersPending && selectedSnapshot)
      onCommunityHolderDataPending(selectedSnapshot);
  }, [snapshotHoldersPending, selectedSnapshot]);
  useEffect13(() => {
    if (snapshotHolders && snapshotHolders.length > 0 && selectedSnapshot) {
      onCommunitySelected(selectedSnapshot, snapshotHolders);
    }
  }, [snapshotHolders, selectedSnapshot]);
  useEffect13(() => {
    if (followersPending && selectedFollower)
      onCommunityHolderDataPending(selectedFollower);
  }, [followersPending, selectedFollower]);
  useEffect13(() => {
    if (followers && followers.results.length > 0 && selectedFollower)
      onCommunitySelected(selectedFollower);
  }, [followers, selectedFollower]);
  useEffect13(() => {
    if (audienceListMembersPending && selectedAudienceList)
      onCommunityHolderDataPending(selectedAudienceList);
  }, [audienceListMembersPending, selectedAudienceList]);
  useEffect13(() => {
    if (relevantAudienceListMembers && relevantAudienceListMembers.length > 0 && selectedAudienceList)
      onCommunitySelected(selectedAudienceList, void 0, relevantAudienceListMembers);
  }, [relevantAudienceListMembers, selectedAudienceList]);
  return /* @__PURE__ */ jsxs34(Box32, { children: [
    /* @__PURE__ */ jsxs34(FormControl2, { fullWidth: true, children: [
      /* @__PURE__ */ jsxs34(
        InputLabel4,
        {
          id: "community-source-select-label",
          sx: { display: "flex", alignItems: "center" },
          children: [
            "Select Source",
            /* @__PURE__ */ jsx44(Tooltip6, { title: "You can choose existing audiences from your Snapshots or Follower lists of members that have signed up for your collectible drops.", children: /* @__PURE__ */ jsx44(InfoIcon2, { fontSize: "small", style: { marginLeft: "5px", color: "white" } }) })
          ]
        }
      ),
      /* @__PURE__ */ jsxs34(
        Select2,
        {
          "data-testid": "community-source-select",
          labelId: "community-source-select-label",
          id: "community-source-select",
          value: communitySource,
          disabled: !canAllowEthereumAddresses && !canAllowEmailAddresses,
          onChange: handleCommunitySourceChange,
          children: [
            /* @__PURE__ */ jsx44(MenuItem3, { value: "audienceList", children: "Audience Lists" }),
            canAllowEthereumAddresses && /* @__PURE__ */ jsx44(MenuItem3, { value: "snapshot", children: "Snapshot Lists" }),
            canAllowEmailAddresses && /* @__PURE__ */ jsx44(MenuItem3, { value: "followerLists", children: "Follower Lists" })
          ]
        }
      )
    ] }),
    communitySource === "audienceList" && /* @__PURE__ */ jsxs34(Box32, { sx: { pt: 2 }, children: [
      /* @__PURE__ */ jsx44(FormControl2, { fullWidth: true, children: /* @__PURE__ */ jsx44(
        CommonInputLabelStack,
        {
          label: "Select an Audience",
          forCtrl: "autocomplete",
          inputReadOnly: audienceListsPending || !audienceLists.length
        }
      ) }),
      /* @__PURE__ */ jsx44(
        Autocomplete,
        {
          "data-tetsid": "audienceLists-list-autocomplete",
          id: "audienceLists-list-audienceLists-autocomplete",
          disabled: audienceListsPending || !audienceLists.length,
          options: audienceLists.filter((audienceLists2) => audienceLists2.name),
          getOptionLabel: (option) => option.name || "No Name",
          renderInput: (params) => /* @__PURE__ */ jsx44(TextField4, __spreadValues({}, params)),
          value: selectedAudienceList,
          onChange: (_, value) => {
            setSelectedAudienceList(value != null ? value : void 0);
            setSelectedFollower(void 0);
            setSelectedSnapshot(void 0);
          }
        }
      ),
      audienceListMembersPending && Boolean(selectedAudienceList == null ? void 0 : selectedAudienceList.id) && /* @__PURE__ */ jsxs34(Stack12, { spacing: 2, sx: { mt: 2 }, children: [
        /* @__PURE__ */ jsx44(Typography20, { children: "Loading Eligible Audience List Members..." }),
        /* @__PURE__ */ jsx44(LinearProgress5, {})
      ] }),
      !audienceListMembersPending && relevantAudienceListMembers && /* @__PURE__ */ jsx44(Stack12, { spacing: 2, sx: { mt: 2 }, children: /* @__PURE__ */ jsxs34(FormHelperText3, { error: !relevantAudienceListMembers.length, children: [
        relevantAudienceListMembers.length,
        " Eligible Member",
        relevantAudienceListMembers.length === 1 ? "" : "s",
        " detected"
      ] }) }),
      !audienceListsPending && !audienceLists && /* @__PURE__ */ jsx44(Alert7, { severity: "info", sx: { mt: 2 }, children: /* @__PURE__ */ jsx44(Typography20, { variant: "body1", children: "It seems like you havn't made any audienceLists lists yet." }) })
    ] }),
    communitySource === "snapshot" && /* @__PURE__ */ jsx44(Box32, { sx: { pt: 2 }, children: /* @__PURE__ */ jsxs34(FormControl2, { fullWidth: true, children: [
      /* @__PURE__ */ jsx44(
        CommonInputLabelStack,
        {
          label: "Select a Snapshot",
          forCtrl: "autocomplete",
          inputReadOnly: snapshotsPending || !completedSnapshotsWithHolders.length
        }
      ),
      /* @__PURE__ */ jsx44(
        Autocomplete,
        {
          "data-testid": "snapshot-audienceLists-autocomplete",
          id: "snapshot-audienceLists-autocomplete",
          disabled: snapshotsPending || !completedSnapshotsWithHolders.length,
          isOptionEqualToValue: (option, value) => {
            return Boolean(option == null ? void 0 : option.id) && Boolean(value == null ? void 0 : value.id) && (option == null ? void 0 : option.id) === value.id;
          },
          options: !(completedSnapshotsWithHolders == null ? void 0 : completedSnapshotsWithHolders.length) || snapshotsPending ? [dummySnapshot] : completedSnapshotsWithHolders,
          getOptionLabel: (option) => option.name,
          renderOption: (props, option) => /* @__PURE__ */ jsx44("li", __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx44(SnapshotHolderMenuItemContent, { snapshot: option }) }), option.id),
          value: selectedSnapshot,
          disableClearable: true,
          size: "small",
          includeInputInList: true,
          onChange: (_, newValue) => {
            const candidate = find2(
              completedSnapshotsWithHolders,
              (snapshot) => snapshot.id === (newValue == null ? void 0 : newValue.id)
            );
            if (candidate) {
              setSelectedSnapshot(candidate);
              setSelectedFollower(void 0);
              setSelectedAudienceList(void 0);
            } else
              return false;
          },
          clearOnBlur: false,
          sx: { ".MuiInput-root": { paddingTop: 0, margin: 0 } },
          renderInput: (params) => {
            return /* @__PURE__ */ jsx44(TextField4, __spreadValues({ sx: {} }, params));
          }
        }
      ),
      snapshotHoldersPending && Boolean(selectedSnapshot == null ? void 0 : selectedSnapshot.id) && /* @__PURE__ */ jsxs34(Stack12, { spacing: 2, sx: { mt: 2 }, children: [
        /* @__PURE__ */ jsx44(Typography20, { children: "Loading Snapshot Wallet Addresses..." }),
        /* @__PURE__ */ jsx44(LinearProgress5, {})
      ] }),
      !snapshotHoldersPending && snapshotHolders.length > 0 && /* @__PURE__ */ jsx44(Stack12, { spacing: 2, sx: { mt: 2 }, children: /* @__PURE__ */ jsxs34(FormHelperText3, { children: [
        snapshotHolders.length,
        " Wallet Address",
        snapshotHolders.length === 1 ? "" : "es",
        " detected"
      ] }) }),
      !snapshotsPending && (!completedSnapshotsWithHolders || !completedSnapshotsWithHolders.length) && snapshotsFetched && /* @__PURE__ */ jsxs34(Alert7, { severity: "info", sx: { mt: 2 }, children: [
        /* @__PURE__ */ jsx44(Typography20, { variant: "body1", children: "It seems like you don't have any completed snapshots yet that have at least 1 collector." }),
        /* @__PURE__ */ jsxs34("ul", { style: { paddingLeft: "15px" }, children: [
          /* @__PURE__ */ jsx44("li", { children: /* @__PURE__ */ jsx44(Typography20, { variant: "body2", children: "If you want to create a new snapshot, you can do so by going to the snapshot creator." }) }),
          /* @__PURE__ */ jsx44("li", { children: /* @__PURE__ */ jsx44(Typography20, { variant: "body2", children: "Snapshots awaiting completion will show up here once they are completed." }) })
        ] })
      ] })
    ] }) }),
    communitySource === "followerLists" && /* @__PURE__ */ jsx44(Box32, { sx: { pt: 2 }, children: /* @__PURE__ */ jsxs34(FormControl2, { fullWidth: true, children: [
      /* @__PURE__ */ jsx44(InputLabel4, { id: "collector-audienceLists-select-label", children: "Select a List" }),
      /* @__PURE__ */ jsx44(
        Select2,
        {
          "data-testid": "collector-audienceLists-select",
          labelId: "collector-audienceLists-select-label",
          id: "collector-audienceLists-select",
          value: selectedSnapshot,
          onChange: (e) => {
            setSelectedFollower(followers);
            setSelectedSnapshot(void 0);
            setSelectedAudienceList(void 0);
          },
          disabled: followersPending,
          placeholder: "Add to your email followers by sharing your follower link",
          children: /* @__PURE__ */ jsx44(MenuItem3, { value: 1, sx: { p: 0 }, children: /* @__PURE__ */ jsx44(FollowerHolderMenuItem, { subscriptionList: followers }) })
        }
      ),
      followersPending && /* @__PURE__ */ jsxs34(Stack12, { spacing: 2, sx: { mt: 1 }, children: [
        /* @__PURE__ */ jsx44(Typography20, { children: "Loading Followers..." }),
        /* @__PURE__ */ jsx44(LinearProgress5, {})
      ] }),
      /* @__PURE__ */ jsx44(SlotRenderer3, { slot: "portal.community.no-followers-share-link" })
    ] }) })
  ] });
};

// src/audience/DefineDialog.tsx
import { Fragment as Fragment18, jsx as jsx45, jsxs as jsxs35 } from "react/jsx-runtime";
var SnapshotHolderMenuItemContent = ({ snapshot }) => {
  var _a;
  return /* @__PURE__ */ jsx45(
    Box33,
    {
      sx: {
        width: "100%",
        height: "100%"
      },
      children: snapshot.id === "0" ? /* @__PURE__ */ jsx45(Fragment18, { children: snapshot.name }) : /* @__PURE__ */ jsx45(Typography21, { variant: "caption", children: (snapshot == null ? void 0 : snapshot.holder_count) ? `${snapshot.name} \u2022 ${(_a = snapshot == null ? void 0 : snapshot.holder_count) != null ? _a : 0} address${(snapshot == null ? void 0 : snapshot.holder_count) === 1 ? "" : "es"}` : snapshot.name })
    }
  );
};
var FollowerHolderMenuItem = ({
  subscriptionList
}) => {
  var _a;
  const recipientCount = (_a = subscriptionList == null ? void 0 : subscriptionList.results.length) != null ? _a : 0;
  return /* @__PURE__ */ jsx45(
    Box33,
    {
      sx: {
        width: "100%",
        height: "100%",
        p: 1
      },
      children: /* @__PURE__ */ jsx45(Typography21, { variant: "caption", children: `Email Followers \u2022 ${recipientCount} recipient${recipientCount === 1 ? "" : "s"}` })
    }
  );
};
var CustomDefineDropAudienceEntries = ({
  handleAudienceEntries,
  existingEntries,
  allowedRecipientTypes,
  includeQuantity
}) => {
  const [communityHolders, setCommunityHolders] = useState19(
    []
  );
  const [duplicateCandidates, setDuplicateCandidates] = useState19([]);
  const [follower, setFollower] = useState19(void 0);
  const [snapshot, setSnapshot] = useState19(void 0);
  const [, setAudienceList] = useState19(void 0);
  const { jsonToCSV } = usePapaParse2();
  const handleDownloadCsv = useCallback13(() => {
    if (!follower && !snapshot)
      return;
    if (communityHolders && snapshot) {
      const csv = jsonToCSV(
        {
          data: communityHolders.map((holder) => {
            const holderEntry = { recipient: holder == null ? void 0 : holder.address };
            if (includeQuantity) {
              ;
              holderEntry["quantityToReceive"] = 1;
            }
            return holderEntry;
          })
        },
        { header: true, skipEmptyLines: true }
      );
      downloadCsvFromString(csv, `snapshot-audience-${snapshot.name}-import-template`);
    } else if (follower) {
      const csvData = follower.results.map((entry) => {
        const followerEntry = { recipient: entry == null ? void 0 : entry.email_address };
        if (includeQuantity) {
          ;
          followerEntry["quantityToReceive"] = 1;
        }
        return followerEntry;
      });
      const csv = jsonToCSV(csvData, { header: true, skipEmptyLines: true });
      downloadCsvFromString(csv, `follower-list-import-template`);
    }
  }, [communityHolders, jsonToCSV, snapshot, follower, includeQuantity]);
  return /* @__PURE__ */ jsxs35(Stack13, { spacing: 2, children: [
    /* @__PURE__ */ jsx45(
      CommunitySelect,
      {
        allowedRecipientTypes,
        onCommunityHolderDataPending: () => {
          setCommunityHolders([]);
          setDuplicateCandidates([]);
        },
        onCommunitySelected: (selected, communityHolders2, listMembers) => {
          if ("collection_id" in selected && communityHolders2) {
            const existingEntryAddresses = existingEntries.filter((entry) => entry.recipientType === "ETHEREUM_ADDRESS").map((entry) => entry.recipient);
            const duplicates = [];
            const validHolders = communityHolders2.filter((holder) => {
              const isDuplicate = find3(
                existingEntryAddresses,
                (existingEntryAddress) => existingEntryAddress === holder.address
              );
              if (isDuplicate) {
                duplicates.push(holder);
                return false;
              } else
                return true;
            });
            setDuplicateCandidates(duplicates);
            handleAudienceEntries(
              validHolders.map((holder) => {
                return {
                  recipient: holder.address,
                  quantityToReceive: 1,
                  recipientType: "ETHEREUM_ADDRESS"
                };
              })
            );
            setCommunityHolders(validHolders);
            setSnapshot(selected);
            setFollower(void 0);
            setAudienceList(void 0);
          } else if ("results" in selected && selected.results) {
            handleAudienceEntries(
              selected.results.map((follower2) => {
                return {
                  recipient: follower2.email_address,
                  quantityToReceive: 1,
                  recipientType: "EMAIL"
                };
              })
            );
            setFollower(selected);
            setSnapshot(void 0);
            setAudienceList(void 0);
          } else if ("id" in selected && selected.id && listMembers) {
            const existingEntryAddresses = existingEntries.map((entry) => entry.recipient);
            const duplicates = [];
            const validMembers = listMembers.filter((member) => {
              const isDuplicate = find3(
                existingEntryAddresses,
                (existingEntryAddress) => existingEntryAddress === member.value
              );
              if (isDuplicate) {
                duplicates.push(member);
                return false;
              } else
                return true;
            });
            setDuplicateCandidates(duplicates);
            handleAudienceEntries(
              validMembers.map((member) => {
                return {
                  recipient: member.value,
                  quantityToReceive: 1,
                  recipientType: member.type === AudienceListMemberTypeEnum.EmailAddress ? "EMAIL" : "ETHEREUM_ADDRESS"
                };
              })
            );
            setAudienceList(selected);
            setFollower(void 0);
            setSnapshot(void 0);
          }
        }
      }
    ),
    duplicateCandidates.length > 0 && /* @__PURE__ */ jsxs35(Alert8, { severity: "warning", children: [
      "Found ",
      duplicateCandidates.length,
      " address",
      duplicateCandidates.length === 1 ? "" : "es",
      " ",
      "that were already defined in the audience. These will be ignored."
    ] }),
    (snapshot || follower && (follower == null ? void 0 : follower.results.length) > 0) && /* @__PURE__ */ jsxs35(Box33, { children: [
      /* @__PURE__ */ jsx45(Button18, { fullWidth: true, variant: "outlined", color: "primary", onClick: handleDownloadCsv, children: "Download your audience" }),
      includeQuantity && /* @__PURE__ */ jsxs35(Alert8, { sx: { mt: 2 }, severity: "info", children: [
        /* @__PURE__ */ jsx45(Typography21, { children: "If you want to set a specific quantity for some of the recipients, download the CSV file and re-upload it in the first tab." }),
        /* @__PURE__ */ jsx45(Typography21, { children: "You can also continue with the default of 1 token per detected recipient." })
      ] })
    ] })
  ] });
};
function DefineAudienceEntryDialog({
  open,
  handleClose,
  existingEntries,
  allowedRecipientTypes = ["EMAIL", "ETHEREUM_ADDRESS"],
  includeQuantity = true,
  quantityHiddenDefault = 1,
  confirmLabel = "Confirm Add Recipient(s)",
  defineContextSingular = "Recipient",
  specificRecipientType = void 0
}) {
  const { checkConsent } = useCheckConsentSingle();
  const handleCreateDefaultManualEntry = useCallback13(() => {
    return defaultManualAudienceEntry;
  }, []);
  if (allowedRecipientTypes.length === 1) {
    specificRecipientType = allowedRecipientTypes[0];
  }
  const getSubtitleForTabType = useCallback13(
    (tabType) => {
      switch (tabType) {
        case 0 /* IMPORT */:
          return `Upload a CSV file to add ${defineContextSingular.toLowerCase()}${defineContextSingular.toLowerCase().endsWith("s") ? "es" : "s"} in bulk.`;
        case 1 /* MANUAL */:
          return `Manually add ${defineContextSingular.toLowerCase()}${defineContextSingular.toLowerCase().endsWith("s") ? "es" : "s"} to your audience via the editor.`;
        case 2 /* CUSTOM */:
          return `Add ${defineContextSingular.toLowerCase()}${defineContextSingular.toLowerCase().endsWith("s") ? "es" : "s"} in bulk from your Community.`;
      }
    },
    [defineContextSingular]
  );
  return /* @__PURE__ */ jsx45(
    DefineViaCsvOrManualDialog,
    {
      open,
      dialogMinWidth: 650,
      handleClose,
      dialogTitle: `${defineContextSingular}${defineContextSingular.endsWith("s") ? "es" : "s"} Editor`,
      dialogSubtitle: getSubtitleForTabType,
      defaultEntryFactory: handleCreateDefaultManualEntry,
      confirmLabel,
      sortElements: (entries) => sortBy3(entries, (entry) => {
        var _a;
        return (_a = entry.recipient) == null ? void 0 : _a.toUpperCase();
      }),
      CsvUpload: (reflectChangeToDialogState) => /* @__PURE__ */ jsx45(
        AudienceEntryCsvUpload,
        {
          existingEntries,
          label: "CSV File",
          includeTemplateDownloadButton: true,
          handleChange: reflectChangeToDialogState,
          allowedRecipientTypes,
          includeQuantity,
          quantityHiddenDefault
        }
      ),
      ManualDefine: (entry, reflectChangeToDialogState) => /* @__PURE__ */ jsx45(
        DefineSingle_default,
        {
          allowedRecipientTypes,
          quantityToReceive: entry.quantityToReceive,
          includeQuantity,
          quantityHiddenDefault,
          recipient: entry.recipient,
          warning: entry.warning,
          handleChange: (propName, changed) => reflectChangeToDialogState(propName, changed)
        }
      ),
      validateManuallyDefinedEntry: (toValidate, addedEntries) => __async(this, null, function* () {
        if (toValidate && !toValidate.recipient) {
          toValidate.valid = false;
          toValidate.warning = `Please fill in a valid address.`;
          return;
        }
        const recipientType = specificRecipientType != null ? specificRecipientType : getAudienceRecipientType(toValidate.recipient);
        toValidate.recipientType = recipientType;
        if (recipientType && !allowedRecipientTypes.includes(recipientType)) {
          toValidate.valid = false;
          toValidate.warning = recipientType === "EMAIL" ? `Email addresses are not allowed.` : "Ethereum addresses are not allowed.";
          return;
        }
        const consentValid = yield checkConsent(toValidate.recipient);
        if (consentValid) {
          const { valid, warning } = validateAudienceEntry(
            toValidate,
            [...addedEntries, ...existingEntries != null ? existingEntries : []],
            void 0,
            defineContextSingular
          );
          toValidate.recipientType = recipientType;
          toValidate.valid = valid;
          toValidate.warning = warning;
        } else {
          toValidate.valid = false;
          toValidate.warning = /* @__PURE__ */ jsxs35(Fragment18, { children: [
            /* @__PURE__ */ jsx45(Alert8, { severity: "warning", children: "You do not have consent to email this address." }),
            /* @__PURE__ */ jsx45(SlotRenderer4, { slot: "portal.community.follower-share-link" })
          ] });
        }
      }),
      CustomDefine: (handleAudienceEntries) => /* @__PURE__ */ jsx45(
        CustomDefineDropAudienceEntries,
        {
          existingEntries: existingEntries != null ? existingEntries : [],
          handleAudienceEntries,
          allowedRecipientTypes,
          includeQuantity
        }
      ),
      customDefineOptions: { tabLabel: "From Community", tabOrder: 3 },
      elementLabelFormatter: (entry) => /* @__PURE__ */ jsxs35(Stack13, { direction: "row", children: [
        entry.recipientType === "ETHEREUM_ADDRESS" ? /* @__PURE__ */ jsx45(EthereumAddress_default, { shortFormat: true, value: entry.recipient, showExplorerIcon: true }) : /* @__PURE__ */ jsx45(Fragment18, { children: entry.recipient }),
        includeQuantity && /* @__PURE__ */ jsx45("span", { style: { marginLeft: "3px" }, children: `| Qty: ${entry.quantityToReceive}` })
      ] }),
      definitionContextSingular: defineContextSingular
    }
  );
}

// src/audience/ConfirmDeleteDialog.tsx
import CloseIcon5 from "@mui/icons-material/Close";
import {
  Button as Button19,
  Dialog as Dialog4,
  DialogContent as DialogContent4,
  DialogTitle as DialogTitle4,
  IconButton as IconButton9,
  Stack as Stack14,
  TextField as TextField5,
  Typography as Typography22,
  useMediaQuery as useMediaQuery4,
  useTheme as useTheme16
} from "@mui/material";
import { jsx as jsx46, jsxs as jsxs36 } from "react/jsx-runtime";
function ConfirmDeleteAudienceEntryDialog({
  open,
  toDelete,
  title,
  subtitle,
  handleClose,
  includeQuantity = true,
  defineContextSingular = "Recipient"
}) {
  const theme = useTheme16();
  const fullScreen = useMediaQuery4(theme.breakpoints.down("md"));
  const minWidth = !fullScreen ? 650 : void 0;
  return /* @__PURE__ */ jsxs36(
    Dialog4,
    {
      sx: {
        "& .MuiDialog-paper": { minWidth }
      },
      open,
      fullScreen,
      onClose: () => handleClose(false),
      children: [
        /* @__PURE__ */ jsxs36(DialogTitle4, { children: [
          /* @__PURE__ */ jsxs36(Stack14, { spacing: 2, children: [
            /* @__PURE__ */ jsx46(Typography22, { fontSize: theme.typography.h3.fontSize, fontWeight: 600, children: title || "Confirm Delete" }),
            /* @__PURE__ */ jsx46(Typography22, { variant: "body2", children: subtitle || "Are you sure you want to remove this recipient from the audience?" })
          ] }),
          /* @__PURE__ */ jsx46(
            IconButton9,
            {
              "aria-label": "close",
              onClick: () => handleClose(false),
              size: "large",
              sx: {
                position: "absolute",
                right: 8
              },
              children: /* @__PURE__ */ jsx46(CloseIcon5, { fontSize: "small" })
            }
          )
        ] }),
        /* @__PURE__ */ jsxs36(DialogContent4, { children: [
          /* @__PURE__ */ jsxs36(Stack14, { direction: "row", spacing: 1, children: [
            /* @__PURE__ */ jsx46(
              TextField5,
              {
                fullWidth: true,
                id: "recipient",
                name: "recipient",
                label: defineContextSingular,
                required: true,
                type: "text",
                value: toDelete == null ? void 0 : toDelete.recipient,
                inputProps: { readOnly: true }
              }
            ),
            includeQuantity && /* @__PURE__ */ jsx46(
              TextField5,
              {
                fullWidth: true,
                id: "quantity",
                name: "quantity",
                label: "Quantity",
                required: true,
                type: "text",
                value: toDelete == null ? void 0 : toDelete.quantityToReceive,
                inputProps: { readOnly: true }
              }
            )
          ] }),
          /* @__PURE__ */ jsxs36(
            Stack14,
            {
              direction: "row",
              display: "flex",
              justifyContent: "space-between",
              sx: { pt: 2, ".MuiButton-root": { flexGrow: 1 } },
              spacing: 2,
              children: [
                /* @__PURE__ */ jsx46(Button19, { variant: "outlined", onClick: () => handleClose(false), children: "Close" }),
                /* @__PURE__ */ jsx46(Button19, { variant: "contained", onClick: () => handleClose(true), children: "Confirm" })
              ]
            }
          )
        ] })
      ]
    }
  );
}

// src/audience/UpdateSingleDialog.tsx
import CloseIcon6 from "@mui/icons-material/Close";
import {
  Alert as Alert9,
  Button as Button20,
  Dialog as Dialog5,
  DialogActions as DialogActions4,
  DialogContent as DialogContent5,
  DialogTitle as DialogTitle5,
  IconButton as IconButton10,
  Stack as Stack15,
  Typography as Typography23,
  useMediaQuery as useMediaQuery5,
  useTheme as useTheme17
} from "@mui/material";
import { useCallback as useCallback14, useEffect as useEffect14, useState as useState20 } from "react";
import { SlotRenderer as SlotRenderer5 } from "plugin-system";
import { Fragment as Fragment19, jsx as jsx47, jsxs as jsxs37 } from "react/jsx-runtime";
function UpdateSingleAudienceEntryDialog({
  open,
  toUpdate,
  handleClose,
  existingEntries,
  allowedRecipientTypes = ["EMAIL", "ETHEREUM_ADDRESS"],
  includeQuantity = true,
  quantityHiddenDefault = 1,
  defineContextSingular = "Recipient"
}) {
  const theme = useTheme17();
  const { checkConsent } = useCheckConsentSingle();
  const fullScreen = useMediaQuery5(theme.breakpoints.down("md"));
  const minWidth = !fullScreen ? 650 : void 0;
  const [updatedEntry, setUpdatedEntry] = useState20(null);
  const [recordValidity, setRecordValidity] = useState20({
    valid: false,
    warning: ""
  });
  const handleDefineChange = useCallback14(
    (propName, changed) => {
      if (!updatedEntry || !toUpdate)
        return;
      const updated = __spreadProps(__spreadValues({}, updatedEntry), { [propName]: changed });
      setUpdatedEntry(updated);
    },
    [updatedEntry, toUpdate]
  );
  useEffect14(() => {
    if (toUpdate == null ? void 0 : toUpdate.recipient) {
      setUpdatedEntry(
        toUpdate ? __spreadValues({}, toUpdate) : null
      );
    }
  }, [toUpdate]);
  const closeDialog = useCallback14(() => {
    setRecordValidity({ valid: false, warning: "" });
    setUpdatedEntry((old) => {
      if (old)
        handleClose(old, null);
      return null;
    });
  }, [handleClose]);
  const handleSubmit = useCallback14(() => __async(this, null, function* () {
    var _a;
    if (!updatedEntry)
      return;
    let recordValidityCandidate = void 0;
    const suggestedRecipientType = getAudienceRecipientType(updatedEntry.recipient);
    if (suggestedRecipientType && !allowedRecipientTypes.includes(suggestedRecipientType)) {
      recordValidityCandidate = {
        valid: false,
        warning: suggestedRecipientType === "ETHEREUM_ADDRESS" ? `Invalid ${defineContextSingular == null ? void 0 : defineContextSingular.toLowerCase()}, Ethereum addresses are not allowed.` : suggestedRecipientType === "EMAIL" ? `Invalid ${defineContextSingular == null ? void 0 : defineContextSingular.toLowerCase()}, email addresses are not allowed` : `This ${defineContextSingular == null ? void 0 : defineContextSingular.toLowerCase()} type is not allowed`
      };
    } else {
      const consentValid = yield checkConsent(updatedEntry.recipient);
      if (consentValid) {
        const { valid, warning } = validateAudienceEntry(
          updatedEntry,
          (_a = existingEntries == null ? void 0 : existingEntries.filter((existing) => existing.recipient !== toUpdate.recipient)) != null ? _a : [],
          void 0,
          defineContextSingular
        );
        updatedEntry.recipientType = getAudienceRecipientType(updatedEntry.recipient);
        recordValidityCandidate = {
          valid,
          warning
        };
      } else {
        recordValidityCandidate = {
          valid: false,
          warning: /* @__PURE__ */ jsxs37(Fragment19, { children: [
            /* @__PURE__ */ jsx47(Alert9, { severity: "warning", children: "You do not have consent to email this address." }),
            /* @__PURE__ */ jsx47(SlotRenderer5, { slot: "portal.community.follower-share-link" })
          ] })
        };
      }
    }
    if (recordValidityCandidate) {
      setRecordValidity(recordValidityCandidate);
      if (recordValidityCandidate.valid) {
        handleClose(toUpdate, updatedEntry);
      }
    }
  }), [
    allowedRecipientTypes,
    checkConsent,
    defineContextSingular,
    existingEntries,
    handleClose,
    toUpdate,
    updatedEntry
  ]);
  return /* @__PURE__ */ jsxs37(
    Dialog5,
    {
      sx: {
        "& .MuiDialog-paper": { minWidth }
      },
      open,
      fullScreen,
      onClose: closeDialog,
      children: [
        /* @__PURE__ */ jsxs37(DialogTitle5, { children: [
          /* @__PURE__ */ jsx47(Stack15, { spacing: 2, children: /* @__PURE__ */ jsxs37(Typography23, { fontSize: theme.typography.h3.fontSize, fontWeight: 600, children: [
            "Update ",
            defineContextSingular
          ] }) }),
          /* @__PURE__ */ jsx47(
            IconButton10,
            {
              "aria-label": "close",
              onClick: closeDialog,
              size: "large",
              sx: {
                position: "absolute",
                right: 8
              },
              children: /* @__PURE__ */ jsx47(CloseIcon6, { fontSize: "small" })
            }
          )
        ] }),
        /* @__PURE__ */ jsx47(DialogContent5, { children: updatedEntry && /* @__PURE__ */ jsx47(
          DefineSingle_default,
          {
            quantityToReceive: updatedEntry == null ? void 0 : updatedEntry.quantityToReceive,
            recipient: updatedEntry == null ? void 0 : updatedEntry.recipient,
            warning: recordValidity == null ? void 0 : recordValidity.warning,
            handleChange: handleDefineChange,
            allowedRecipientTypes,
            includeQuantity,
            quantityHiddenDefault,
            defineContextSingular
          }
        ) }),
        /* @__PURE__ */ jsxs37(DialogActions4, { children: [
          /* @__PURE__ */ jsx47(Button20, { variant: "outlined", onClick: closeDialog, children: "Close" }),
          /* @__PURE__ */ jsx47(Button20, { variant: "contained", onClick: handleSubmit, children: "Confirm" })
        ] })
      ]
    }
  );
}

// src/audience/Grid.tsx
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Stack as Stack16, useTheme as useTheme18 } from "@mui/material";
import { useEffect as useEffect15, useState as useState21 } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenu
} from "@mui/x-data-grid";
import { Fragment as Fragment20, jsx as jsx48 } from "react/jsx-runtime";
var initialGridState = {
  sorting: {
    sortModel: [{ field: "quantityToReceive", sort: "desc" }]
  }
};
var columns = [
  {
    field: "recipient",
    headerName: "Recipient",
    headerAlign: "left",
    flex: 2,
    type: "string",
    filterable: true,
    sortable: true,
    renderCell: (params) => /* @__PURE__ */ jsx48(Stack16, { direction: "row", spacing: 1, children: params.row.recipient && params.row.recipientType === "ETHEREUM_ADDRESS" ? /* @__PURE__ */ jsx48(EthereumAddress_default, { value: params.row.recipient, showCopyIcon: true }) : /* @__PURE__ */ jsx48(Fragment20, { children: params.row.recipient }) })
  },
  {
    field: "quantityToReceive",
    headerName: "Qty",
    headerAlign: "left",
    width: 125,
    type: "number",
    filterable: true,
    sortable: true
  }
];
var AudienceGrid = ({
  entries,
  handleEditEntry,
  handleDeleteEntry,
  noRowsOverlay,
  readOnly,
  includeQuantity = true,
  recipientColumnHeaderName = void 0
}) => {
  const theme = useTheme18();
  const [coercedColumns, setCoercedColumns] = useState21(columns);
  const [rows, setRows] = useState21([]);
  useEffect15(() => {
    const coerced = includeQuantity ? [...columns] : [...columns.filter((column) => column.field !== "quantityToReceive")];
    if (recipientColumnHeaderName) {
      const toCorrect = coerced.find((column) => column.field === "recipient");
      if (toCorrect)
        toCorrect.headerName = recipientColumnHeaderName;
    }
    if (!readOnly) {
      coerced.push({
        field: "actions",
        type: "actions",
        getActions: (params) => [
          /* @__PURE__ */ jsx48(
            GridActionsCellItem,
            {
              onClick: () => handleEditEntry(params.row),
              icon: /* @__PURE__ */ jsx48(EditIcon, {}),
              label: "Edit",
              showInMenu: true
            }
          ),
          /* @__PURE__ */ jsx48(
            GridActionsCellItem,
            {
              onClick: () => handleDeleteEntry(params.row),
              icon: /* @__PURE__ */ jsx48(DeleteIcon, {}),
              label: "Remove",
              showInMenu: true
            }
          )
        ]
      });
    }
    setCoercedColumns(coerced);
  }, [columns, readOnly, includeQuantity, recipientColumnHeaderName]);
  useEffect15(() => {
    setRows(
      entries.map((entry) => {
        return __spreadProps(__spreadValues({}, entry), {
          id: entry.recipient
        });
      })
    );
  }, [entries]);
  return /* @__PURE__ */ jsx48(
    DataGrid,
    {
      getRowId: (row) => row.recipient,
      columns: coercedColumns,
      slots: {
        columnMenu: (props) => /* @__PURE__ */ jsx48(
          GridColumnMenu,
          __spreadProps(__spreadValues({}, props), {
            slots: {
              columnMenuColumnsItem: null
            }
          })
        ),
        noRowsOverlay: noRowsOverlay ? () => /* @__PURE__ */ jsx48(
          Stack16,
          {
            sx: {
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              color: theme.palette.neutral_600
            },
            children: noRowsOverlay
          }
        ) : void 0
      },
      rows,
      slotProps: {
        footer: {
          hidden: !(rows == null ? void 0 : rows.length)
        },
        pagination: {
          // No server side pagination so we need to show all buttons.
          showFirstButton: true,
          showLastButton: true
        }
      },
      initialState: initialGridState,
      sx: {
        height: "100%",
        minWidth: "300px",
        width: "100%"
      },
      hideFooter: !(rows == null ? void 0 : rows.length)
    }
  );
};

// src/audience/CommunitySelectCompact.tsx
import { useEffect as useEffect16, useMemo as useMemo11, useState as useState22 } from "react";
import { SlotRenderer as SlotRenderer6 } from "plugin-system";
import {
  Autocomplete as Autocomplete2,
  Box as Box34,
  Typography as Typography24,
  useTheme as useTheme19,
  Stack as Stack17,
  TextField as TextField6,
  FormHelperText as FormHelperText4
} from "@mui/material";
import {
  useAllAudienceListMembers as useAllAudienceListMembers2,
  useAllAudienceLists as useAllAudienceLists2,
  useAllCreatorFollowers as useAllCreatorFollowers2,
  useAllSnapshotHoldersByAddress as useAllSnapshotHoldersByAddress2,
  useAllSnapshots as useAllSnapshots2
} from "common-api";
import GroupIcon from "@mui/icons-material/Group";
import { useFormikContext as useFormikContext3 } from "formik";
import { jsx as jsx49, jsxs as jsxs38 } from "react/jsx-runtime";
var CommunitySelectCompact = ({ formKey }) => {
  var _a, _b;
  const theme = useTheme19();
  const formik = useFormikContext3();
  const { data: snapshots, isPending: snapshotsPending } = useAllSnapshots2();
  const { data: followers, isPending: followersPending } = useAllCreatorFollowers2();
  const { data: audienceList, isPending: audienceListsPending } = useAllAudienceLists2();
  const [selectedOption, setSelectedOption] = useState22(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState22(void 0);
  const { data: snapshotHolders, isPending: snapshotHoldersPending } = useAllSnapshotHoldersByAddress2(selectedSnapshot == null ? void 0 : selectedSnapshot.id);
  const [selectedAudienceList, setSelectedAudienceList] = useState22(
    void 0
  );
  const { data: audienceListMembers, isPending: audienceListMembersPending } = useAllAudienceListMembers2(selectedAudienceList == null ? void 0 : selectedAudienceList.id);
  useEffect16(() => {
    formik.setFieldValue(
      FORM_PREVENT_SUBMIT_LOADING,
      snapshotHoldersPending && Boolean(selectedSnapshot == null ? void 0 : selectedSnapshot.id),
      false
    );
  }, [snapshotHoldersPending, selectedSnapshot]);
  useEffect16(() => {
    formik.setFieldValue(
      FORM_PREVENT_SUBMIT_LOADING,
      audienceListMembersPending && Boolean(selectedAudienceList == null ? void 0 : selectedAudienceList.id),
      false
    );
  }, [audienceListMembersPending]);
  useEffect16(() => {
    if (!audienceListMembers.length)
      return;
    const audienceCandidates = audienceListMembers.map(
      (member) => ({
        recipient: member.value,
        quantityToReceive: 1,
        recipientType: member.type === AudienceListMemberTypeEnum.EmailAddress ? "EMAIL" : "ETHEREUM_ADDRESS"
      })
    );
    formik.setFieldValue(formKey, audienceCandidates);
  }, [audienceListMembers]);
  useEffect16(() => {
    if (!snapshotHolders.length || !formik)
      return;
    const audienceCandidates = snapshotHolders.map(
      (holder) => ({
        recipient: holder == null ? void 0 : holder.address,
        quantityToReceive: 1,
        recipientType: "ETHEREUM_ADDRESS"
      })
    );
    formik.setFieldValue(formKey, audienceCandidates);
  }, [snapshotHolders]);
  const completedSnapshotsWithHolders = useMemo11(() => {
    var _a2;
    return (_a2 = snapshots == null ? void 0 : snapshots.filter(
      (snapshot) => snapshot.status === SnapshotOutputStatusEnum.Completed && snapshot.holder_count && snapshot.holder_count > 0
    )) != null ? _a2 : [];
  }, [snapshots]);
  const handleAudienceEntries = (selected) => {
    let audienceEntries = [];
    const optionCandidate = selected == null ? void 0 : selected.options;
    if ((selected == null ? void 0 : selected.groups) === "Followers" && followers) {
      audienceEntries = followers.results.map(
        (follower) => ({
          recipient: follower.email_address,
          quantityToReceive: 1,
          recipientType: "EMAIL"
        })
      );
      formik.setFieldValue(formKey, audienceEntries);
    } else if ((selected == null ? void 0 : selected.groups) === "Snapshots" && snapshots && optionCandidate) {
      setSelectedSnapshot(snapshots.find((snapshot) => snapshot.id === optionCandidate[0].id));
    } else if ((selected == null ? void 0 : selected.groups) === "Audiences" && audienceList && optionCandidate) {
      setSelectedAudienceList(audienceList.find((list) => list.id === optionCandidate[0].id));
    }
  };
  const options = useMemo11(() => {
    var _a2;
    return [
      {
        groups: "Followers",
        options: [
          {
            id: "1",
            name: "All Email Followers",
            holder_count: (_a2 = followers == null ? void 0 : followers.results.length) != null ? _a2 : 0
          }
        ]
      },
      ...audienceList.filter(
        (list) => {
          var _a3, _b2;
          return (((_a3 = list.total_email_address) != null ? _a3 : 0) > 0 || ((_b2 = list.total_ethereum_address) != null ? _b2 : 0) > 0) && list.id && list.name;
        }
      ).map((list) => {
        var _a3, _b2, _c, _d;
        return {
          groups: "Audiences",
          options: [
            {
              id: (_a3 = list.id) != null ? _a3 : "",
              name: (_b2 = list.name) != null ? _b2 : "",
              holder_count: ((_c = list.total_email_address) != null ? _c : 0) + ((_d = list.total_ethereum_address) != null ? _d : 0)
            }
          ]
        };
      }),
      ...completedSnapshotsWithHolders.map((snapshot) => {
        var _a3;
        return {
          groups: "Snapshots",
          options: [
            {
              id: snapshot.id,
              name: snapshot.name,
              holder_count: (_a3 = snapshot.holder_count) != null ? _a3 : 0
            }
          ]
        };
      })
    ];
  }, [audienceList, completedSnapshotsWithHolders, followers == null ? void 0 : followers.results.length]);
  const selectedSnapshotholderCount = useMemo11(() => {
    var _a2, _b2;
    if (!selectedOption || (selectedOption == null ? void 0 : selectedOption.groups) === "Followers")
      return 0;
    return (_b2 = (_a2 = completedSnapshotsWithHolders.find((snapshot) => snapshot.id === selectedOption.options[0].id)) == null ? void 0 : _a2.holder_count) != null ? _b2 : 0;
  }, [completedSnapshotsWithHolders, selectedOption]);
  const selectedAudienceListMemberCount = useMemo11(() => {
    var _a2, _b2;
    if (!selectedOption || (selectedOption == null ? void 0 : selectedOption.groups) === "Audience")
      return 0;
    const list = audienceList.find((list2) => list2.id === selectedOption.options[0].id);
    return ((_a2 = list == null ? void 0 : list.total_email_address) != null ? _a2 : 0) + ((_b2 = list == null ? void 0 : list.total_ethereum_address) != null ? _b2 : 0);
  }, [audienceList, selectedOption]);
  return /* @__PURE__ */ jsxs38(Stack17, { children: [
    /* @__PURE__ */ jsx49(
      Autocomplete2,
      {
        id: "community-source-autocomplete",
        "data-testid": "community-source-autocomplete",
        options,
        groupBy: (option) => option.groups,
        getOptionLabel: (option) => option.options[0].name,
        value: selectedOption,
        disabled: snapshotHoldersPending && Boolean(selectedSnapshot == null ? void 0 : selectedSnapshot.id) || snapshotsPending || followersPending || audienceListsPending || audienceListMembersPending && Boolean(selectedAudienceList == null ? void 0 : selectedAudienceList.id),
        onChange: (_, newValue) => {
          setSelectedOption(newValue);
          handleAudienceEntries(newValue);
        },
        isOptionEqualToValue: (selected, value) => selected.options[0].id === value.options[0].id,
        renderInput: (params) => /* @__PURE__ */ jsx49(
          TextField6,
          __spreadProps(__spreadValues({}, params), {
            error: formik.touched[formKey] && Boolean(formik.errors[formKey])
          })
        ),
        renderGroup: (params) => [
          /* @__PURE__ */ jsx49(Typography24, { variant: "subtitle1", color: theme.palette.common.white, children: params.group }, params.key),
          [/* @__PURE__ */ jsx49("ul", { children: params.children }, `${params.key}-list`)]
        ],
        renderOption: (props, option) => /* @__PURE__ */ jsxs38("li", __spreadProps(__spreadValues({}, props), { style: { color: "primary" }, children: [
          option.options[0].name.length > 30 ? `${option.options[0].name.substring(0, 20)}...` : `${option.options[0].name}`,
          " \u2022 ",
          " ",
          option.options[0].holder_count,
          " address",
          option.options[0].holder_count === 1 ? "" : "es"
        ] }))
      }
    ),
    (selectedOption == null ? void 0 : selectedOption.options[0]) && /* @__PURE__ */ jsxs38(
      Box34,
      {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        padding: 0.5,
        borderRadius: 2,
        borderColor: theme.palette.neutral_600,
        mt: 1,
        p: 1,
        children: [
          /* @__PURE__ */ jsx49(GroupIcon, { fontSize: "small" }),
          /* @__PURE__ */ jsxs38(Typography24, { variant: "body2", ml: 1, color: theme.palette.neutral_600, children: [
            selectedOption.groups === "Followers" && `There ${((_a = followers == null ? void 0 : followers.results) == null ? void 0 : _a.length) === 1 ? "is" : "are"} ${followers == null ? void 0 : followers.results.length} address${((_b = followers == null ? void 0 : followers.results) == null ? void 0 : _b.length) === 1 ? "" : "es"} on the list`,
            selectedOption.groups === "Snapshots" && `There ${selectedSnapshotholderCount === 1 ? "is" : "are"} ${selectedSnapshotholderCount != null ? selectedSnapshotholderCount : 0} address${selectedSnapshotholderCount === 1 ? "" : "es"} on the list`,
            selectedOption.groups === "Audiences" && `There ${selectedAudienceListMemberCount === 1 ? "is" : "are"} ${selectedAudienceListMemberCount != null ? selectedAudienceListMemberCount : 0} address${selectedAudienceListMemberCount === 1 ? "" : "es"} on the list`
          ] })
        ]
      }
    ),
    (snapshotHoldersPending && Boolean(selectedSnapshot == null ? void 0 : selectedSnapshot.id) || audienceListMembersPending && Boolean(selectedAudienceList == null ? void 0 : selectedAudienceList.id)) && /* @__PURE__ */ jsx49(Typography24, { variant: "body1", children: "Loading snapshot wallet addresses..." }),
    formik.touched[formKey] && Boolean(formik.errors[formKey]) ? /* @__PURE__ */ jsx49(FormHelperText4, { error: true, children: formik.errors[formKey] }) : null,
    (selectedOption == null ? void 0 : selectedOption.groups) === "Followers" && /* @__PURE__ */ jsx49(SlotRenderer6, { slot: "portal.community.no-followers-share-link" })
  ] });
};

// src/audience/AddToListingModal.tsx
import { useCallback as useCallback15, useEffect as useEffect17, useMemo as useMemo12, useRef as useRef6 } from "react";
import { Formik, Form } from "formik";
import {
  useEditListingAllowlist,
  useInvalidateResourceQuery as useInvalidateResourceQuery2,
  useOrganizationSettings,
  usePaginatedListingAllowlist
} from "common-api";

// ../api/src/sdks/sdk-platform-beta.ts
import globalAxios4 from "axios";
var AllowListEditInputActionEnum = {
  Add: "ADD",
  Remove: "REMOVE"
};
var AllowListGetPaginatedInputAddressTypeEnum = {
  Eth: "ETH",
  Email: "EMAIL"
};

// src/audience/AddToListingModal.tsx
import { usePluginRegistry as usePluginRegistry9 } from "plugin-system";
import z4 from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { isAddress as isAddress3 } from "ethers";
import { jsx as jsx50 } from "react/jsx-runtime";
var VALIDATION_SCHEMA = z4.object({
  addresses: z4.array(
    z4.string().refine((value) => isAddress3(value)),
    {
      invalid_type_error: "Recipient must be a valid wallet address."
    }
  ),
  addressType: z4.enum(["wallet-address"])
});
var AddToListingModal = ({
  dropId,
  dropFlowId,
  listingId,
  open,
  onClose
}) => {
  var _a;
  const formikRef = useRef6();
  const { platform } = useAuth();
  const { data: orgSettings, isPending: orgSettingsPending } = useOrganizationSettings(
    (_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id
  );
  const {
    mutate: addToListingAllowlist,
    status: addToListingAllowlistStatus,
    reset: resetAddToListingAllowList,
    data: addedResult
  } = useEditListingAllowlist();
  const { data: allowListPeek, isPending: allowListPeekPending } = usePaginatedListingAllowlist(
    dropId,
    dropFlowId,
    listingId,
    { limit: 1 }
  );
  const allowListCurrentCount = useMemo12(() => {
    var _a2, _b, _c;
    return allowListPeek && ((_a2 = allowListPeek == null ? void 0 : allowListPeek.pages) == null ? void 0 : _a2.length) ? (_c = (_b = allowListPeek == null ? void 0 : allowListPeek.pages) == null ? void 0 : _b[0]) == null ? void 0 : _c.total_results : void 0;
  }, [allowListPeek]);
  const pluginRegistry = usePluginRegistry9();
  const invalidate = useInvalidateResourceQuery2();
  useEffect17(() => {
    var _a2, _b, _c, _d, _e;
    if (addToListingAllowlistStatus === "success") {
      if (addedResult && ((_a2 = addedResult.data.added.eth_addresses) == null ? void 0 : _a2.length) === 0 && ((_b = addedResult.data.added.email_addresses) == null ? void 0 : _b.length) === 0) {
        pluginRegistry.events.publish(
          new NotificationEvent({
            message: `Could not add address, it likely already exists in the allowlist.`,
            options: {
              type: "warning",
              toastId: "add-to-allowlist-duplicate-warning"
            }
          })
        );
      } else {
        pluginRegistry.events.publish(
          new NotificationEvent({
            message: `Added address to allowlist.`,
            options: {
              type: "success",
              toastId: "add-to-allowlist-success"
            }
          })
        );
        invalidate("LISTING", listingId);
        onClose();
      }
      resetAddToListingAllowList();
      if (formikRef == null ? void 0 : formikRef.current)
        formikRef == null ? void 0 : formikRef.current.setSubmitting(false);
    } else if (addToListingAllowlistStatus === "error" && ((_e = (_d = (_c = formikRef == null ? void 0 : formikRef.current) == null ? void 0 : _c.values) == null ? void 0 : _d.addresses) == null ? void 0 : _e.length) !== 0) {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: `Failed to add address to allowlist.`,
          options: {
            type: "error",
            toastId: "add-to-allowlist-error"
          }
        })
      );
      resetAddToListingAllowList();
      if (formikRef == null ? void 0 : formikRef.current)
        formikRef == null ? void 0 : formikRef.current.setSubmitting(false);
    }
  }, [
    addToListingAllowlistStatus,
    pluginRegistry,
    resetAddToListingAllowList,
    onClose,
    addedResult,
    invalidate,
    listingId
  ]);
  const handleConfirm = useCallback15(
    (values) => {
      var _a2;
      if (orgSettingsPending || allowListPeekPending)
        return;
      if (values) {
        if ((orgSettings == null ? void 0 : orgSettings.maximum_allowlist_size) && (orgSettings.maximum_allowlist_size <= (allowListCurrentCount != null ? allowListCurrentCount : 0) || orgSettings.maximum_allowlist_size < (allowListCurrentCount != null ? allowListCurrentCount : 0) + values.addresses.length)) {
          pluginRegistry.events.publish(
            new NotificationEvent({
              message: `Failed to add to allow list as the new total (${(allowListCurrentCount != null ? allowListCurrentCount : 0) + values.addresses.length}) would exceed allowed allow list size. (${(_a2 = orgSettings == null ? void 0 : orgSettings.maximum_allowlist_size) != null ? _a2 : 0})`,
              options: {
                type: "info"
              }
            })
          );
        } else {
          addToListingAllowlist({
            listingId,
            action: AllowListEditInputActionEnum.Add,
            addresses: values.addresses,
            addressType: AllowListGetPaginatedInputAddressTypeEnum.Eth
          });
        }
        onClose();
      }
    },
    [
      addToListingAllowlist,
      listingId,
      onClose,
      orgSettings,
      allowListCurrentCount,
      pluginRegistry,
      orgSettingsPending,
      allowListPeekPending
    ]
  );
  return /* @__PURE__ */ jsx50(
    Formik,
    {
      innerRef: formikRef,
      initialValues: { addresses: [], addressType: "wallet-address" },
      onSubmit: handleConfirm,
      validateOnBlur: false,
      validationSchema: toFormikValidationSchema(VALIDATION_SCHEMA),
      children: () => /* @__PURE__ */ jsx50(Form, { style: { display: "flex", flexDirection: "column", rowGap: "24px" }, children: /* @__PURE__ */ jsx50(
        DefineAudienceEntryDialog,
        {
          open,
          existingEntries: [],
          allowedRecipientTypes: ["ETHEREUM_ADDRESS"],
          handleClose: (values) => handleConfirm({
            addresses: values.map((recipient) => recipient.recipient),
            addressType: "wallet-address"
          }),
          includeQuantity: false,
          confirmLabel: "Add To Allowlist",
          defineContextSingular: "Wallet Address"
        }
      ) })
    }
  );
};

// src/stepper-dialog/StepperDialog.tsx
import CloseIcon7 from "@mui/icons-material/Close";
import {
  Box as Box35,
  Dialog as Dialog6,
  DialogActions as DialogActions5,
  IconButton as IconButton11,
  Stack as Stack18,
  useMediaQuery as useMediaQuery6,
  useTheme as useTheme20
} from "@mui/material";
import DialogContent6 from "@mui/material/DialogContent/DialogContent";
import DialogTitle6 from "@mui/material/DialogTitle/DialogTitle";
import Typography25 from "@mui/material/Typography/Typography";
import { jsx as jsx51, jsxs as jsxs39 } from "react/jsx-runtime";
function StepperDialog(props) {
  const theme = useTheme20();
  const fullScreen = useMediaQuery6(theme.breakpoints.down("md"));
  const minWidth = !fullScreen ? 922 : void 0;
  return /* @__PURE__ */ jsxs39(
    Dialog6,
    {
      sx: {
        "& .MuiDialog-paper": { minWidth, minHeight: 600 }
      },
      open: props.open,
      fullScreen,
      onClose: props.onClose,
      children: [
        /* @__PURE__ */ jsxs39(DialogTitle6, { children: [
          typeof props.title === "string" ? /* @__PURE__ */ jsx51(
            Typography25,
            {
              fontSize: theme.typography.h2.fontSize,
              fontWeight: 800,
              children: props.title
            }
          ) : props.title,
          /* @__PURE__ */ jsx51(
            IconButton11,
            {
              "aria-label": "close",
              onClick: props.onClose,
              size: "large",
              sx: {
                position: "absolute",
                right: 8
              },
              children: /* @__PURE__ */ jsx51(CloseIcon7, { fontSize: "small" })
            }
          )
        ] }),
        /* @__PURE__ */ jsxs39(DialogContent6, { children: [
          props.contentOverlay && /* @__PURE__ */ jsx51(Box35, { sx: { width: "100%", height: "100%", position: "absolute", zIndex: 100 }, children: props.contentOverlay }),
          /* @__PURE__ */ jsxs39(Stack18, { direction: "row", height: "100%", sx: { opacity: props.contentOverlay ? 0.5 : 1 }, children: [
            /* @__PURE__ */ jsx51(
              Stack18,
              {
                sx: {
                  pr: 3,
                  rowGap: 2,
                  borderRight: `1px solid ${theme.palette.neutral_200}`
                },
                children: props.guidance
              }
            ),
            /* @__PURE__ */ jsx51(Box35, { flexGrow: 1, sx: { pl: 3 }, children: props.content })
          ] })
        ] }),
        /* @__PURE__ */ jsx51(DialogActions5, { children: props.actions })
      ]
    }
  );
}

// src/stepper-dialog/GuidanceStep.tsx
import { Box as Box36, Typography as Typography26 } from "@mui/material";
import { jsx as jsx52, jsxs as jsxs40 } from "react/jsx-runtime";
function GuidanceStep({
  icon,
  title,
  description,
  status,
  StatusIcon,
  disabled,
  onClick
}) {
  const color = (theme) => theme.palette.primary.main;
  const showDescription = status === "active" || status === "incomplete";
  return /* @__PURE__ */ jsxs40(
    Box36,
    {
      sx: {
        display: "grid",
        gridTemplateColumns: "24px 1fr 24px",
        gridTemplateRows: status === "active" ? "24px max-content" : "24px",
        backgroundColor: (theme) => status === "active" ? theme.palette.secondary_50 : "transparent",
        columnGap: "8px",
        borderRadius: "8px",
        p: 1,
        "&:hover": {
          cursor: !disabled && onClick && status !== "active" ? "pointer" : "inherit"
        }
      },
      onClick: () => {
        if (!disabled && onClick && status !== "active") {
          onClick();
        }
      },
      children: [
        /* @__PURE__ */ jsx52(Box36, { sx: { color: (theme) => theme.palette.neutral_500 }, children: icon }),
        /* @__PURE__ */ jsx52(Typography26, { variant: "body1", fontWeight: 600, sx: { color, alignSelf: "center" }, children: title }),
        /* @__PURE__ */ jsx52(StatusIcon, {}),
        showDescription && /* @__PURE__ */ jsx52(
          Box36,
          {
            sx: {
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: "2",
              gridColumnEnd: "2"
            },
            children: /* @__PURE__ */ jsx52(Typography26, { variant: "body3", children: description })
          }
        )
      ]
    }
  );
}

// src/stepper-dialog/wizard/helpers.tsx
var WIZARD_DIALOG_NAVIGATE_TO_STEP = "navigateTo";

// src/stepper-dialog/wizard/manager.tsx
import { find as find4, isEqual } from "lodash";
var getWizardStepDirection = (ordered, current, requested) => {
  if (current === requested)
    return "same";
  return ordered.indexOf(current) < ordered.indexOf(requested) ? "forward" : "backward";
};
var WizardDialogManager = class {
  /**
   * @title Constructor for the wizard dialog manager.
   * @param wizardState - The root state for the wizard.
   * @param orderedStepStates - The states for each step in the wizard. These should be in the order they appear in the wizard!
   */
  constructor(wizardState, stepStates) {
    /**
     * @title Based on the current active step, gets the logical next step type. (invariant of accessibility)
     */
    this.getNextWizardStepType = (type) => {
      const orderedStepTypes = Object.keys(this.stepStates);
      return orderedStepTypes.indexOf(type) === orderedStepTypes.length - 1 ? void 0 : orderedStepTypes[orderedStepTypes.indexOf(type) + 1];
    };
    /**
     * @title Determines if the requested step has uncommitted state.
     */
    this.hasUncommittedStepState = (stepState, candidateValues) => {
      var _a;
      return stepState.committed === void 0 && !isEqual(JSON.stringify(candidateValues != null ? candidateValues : {}), JSON.stringify(stepState.defaults)) || Boolean(stepState.committed) && !isEqual(JSON.stringify(candidateValues != null ? candidateValues : {}), JSON.stringify((_a = stepState.committed) != null ? _a : {}));
    };
    /**
     * @title Determines if there are stepstates that have been committed to memory.
     */
    this.hasMemoryCommittedStepStates = () => {
      return Boolean(
        find4(
          Object.values(this.stepStates),
          (stepState) => stepState.status === "COMMITTED" && stepState.commitType === "MEMORY"
        )
      );
    };
    this.wizardState = wizardState;
    this.stepStates = stepStates;
  }
  /**
   * @title Accessor for a specific step state.
   */
  getStepState(stepType) {
    return this.stepStates[stepType];
  }
  /**
   * @title Resets the state of the wizard and all of its steps.
   */
  reset() {
    const _a = this.wizardState.defaults, { open } = _a, resetDefaults = __objRest(_a, ["open"]);
    for (const key in this.wizardState) {
      if (key !== "defaults") {
        ;
        this.wizardState[key] = resetDefaults[key];
      }
    }
    for (const stepState of Object.values(this.stepStates)) {
      stepState.reset(stepState);
    }
  }
  /**
   * @title Closes the wizard
   */
  close() {
    this.wizardState.open = false;
  }
  /**
   * @title Attempts to navigate to a specific step.
   * @description Takes into account the current step and the requested step to determine if the requested step is accessible. (amongst other things)
   */
  navigateToStep(stepType) {
    var _a, _b;
    let requestedStepState = this.getStepState(stepType);
    const requestedDirection = getWizardStepDirection(
      Object.keys(this.stepStates),
      this.wizardState.activeStep.stepType,
      stepType
    );
    if ((!requestedStepState || !requestedStepState.applicable || requestedStepState.disabled) && requestedDirection === "backward") {
      const previousAccessibleStepState = this.getPreviousAccessibleWizardStepState(stepType);
      requestedStepState = previousAccessibleStepState != null ? previousAccessibleStepState : requestedStepState;
    } else if (requestedStepState && !requestedStepState.applicable && requestedDirection === "forward") {
      const nextAccessibleStepState = this.getNextAccessibleWizardStepState(stepType);
      requestedStepState = nextAccessibleStepState != null ? nextAccessibleStepState : requestedStepState;
    }
    if ((requestedStepState == null ? void 0 : requestedStepState.disabled) || !(requestedStepState == null ? void 0 : requestedStepState.applicable))
      return;
    if (this.wizardState.activeStep.status === "UNCOMMITTED") {
      this.wizardState.activeStep.status = this.wizardState.activeStep.committed && Object.keys(this.wizardState.activeStep.committed).length || !this.wizardState.activeStep.defaults || !Object.keys(this.wizardState.activeStep.defaults).length ? "COMMITTED" : "NOT_STARTED";
    }
    this.wizardState.previousStepType = (_b = (_a = this.getPreviousAccessibleWizardStepState(stepType)) == null ? void 0 : _a.stepType) != null ? _b : this.getPreviousWizardStepType(stepType);
    this.wizardState.activeStep = requestedStepState;
    this.wizardState.activeStep.status = "UNCOMMITTED";
    this.wizardState.nextStepType = this.getNextWizardStepType(requestedStepState.stepType);
  }
  /**
   * @title Commits the active step and navigates to the requested step.
   * @param activeStepCandidateValues - The candidate values to commit for the active step.
   * @param navigateTo - The step to navigate to after committing the active step.
   * @param commitBag - (Optional) Bag with data needed to commit the active step with.
   */
  commitAndNavigate(activeStepCandidateValues, navigateTo, commitBag, pluginRegistry, formikRef) {
    return __async(this, null, function* () {
      var _a, _b, _c;
      let commitResult = { success: void 0 };
      if (!((_a = this.wizardState.activeStep) == null ? void 0 : _a.readOnly)) {
        this.wizardState.activeStep.readOnly = true;
        commitResult = (_b = yield this.commitActiveStepState(
          activeStepCandidateValues,
          navigateTo,
          commitBag,
          pluginRegistry,
          formikRef
        )) != null ? _b : navigateTo;
        this.wizardState.activeStep.readOnly = false;
      }
      if (this.wizardState.activeStep.status === "COMMITTED" || this.wizardState.activeStep.readOnly) {
        commitResult.success = true;
        const coercedNavigateTo = (_c = commitResult == null ? void 0 : commitResult.navigateToStepType) != null ? _c : navigateTo;
        if (this.canCloseAfterCommit(coercedNavigateTo))
          this.close();
        else
          this.navigateToStep(coercedNavigateTo);
      }
      commitResult.success = commitResult.success !== void 0 ? commitResult.success : false;
      return commitResult;
    });
  }
  /**
   * @title Based on the current active step, gets the logical previous step state. (invariant of accessibility)
   */
  getPreviousWizardStepType(type) {
    const orderedStepTypes = Object.keys(this.stepStates);
    return orderedStepTypes.indexOf(type) === 0 ? void 0 : orderedStepTypes[orderedStepTypes.indexOf(type) - 1];
  }
  /**
   * @title Gets the previous accessible step state. (if any)
   */
  getPreviousAccessibleWizardStepState(stepType) {
    let result = null;
    let currentStepType = stepType;
    while (result === null) {
      const previousStep = this.getPreviousWizardStepType(currentStepType);
      if (!previousStep)
        break;
      const previousStepState = this.getStepState(previousStep);
      if (previousStepState && previousStepState.applicable && !previousStepState.disabled) {
        result = previousStepState;
      } else {
        currentStepType = previousStep;
      }
    }
    return result;
  }
  /**
   * @title Gets the next accessible step state. (if any)
   */
  getNextAccessibleWizardStepState(stepType) {
    let result = null;
    let currentStepType = stepType;
    while (result === null) {
      const nextStep = this.getNextWizardStepType(currentStepType);
      if (!nextStep)
        break;
      const nextStepState = this.getStepState(nextStep);
      if (nextStepState && nextStepState.applicable && !nextStepState.disabled) {
        result = nextStepState;
      } else {
        currentStepType = nextStep;
      }
    }
    return result;
  }
};

// src/stepper-dialog/wizard/WizardDialogStepActions.tsx
import { Alert as Alert10, AlertTitle, Button as Button21, Stack as Stack19 } from "@mui/material";
import { useFormikContext as useFormikContext4 } from "formik";
import { useCommandExecutor, usePluginRegistry as usePluginRegistry10 } from "plugin-system";
import { useCallback as useCallback16, useEffect as useEffect18, useState as useState23 } from "react";
import { useSnapshot } from "valtio";
import dayjs2 from "dayjs";
import { jsx as jsx53, jsxs as jsxs41 } from "react/jsx-runtime";
function WizardDialogStepActions({
  manager,
  backButtonLabel,
  nextButtonLabel,
  nextButtonDisabled,
  customButtons,
  backButtonSx = {},
  nextButtonSx = {},
  showBackButton = true,
  commandId = ""
}) {
  const pluginRegistry = usePluginRegistry10();
  const formik = useFormikContext4();
  const { previousStepType, activeStep, nextStepType } = useSnapshot(manager.wizardState);
  const [showLongSubmitAttempt, setShowLongSubmitAttempt] = useState23(false);
  const [submitStartedAt, setSubmitStartedAt] = useState23(void 0);
  const executeCommand = useCommandExecutor(commandId);
  const handleClick = useCallback16(
    (direction) => __async(this, null, function* () {
      if (direction === "forward" && nextStepType) {
        formik.setFieldValue(WIZARD_DIALOG_NAVIGATE_TO_STEP, nextStepType, false);
        yield formik.submitForm();
      } else if (direction === "forward" && !nextStepType) {
        yield formik.submitForm();
        manager.close();
      } else if (direction === "backward" && commandId !== "") {
        manager.close();
        setTimeout(() => {
          executeCommand();
        }, 250);
      } else if (previousStepType !== void 0) {
        const _a = formik.values, { navigateTo, preventCancel, preventSubmit } = _a, candidateState = __objRest(_a, ["navigateTo", "preventCancel", "preventSubmit"]);
        const hasChanges = manager.hasUncommittedStepState(activeStep, candidateState);
        if (hasChanges) {
          pluginRegistry.commands.execute({
            id: "portal.confirm-dialog",
            dialogTitle: "Discard changes",
            dialogSubtitle: "Are you sure you want to leave this step? You will lose any unsaved changes.",
            onConfirm: () => manager.navigateToStep(previousStepType)
          });
        } else
          manager.navigateToStep(previousStepType);
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, formik.values, previousStepType, nextStepType, activeStep, manager]
  );
  useEffect18(() => {
    if (formik.isSubmitting && !submitStartedAt) {
      setSubmitStartedAt(dayjs2());
    } else if (!formik.isSubmitting) {
      setSubmitStartedAt(void 0);
    }
  }, [formik.isSubmitting, submitStartedAt]);
  useEffect18(() => {
    if (formik.isSubmitting && submitStartedAt) {
      setTimeout(() => {
        if (formik.isSubmitting && submitStartedAt) {
          const diff = dayjs2().diff(submitStartedAt, "second");
          if (diff >= 5)
            setShowLongSubmitAttempt(() => true);
        }
      }, 5e3);
    } else
      setShowLongSubmitAttempt(() => false);
  }, [submitStartedAt, formik.isSubmitting]);
  return /* @__PURE__ */ jsxs41(Stack19, { direction: "column", sx: { flexGrow: 1 }, children: [
    /* @__PURE__ */ jsxs41(
      Stack19,
      {
        direction: "row",
        sx: {
          flexGrow: 1,
          justifyContent: showBackButton || customButtons ? "space-between" : "flex-end"
        },
        children: [
          showBackButton && /* @__PURE__ */ jsx53(
            Button21,
            {
              "data-testid": "back",
              variant: "outlined",
              size: "small",
              disabled: formik.isSubmitting || previousStepType === void 0 || formik.values[FORM_PREVENT_CANCEL] === true,
              sx: backButtonSx,
              onClick: () => handleClick("backward"),
              children: backButtonLabel != null ? backButtonLabel : "Back"
            }
          ),
          customButtons,
          /* @__PURE__ */ jsx53(
            LoadingButton,
            {
              "data-testid": "next",
              variant: "contained",
              size: "small",
              loading: formik.isSubmitting || formik.values[FORM_PREVENT_SUBMIT_LOADING],
              loadingText: formik.isSubmitting ? "Saving..." : "Waiting...",
              sx: nextButtonSx,
              title: formik.values[FORM_PREVENT_SUBMIT_LOADING] ? "This button is disabled because there is an action taking place on the form that has yet to complete." : void 0,
              disabled: formik.isSubmitting || formik.isValidating || formik.values[FORM_PREVENT_SUBMIT] === true || formik.values[FORM_PREVENT_SUBMIT_LOADING] === true || nextButtonDisabled,
              onClick: () => {
                handleClick("forward");
              },
              children: nextButtonLabel != null ? nextButtonLabel : "Next"
            }
          )
        ]
      }
    ),
    formik.isSubmitting && showLongSubmitAttempt && submitStartedAt && /* @__PURE__ */ jsxs41(Alert10, { severity: "info", sx: { width: "100%", mt: 1 }, children: [
      /* @__PURE__ */ jsx53(AlertTitle, { children: "Saving is taking longer than expected" }),
      "We're working hard to save everything captured in this step. Please remain patient and do not close this window."
    ] })
  ] });
}
var WizardDialogStepActions_default = WizardDialogStepActions;

// src/stepper-dialog/wizard/WizardDialogStepGuidance.tsx
import { Stack as Stack20 } from "@mui/material";
import { useFormikContext as useFormikContext5 } from "formik";
import { usePluginRegistry as usePluginRegistry11 } from "plugin-system";
import { useCallback as useCallback17, useMemo as useMemo13 } from "react";
import { useSnapshot as useSnapshot2 } from "valtio";
import { jsx as jsx54 } from "react/jsx-runtime";
function WizardDialogStepGuidance({
  manager,
  stepGuidances
}) {
  const pluginRegistry = usePluginRegistry11();
  const { activeStep } = useSnapshot2(manager.wizardState);
  const formik = useFormikContext5();
  const onGuidanceClick = useCallback17(
    (stepType) => __async(this, null, function* () {
      const direction = getWizardStepDirection(
        Object.keys(manager.stepStates),
        activeStep.stepType,
        stepType
      );
      if (direction === "forward") {
        formik.setFieldValue(WIZARD_DIALOG_NAVIGATE_TO_STEP, stepType, false);
        yield formik.submitForm();
      } else {
        const _a = formik.values, { navigateTo, preventSubmit, preventCancel } = _a, candidateState = __objRest(_a, ["navigateTo", "preventSubmit", "preventCancel"]);
        const candidateStateHasChanges = activeStep.status === "UNCOMMITTED" && manager.hasUncommittedStepState(activeStep, candidateState);
        if (candidateStateHasChanges) {
          pluginRegistry.commands.execute({
            id: "portal.confirm-dialog",
            dialogTitle: "Discard changes",
            dialogSubtitle: "Are you sure you want to leave this step? You will lose any unsaved changes.",
            onConfirm: () => manager.navigateToStep(stepType)
          });
        } else
          manager.navigateToStep(stepType);
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, formik.values, activeStep, manager]
  );
  const handleStepGuidanceClick = useCallback17(
    (stepGuidance) => {
      const navigationDirection = getWizardStepDirection(
        Object.keys(manager.stepStates),
        activeStep.stepType,
        stepGuidance.stepType
      );
      if (!formik.isSubmitting && (navigationDirection === "forward" && !formik.values[FORM_PREVENT_SUBMIT] && !formik.values[FORM_PREVENT_SUBMIT_LOADING] || navigationDirection === "backward" && !formik.values[FORM_PREVENT_CANCEL])) {
        onGuidanceClick(stepGuidance.stepType);
      } else {
      }
    },
    [formik, activeStep, onGuidanceClick, manager]
  );
  const applicableStepGuidances = useMemo13(() => {
    var _a;
    return (_a = stepGuidances == null ? void 0 : stepGuidances.filter((stepGuidance) => {
      const stepState = manager.getStepState(stepGuidance.stepType);
      return (stepState == null ? void 0 : stepState.applicable) === true;
    })) != null ? _a : [];
  }, [activeStep, stepGuidances, manager]);
  return /* @__PURE__ */ jsx54(Stack20, { sx: { width: 240, rowGap: 1.5 }, children: applicableStepGuidances.map((stepGuidance, index) => /* @__PURE__ */ jsx54(
    BoxedGuidanceStep,
    {
      manager,
      stepGuidance,
      activeStep,
      handleStepGuidanceClick
    },
    index
  )) });
}
function BoxedGuidanceStep({
  manager,
  stepGuidance,
  activeStep,
  handleStepGuidanceClick
}) {
  const stepState = manager.getStepState(stepGuidance.stepType);
  const status = activeStep.stepType === (stepState == null ? void 0 : stepState.stepType) ? "active" : (stepState == null ? void 0 : stepState.status) === "COMMITTED" ? "complete" : (stepState == null ? void 0 : stepState.status) === "UNCOMMITTED" ? "incomplete" : "not-started";
  const StatusIcon = usePortalIcon(`step-${status}`);
  const GuidanceIcon = stepGuidance.Icon;
  if ((stepState == null ? void 0 : stepState.applicable) !== true) {
    return null;
  }
  return /* @__PURE__ */ jsx54(
    GuidanceStep,
    {
      icon: /* @__PURE__ */ jsx54(GuidanceIcon, {}),
      title: stepGuidance.title,
      status,
      StatusIcon,
      disabled: stepState == null ? void 0 : stepState.disabled,
      onClick: () => {
        handleStepGuidanceClick(stepGuidance);
      }
    }
  );
}

// src/stepper-dialog/wizard/WizardDialog.tsx
import { Formik as Formik2 } from "formik";
import { usePluginRegistry as usePluginRegistry12 } from "plugin-system";
import { useCallback as useCallback18, useEffect as useEffect19, useRef as useRef7 } from "react";
import { useSnapshot as useSnapshot4 } from "valtio";
import { toFormikValidationSchema as toFormikValidationSchema2 } from "zod-formik-adapter";

// src/stepper-dialog/wizard/WizardDialogStepContent.tsx
import { Box as Box37, Typography as Typography27 } from "@mui/material";
import Stack21 from "@mui/material/Stack";
import { useFormikContext as useFormikContext6 } from "formik";
import find5 from "lodash/find";
import { useMemo as useMemo14 } from "react";
import { useSnapshot as useSnapshot3 } from "valtio";
import { jsx as jsx55, jsxs as jsxs42 } from "react/jsx-runtime";
function WizardDialogStepContent({
  manager,
  renderStep
}) {
  const { activeStep } = useSnapshot3(manager.wizardState);
  const formik = useFormikContext6();
  const formInitializedForActiveStep = useMemo14(() => {
    const referenceKeys = Object.keys(activeStep.defaults);
    const formikKeys = Object.keys(formik.values).filter(
      (key) => key !== FORM_PREVENT_CANCEL && key !== FORM_PREVENT_SUBMIT && key !== FORM_PREVENT_SUBMIT_LOADING && key !== WIZARD_DIALOG_NAVIGATE_TO_STEP
    );
    return formikKeys.length === 0 && referenceKeys.length === 0 || Boolean(formikKeys.length) && Boolean(referenceKeys.length) && !find5(referenceKeys, (formikValueKey) => formikKeys.indexOf(formikValueKey) === -1);
  }, [activeStep, formik.values]);
  return /* @__PURE__ */ jsx55(BasicFileUploadDropContainer, { children: /* @__PURE__ */ jsxs42(Box37, { sx: { display: "flex", flexDirection: "column", rowGap: 1 }, height: "100%", children: [
    (activeStep == null ? void 0 : activeStep.title) && /* @__PURE__ */ jsx55(Typography27, { variant: "bodyXL", children: activeStep.title }),
    /* @__PURE__ */ jsxs42(Stack21, { sx: { flexGrow: 1 }, spacing: 4, children: [
      (activeStep == null ? void 0 : activeStep.description) && /* @__PURE__ */ jsx55(Typography27, { variant: "body1", children: activeStep.description }),
      formInitializedForActiveStep && renderStep(activeStep.stepType)
    ] })
  ] }) });
}

// src/stepper-dialog/wizard/WizardDialog.tsx
import { Fragment as Fragment21, jsx as jsx56 } from "react/jsx-runtime";
function WizardDialog(props) {
  var _a;
  const { open } = useSnapshot4((_a = props == null ? void 0 : props.manager) == null ? void 0 : _a.wizardState);
  const { onClose, manager } = props;
  useEffect19(() => {
    if (open)
      return;
    else {
      if (manager)
        manager.reset();
      if (onClose)
        onClose();
    }
  }, [onClose, manager, open, props]);
  return open ? /* @__PURE__ */ jsx56(WizardDialogInner, __spreadValues({}, props)) : /* @__PURE__ */ jsx56(Fragment21, {});
}
function WizardDialogInner({
  title,
  manager,
  stepGuidances,
  renderStepContent,
  stepActionOptions,
  bootstrapper,
  commitBag,
  onClose
}) {
  var _a;
  const { open, activeStep, bootstrapContext } = useSnapshot4(manager.wizardState);
  const pluginRegistry = usePluginRegistry12();
  const formikRef = useRef7();
  const onManualClose = useCallback18(() => {
    var _a2, _b;
    if ((_a2 = formikRef == null ? void 0 : formikRef.current) == null ? void 0 : _a2.isSubmitting) {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "Cannot close this pop-up while saving. Please wait until the application has saved this step.",
          options: {
            type: "info"
          }
        })
      );
      return;
    }
    if (!((_b = formikRef == null ? void 0 : formikRef.current) == null ? void 0 : _b.values)) {
      manager.close();
      if (onClose)
        onClose();
      return;
    }
    const _c = formikRef.current.values, { navigateTo, preventSubmit, preventCancel } = _c, candidateState = __objRest(_c, ["navigateTo", "preventSubmit", "preventCancel"]);
    const hasChanges = manager.hasUncommittedStepState(activeStep, candidateState) || manager.hasMemoryCommittedStepStates();
    if (!hasChanges) {
      manager.close();
      if (onClose)
        onClose();
      return;
    }
    pluginRegistry.commands.execute({
      id: "portal.confirm-dialog",
      dialogTitle: "Unsaved changes",
      dialogSubtitle: "Are you sure you want to leave this page? Any unsaved changes will be lost",
      confirmButtonLabel: "Confirm",
      onConfirm: () => {
        manager.close();
        if (onClose)
          onClose();
      }
    });
  }, [pluginRegistry, activeStep, manager, onClose]);
  const handleSubmit = useCallback18(
    (_0, _1) => __async(this, [_0, _1], function* (values, { setSubmitting }) {
      const _a2 = values, { navigateTo, preventSubmit, preventCancel } = _a2, candidateState = __objRest(_a2, ["navigateTo", "preventSubmit", "preventCancel"]);
      const { success: commitSuccess, feedback: commitFeedback } = yield manager.commitAndNavigate(
        candidateState,
        navigateTo,
        commitBag,
        pluginRegistry,
        formikRef
      );
      if (commitFeedback) {
        pluginRegistry.events.publish(
          new NotificationEvent({
            message: commitFeedback,
            options: {
              type: commitSuccess ? "success" : "error"
            }
          })
        );
      }
      setSubmitting(false);
    }),
    [manager, commitBag, pluginRegistry]
  );
  return /* @__PURE__ */ jsx56(Fragment21, { children: open && /* @__PURE__ */ jsx56(Fragment21, { children: /* @__PURE__ */ jsx56(
    Formik2,
    {
      innerRef: formikRef,
      enableReinitialize: true,
      validateOnBlur: false,
      initialValues: (_a = activeStep == null ? void 0 : activeStep.committed) != null ? _a : activeStep.defaults,
      validationSchema: toFormikValidationSchema2(activeStep.validationSchema),
      onSubmit: handleSubmit,
      children: /* @__PURE__ */ jsx56(
        StepperDialog,
        {
          open: true,
          title,
          contentOverlay: bootstrapContext && !(bootstrapContext == null ? void 0 : bootstrapContext.bootstrapped) ? bootstrapper : null,
          onClose: onManualClose,
          guidance: /* @__PURE__ */ jsx56(WizardDialogStepGuidance, { manager, stepGuidances }),
          content: /* @__PURE__ */ jsx56(WizardDialogStepContent, { manager, renderStep: renderStepContent }),
          actions: /* @__PURE__ */ jsx56(WizardDialogStepActions_default, __spreadValues({ manager }, stepActionOptions))
        }
      )
    }
  ) }) });
}

// src/stepper-dialog/wizard/WizardSingleDialog.tsx
import { Formik as Formik3 } from "formik";
import { usePluginRegistry as usePluginRegistry13 } from "plugin-system";
import { useCallback as useCallback19, useEffect as useEffect20, useRef as useRef8 } from "react";
import { useSnapshot as useSnapshot5 } from "valtio";
import { toFormikValidationSchema as toFormikValidationSchema3 } from "zod-formik-adapter";
import CloseIcon8 from "@mui/icons-material/Close";
import {
  Alert as Alert11,
  Dialog as Dialog7,
  DialogActions as DialogActions6,
  DialogContent as DialogContent7,
  DialogTitle as DialogTitle7,
  IconButton as IconButton12,
  Typography as Typography28,
  useMediaQuery as useMediaQuery7,
  useTheme as useTheme21
} from "@mui/material";
import { Fragment as Fragment22, jsx as jsx57, jsxs as jsxs43 } from "react/jsx-runtime";
function WizardSingleDialog(props) {
  var _a;
  const { open } = useSnapshot5((_a = props == null ? void 0 : props.manager) == null ? void 0 : _a.wizardState);
  const { onClose, manager } = props;
  useEffect20(() => {
    if (open)
      return;
    else {
      if (manager)
        manager.reset();
      if (onClose)
        onClose();
    }
  }, [onClose, manager, open, props]);
  return open ? /* @__PURE__ */ jsx57(WizardSingleDialogInner, __spreadValues({}, props)) : /* @__PURE__ */ jsx57(Fragment22, {});
}
function WizardSingleDialogInner({
  title,
  manager,
  renderStepContent,
  stepActionOptions,
  commitBag,
  dialogInfo,
  onClose,
  minWidth
}) {
  var _a;
  const { open, activeStep } = useSnapshot5(manager.wizardState);
  const pluginRegistry = usePluginRegistry13();
  const formikRef = useRef8();
  const theme = useTheme21();
  const fullScreen = useMediaQuery7(theme.breakpoints.down("md"));
  const coercedMinWidth = !fullScreen ? minWidth || 922 : void 0;
  const onManualClose = () => {
    var _a2;
    if (!((_a2 = formikRef == null ? void 0 : formikRef.current) == null ? void 0 : _a2.values)) {
      manager.close();
      return;
    }
    const _b = formikRef.current.values, { navigateTo, preventSubmit, preventCancel } = _b, candidateState = __objRest(_b, ["navigateTo", "preventSubmit", "preventCancel"]);
    const hasChanges = manager.hasUncommittedStepState(activeStep, candidateState) || manager.hasMemoryCommittedStepStates();
    if (!hasChanges) {
      manager.close();
      return;
    }
    pluginRegistry.commands.execute({
      id: "portal.confirm-dialog",
      dialogTitle: "Unsaved changes",
      dialogSubtitle: "Are you sure you want to leave this page? Any unsaved changes will be lost",
      confirmButtonLabel: "Confirm",
      onConfirm: () => manager.close()
    });
  };
  const handleSubmit = useCallback19(
    (_0, _1) => __async(this, [_0, _1], function* (values, { setSubmitting }) {
      const _a2 = values, { navigateTo, preventSubmit, preventCancel } = _a2, candidateState = __objRest(_a2, ["navigateTo", "preventSubmit", "preventCancel"]);
      const { success: commitSuccess, feedback: commitFeedback } = yield manager.commitAndNavigate(
        candidateState,
        navigateTo,
        commitBag,
        pluginRegistry,
        formikRef
      );
      if (commitFeedback) {
        pluginRegistry.events.publish(
          new NotificationEvent({
            message: commitFeedback,
            options: {
              type: commitSuccess ? "success" : "error"
            }
          })
        );
      }
      setSubmitting(false);
    }),
    [manager, commitBag, pluginRegistry]
  );
  return /* @__PURE__ */ jsx57(Fragment22, { children: open && /* @__PURE__ */ jsx57(Fragment22, { children: /* @__PURE__ */ jsx57(
    Formik3,
    {
      innerRef: formikRef,
      enableReinitialize: true,
      validateOnBlur: false,
      initialValues: (_a = activeStep == null ? void 0 : activeStep.committed) != null ? _a : activeStep.defaults,
      validationSchema: toFormikValidationSchema3(activeStep.validationSchema),
      onSubmit: handleSubmit,
      children: /* @__PURE__ */ jsxs43(
        Dialog7,
        {
          sx: {
            "& .MuiDialog-paper": { minWidth: coercedMinWidth, minHeight: 300 }
          },
          open: true,
          fullScreen,
          onClose: onManualClose,
          children: [
            /* @__PURE__ */ jsxs43(DialogTitle7, { children: [
              typeof title === "string" ? /* @__PURE__ */ jsx57(
                Typography28,
                {
                  fontSize: theme.typography.h4.fontSize,
                  fontWeight: 700,
                  children: title
                }
              ) : title,
              /* @__PURE__ */ jsx57(
                IconButton12,
                {
                  "aria-label": "close",
                  onClick: onManualClose,
                  size: "large",
                  sx: {
                    position: "absolute",
                    right: 8
                  },
                  children: /* @__PURE__ */ jsx57(CloseIcon8, { fontSize: "small" })
                }
              )
            ] }),
            /* @__PURE__ */ jsx57(DialogContent7, { sx: { position: "relative" }, children: /* @__PURE__ */ jsx57(WizardDialogStepContent, { manager, renderStep: renderStepContent }) }),
            /* @__PURE__ */ jsxs43(DialogActions6, { children: [
              dialogInfo && /* @__PURE__ */ jsx57(Alert11, { sx: { py: 0, maxWidth: "75%" }, severity: "info", children: dialogInfo }),
              /* @__PURE__ */ jsx57(
                WizardDialogStepActions_default,
                __spreadValues({
                  showBackButton: false,
                  manager
                }, stepActionOptions)
              )
            ] })
          ]
        }
      )
    }
  ) }) });
}

// src/stepper-dialog/wizard/WizardDualPaneDialog.tsx
import { Formik as Formik4 } from "formik";
import CloseIcon9 from "@mui/icons-material/Close";
import { usePluginRegistry as usePluginRegistry14 } from "plugin-system";
import { useCallback as useCallback20, useEffect as useEffect21, useRef as useRef9 } from "react";
import { useSnapshot as useSnapshot6 } from "valtio";
import { toFormikValidationSchema as toFormikValidationSchema4 } from "zod-formik-adapter";
import {
  Dialog as Dialog8,
  DialogActions as DialogActions7,
  DialogContent as DialogContent8,
  IconButton as IconButton13,
  Stack as Stack22,
  Typography as Typography29,
  useMediaQuery as useMediaQuery8,
  useTheme as useTheme22
} from "@mui/material";
import { Fragment as Fragment23, jsx as jsx58, jsxs as jsxs44 } from "react/jsx-runtime";
function WizardDualPaneDialog({
  leftTitle,
  rightTitle,
  manager,
  activeLeftStepContent,
  activeRightStepContent,
  stepActionOptions,
  commitBag,
  onClose,
  alert
}) {
  var _a;
  const { open, activeStep } = useSnapshot6(manager.wizardState);
  const pluginRegistry = usePluginRegistry14();
  const formikRef = useRef9();
  const theme = useTheme22();
  const fullScreen = useMediaQuery8(theme.breakpoints.down("md"));
  const minWidth = !fullScreen ? 922 : void 0;
  const stepState = manager.getStepState(activeStep == null ? void 0 : activeStep.stepType);
  const onManualClose = () => {
    var _a2;
    if (!((_a2 = formikRef == null ? void 0 : formikRef.current) == null ? void 0 : _a2.values)) {
      manager.close();
      return;
    }
    const _b = formikRef.current.values, { navigateTo, preventSubmit, preventCancel } = _b, candidateState = __objRest(_b, ["navigateTo", "preventSubmit", "preventCancel"]);
    const hasChanges = manager.hasUncommittedStepState(activeStep, candidateState) || manager.hasMemoryCommittedStepStates();
    if (!hasChanges) {
      manager.close();
      return;
    }
    pluginRegistry.commands.execute({
      id: "portal.confirm-dialog",
      dialogTitle: "Unsaved changes",
      dialogSubtitle: "Are you sure you want to leave this page? Any unsaved changes will be lost",
      confirmButtonLabel: "Confirm",
      onConfirm: () => manager.close()
    });
  };
  const handleSubmit = useCallback20(
    (_0, _1) => __async(this, [_0, _1], function* (values, { setSubmitting }) {
      const _a2 = values, { navigateTo, preventSubmit, preventCancel } = _a2, candidateState = __objRest(_a2, ["navigateTo", "preventSubmit", "preventCancel"]);
      const { success: commitSuccess, feedback: commitFeedback } = yield manager.commitAndNavigate(
        candidateState,
        navigateTo,
        commitBag,
        pluginRegistry,
        formikRef
      );
      if (commitFeedback) {
        pluginRegistry.events.publish(
          new NotificationEvent({
            message: commitFeedback,
            options: {
              type: commitSuccess ? "success" : "error"
            }
          })
        );
      }
      setSubmitting(false);
    }),
    [manager, commitBag, pluginRegistry]
  );
  useEffect21(() => {
    if (open)
      return;
    if (onClose)
      onClose();
  }, [open, manager, onClose]);
  return /* @__PURE__ */ jsx58(Fragment23, { children: open && /* @__PURE__ */ jsx58(
    Formik4,
    {
      innerRef: formikRef,
      enableReinitialize: true,
      validateOnBlur: false,
      isInitialValid: true,
      initialValues: (_a = activeStep == null ? void 0 : activeStep.committed) != null ? _a : activeStep.defaults,
      validationSchema: toFormikValidationSchema4(activeStep.validationSchema),
      onSubmit: handleSubmit,
      children: /* @__PURE__ */ jsxs44(
        Dialog8,
        {
          sx: {
            "& .MuiDialog-paper": { minWidth, minHeight: 650 },
            overflow: "hidden"
          },
          open: true,
          fullScreen,
          onClose: !(stepState == null ? void 0 : stepState.isStatic) ? onManualClose : void 0,
          children: [
            /* @__PURE__ */ jsxs44(
              DialogContent8,
              {
                sx: {
                  height: "100%",
                  display: "flex",
                  "& .MuiDialog-paper": { minWidth, minHeight: 500 },
                  overflow: "hidden"
                },
                children: [
                  /* @__PURE__ */ jsx58(
                    Stack22,
                    {
                      sx: {
                        flex: 1,
                        display: "flex",
                        flexDirection: "row"
                      },
                      children: /* @__PURE__ */ jsxs44(Stack22, { sx: { flex: 1, display: "flex", flexDirection: "column", columnGap: 3 }, children: [
                        /* @__PURE__ */ jsx58(Stack22, { sx: { minHeight: "2em" }, children: /* @__PURE__ */ jsx58(Typography29, { variant: "h6", children: leftTitle }) }),
                        activeLeftStepContent(stepState == null ? void 0 : stepState.stepType),
                        alert
                      ] })
                    }
                  ),
                  /* @__PURE__ */ jsxs44(
                    Stack22,
                    {
                      sx: {
                        flex: 2,
                        display: "flex",
                        flexDirection: "column",
                        columnGap: 3
                      },
                      children: [
                        /* @__PURE__ */ jsxs44(Stack22, { sx: { minHeight: "2em" }, children: [
                          /* @__PURE__ */ jsx58(Typography29, { variant: "h6", children: rightTitle }),
                          !activeStep.isStatic && /* @__PURE__ */ jsx58(
                            IconButton13,
                            {
                              "aria-label": "close",
                              onClick: onClose,
                              size: "large",
                              sx: {
                                alignSelf: "flex-end",
                                position: "absolute",
                                top: "10px",
                                right: "10px"
                              },
                              children: /* @__PURE__ */ jsx58(CloseIcon9, { fontSize: "small" })
                            }
                          )
                        ] }),
                        /* @__PURE__ */ jsx58(
                          Stack22,
                          {
                            sx: {
                              overflowY: "auto",
                              flexGrow: 1
                            },
                            children: activeRightStepContent(stepState == null ? void 0 : stepState.stepType)
                          }
                        )
                      ]
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ jsx58(DialogActions7, { children: /* @__PURE__ */ jsx58(
              WizardDialogStepActions_default,
              __spreadValues({
                manager,
                customButtons: stepState.customButtons
              }, stepActionOptions)
            ) })
          ]
        }
      )
    }
  ) });
}
export {
  AUDIENCE_EMAIL_SCHEMA,
  AddToListingModal,
  AudienceEntryBaseSchema,
  AudienceEntryCsvUpload,
  AudienceGrid,
  AudienceRecipientTypeSchema,
  AuthClearEvent,
  AuthPlatformSetEvent,
  AuthTokenSetEvent,
  BASIC_FILE_UPLOAD_DROPZONE_DATA_ATTRIBUTE,
  BasicFileUpload,
  BasicFileUploadDropContainer,
  BasicRichTextEditor,
  BasicTabs,
  COMMON_IMAGE_MIME_TYPES,
  ChipList,
  ClipboardContent,
  ColoredBorderLoader,
  CommonFilePondContainer,
  CommonInputLabelStack,
  CommunitySelect,
  CommunitySelectCompact,
  ConfettiExplosion,
  ConfirmDeleteAudienceEntryDialog,
  ConfirmDialog,
  CustomGridFilterBar,
  DEFAULT_BASIC_RICH_TEXT_EDITOR_TOOLBAR_OPTIONS,
  DateTimeModalPicker,
  DefineAudienceEntryDialog,
  DefineSingleAudienceEntry,
  DefineViaCsvOrManualDialog,
  EthereumAddress,
  FORM_PREVENT_CANCEL,
  FORM_PREVENT_SUBMIT,
  FORM_PREVENT_SUBMIT_LOADING,
  FollowerHolderMenuItem,
  GuidanceStep,
  KebabMenuButton,
  LINEA_NETWORK_ID,
  LINEA_TESTNET_NETWORK_ID,
  LoadingButton,
  MobileCaptureRecipient,
  MobileCheckWalletAddressPresence,
  MobileNotificationModal,
  MobileTileButton,
  MobileTileOverview,
  NetworkSelect,
  NotFoundPage,
  NotificationEvent,
  OrganizationLogo,
  PALM_NETWORK_ID,
  PALM_TESTNET_NETWORK_ID,
  POLYGON_NETWORK_ID,
  POLYGON_TESTNET_AMOY_NETWORK_ID,
  POLYGON_TESTNET_MUMBAI_NETWORK_ID,
  PORTAL_APP_BAR_USER_PROFILE_SLOT,
  PORTAL_DROP_TOOL_SLOT,
  PORTAL_HOME_DESKTOP_SLOT,
  PORTAL_HOME_MOBILE_SLOT,
  PORTAL_MAIN_MENU_BUTTONS_SLOT,
  PORTAL_QUICK_ACTIONS_SLOT,
  PORTAL_QUICK_CARDS_SLOT,
  PORTAL_RECIPE_CARDS_SLOT,
  PORTAL_ROUTES_SLOT,
  PORTAL_THEME_ICONS,
  PORTAL_THEME_LOGO_TEXT_SLOT,
  PORTAL_THEME_SLOT,
  PORTAL_TOP_BAR_SLOT,
  Page,
  PageHelmet,
  PhosphorLoading,
  PopoverButton,
  RequireAuthPage,
  RouteLoaderPage,
  SEPOLIA_TESTNET_NETWORK_ID,
  SHARE_QR_CODE_TAB_INDEX,
  ShareQRCodeCard,
  SimpleDeleteForm,
  SimpleModal,
  SingleFileServerUpload,
  SnapshotHolderMenuItemContent,
  SpinnerLoader,
  SquareImageCard,
  Status,
  StepperDialog,
  StructuralSkeleton,
  TabType,
  TopBarListItemBtn,
  UnexpectedAuthError,
  UpdateSingleAudienceEntryDialog,
  VideoToFrames,
  VideoToFramesMethod,
  WIZARD_DIALOG_NAVIGATE_TO_STEP,
  WizardDialog,
  WizardDialogManager,
  WizardDialogStepActions,
  WizardDualPaneDialog,
  WizardSingleDialog,
  checkConsentViaReport,
  coerceShortThemeColor,
  convertToCsv,
  dataURLtoFile,
  defaultManualAudienceEntry,
  downloadBlobAs,
  downloadCsvFromString,
  fillSlotsForPage,
  getAudienceRecipientType,
  getBase64FromImageFile,
  getCommonStyledFilePondIdleLabel,
  getConfig,
  getExplorerUrl,
  getImagePreviewFromVideo,
  getPath,
  getWizardStepDirection,
  hasPermission,
  hasPermissions,
  ipfsToHttps,
  mapListingAllowlistToAudienceEntry,
  mapSingleToAudienceEntry,
  mapToAudienceEntries,
  snakeToKebabCase,
  snakeToPascalCase,
  snakeToTitleCase,
  useAuth,
  useCheckConsentMultiple,
  useCheckConsentSingle,
  useCoerceColorToTheme,
  useConfig,
  useCopyToClipboard,
  useDebounce,
  useFeatureFlags,
  useMobileEnabled,
  usePortalIcon,
  uuidv4,
  validateAudienceEntry,
  validateStripeKeyAgainstEnv,
  withParams,
  withQueryString
};
